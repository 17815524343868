import { useContext, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  notification
} from "antd";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/sigaf.svg";
import republique from "../../assets/tunisia.png";
import { useTranslation } from "react-i18next";
import CustomLoader from "../../layout/CustomLoader/CustomLoader";
import { login, signUpEhouwiya} from "../../api/geoprod";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import axiosInstance from "../../utils/axios";
const SignInEHouwiya = (props) => {
  const { setToken, setRole } = props;
  const { globalData, setGlobalData, setIsRtl} = useContext(GlobalContext);
  const { setIsLoading, setSurveyJson}= useLoader()
 const code_verifier = localStorage.getItem("code_verifier")
  const { Option } = Select;
 
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);
    setIsRtl(value === "ar");
  };

  const role = new URLSearchParams(window.location.search).get("role");
  const code = new URLSearchParams(window.location.search).get("code");
 
useEffect(() =>  {
  sessionStorage.setItem("role",role)
  const fetchData = async () => {
  if(code)
    {
      const redirect_uri = `${window.location.origin}/signin/e-houwiya?role=${role}`
      const formData = new FormData();
      formData.append("code", code);
      formData.append("grant_type", "authorization_code");
      formData.append("client_id", import.meta.env.VITE_CLIENT_ID_E_HOUWIYA);
      formData.append("client_secret", "secret");
      formData.append("redirect_uri", redirect_uri);
      formData.append("code_verifier",code_verifier)

      
      axiosInstance
      .post(
        `${import.meta.env.VITE_APP_GEOPROD_API}/config/e-houwiya/auth`,
        formData,
      )
      .then(async(response) =>  {
            let payload = {
              user: {
                id: "",
                tel_urgence: "",
                tel2: "",
                mail: "",
                passport:"",
                matricule_rh: "",
                identite_reelle: "",
                skype: "",
                password: "",
                old_password: "",
                pointage: false,
                super_admin: true,
                admin_restreint: false,
                api_key: "",
                entreprise_id:
                  (role === "investisseur" || role ==="directeur")
                    ? import.meta.env.VITE_APP_ID_Entreprise_INVESTISSEUR 
                    : import.meta.env.VITE_APP_ID_Entreprise_FORMATEUR,
                date_naiss_user: "",
                role_auto: [],
                active: "",
                server_in_mail_data: "",
                server_out_mail_data: "",
                port_in_mail_data: "",
                port_out_mail_data: "",
                ssl_mail_data: false,
                num_interne: "",
                photo: null,
                admin_tpv: 2,
                execution_tpv: 2,
                validate_paiement: 2,
                date_integration: null,
                heure_fin: "",
                heure_debut: "",
                proprietaire: true,
                adresse_auto: "",
                samedi_off: false,
                comparateur: "",
                tarificateur: 2,
                signature: "",
                groupe_commission: "",
                act_grp_pubpostage: 2,
                act_grp_negociateur: 2,
                act_grp_communication: 2,
                acces_maileva: 2,
                acces_fusion: 2,
                nom_producteur: "",
                prenom_producteur: "",
                qualite_producteur: "",
                orias_producteur: "",
                location: "",
                acces_ett: true,
                role:
                  role === "investisseur"
                    ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR : role=== "formateur" ? import.meta.env.VITE_APP_ID_ROLE_FORMATEUR
                    : import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR,
                zone: "",
                code: "",
                act_grp_tags: 2,
                act_grp_statut: 2,
                hist: 2,
                hist_comm: 2,
                expiration_passeport:"",
                acces_communiques: 2,
                ecriture_transaction_financiere_client: 2,
                lecture_transaction_financiere_client: 2,
                lecture_transaction_financiere_distribution: 2,
                ecriture_transaction_financiere_distribution: 2,
                transaction_financiere: 2,
                user_mail_data: "",
                user_pwd_data: "",
                acces_reclamation_ticket: 2,
                acc_opportunite: [
                  {
                    id: 1,
                    libele: "Prospection",
                    cat: 1,
                    type: "2",
                  },
                  {
                    id: 5,
                    libele: "Commercial",
                    cat: 5,
                    type: "2",
                  },
                  {
                    id: 6,
                    libele: "Classification des diplômes",
                    cat: 6,
                    type: "2",
                  },
                ],
                acc_affaire: [
                  {
                    icon: "fa-solid fa-archive",
                    id: 2,
                    libele: "Enregistrement",
                    cat: 2,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-archive",
                    id: 3,
                    libele: "Emission",
                    cat: 3,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-archive",
                    id: 10,
                    libele: "Gestion ",
                    cat: 10,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-check",
                    id: 12,
                    libele: "Qualité et Validation",
                    cat: 12,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-graduation-cap",
                    id: 13,
                    libele: "Expertise & pré-enregistrement",
                    cat: 13,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-ad",
                    id: 14,
                    libele: "Pré-Enregistrement ATRIAS",
                    cat: 14,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-envelope-square",
                    id: 15,
                    libele: "Enregistrement ATRIAS",
                    cat: 15,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-birthday-cake",
                    id: 16,
                    libele: "Gestion ATRIAS",
                    cat: 16,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-allergies",
                    id: 822,
                    libele: "Conformité et Qualité",
                    cat: 822,
                    type: "2",
                  },
                  {
                    icon: "",
                    id: 825,
                    libele: "Commercial",
                    cat: 825,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-ad",
                    id: 826,
                    libele: "Expertise",
                    cat: 826,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-graduation-cap",
                    id: 827,
                    libele: "Direction Regionale",
                    cat: 827,
                    type: "2",
                  },
                  {
                    icon: "fa-solid fa-address-book",
                    id: 828,
                    libele: "Sous Direction Regionale",
                    cat: 828,
                    type: "2",
                  },
                ],
                manager_list: [],
                manager_de: [],
                groupe_user: [],
                tab_code: [],
                groupe_user_alert: [],
                nom_organisme: "",
                type_user: "c81e728d9d4c2f636f067f89cc14862c",
                information_bancaire: {
                  id: "",
                  titulaire_compte: "",
                  iban: "",
                  bic_swift: "",
                  type_iban: "prestation",
                  nom_banque: "",
                },
                espace_distrib_access: {
                  changement_statut: 2,
                  acces_info_spec: 2,
                },
                acces_qualite: 2,
                nom_role: "",
              },
              adresse: {
                CP: "",
                streetNumber: "",
                streetName: "",
                longitude: 0,
                latitude: 0,
                geom: null,
              },
              ville: {
                id_pays: "",
                nom_comm: "",
              },
            };
            payload.user.ehouwiya = 1;
            payload.user.civilite = response?.data?.gender == "1" ? "ذكر" :"أنثى";
            payload.user.nom = ""
            payload.user.prenom=""
            payload.user.nom_ar = response?.data["family-name"];
            payload.user.prenom_ar =  response?.data["given-name"];
            payload.user.cin = response?.data?.identityNumber;
            payload.user.date_naiss_user = response?.data?.birthDate;
            payload.user.nationality = "تونسية";
            payload.user.birthplace = "";
            payload.user.code_postal = "";
            payload.user.mail ="";
            payload.user.mobile =response?.data?.phoneNumber;
            payload.user.telephone = response?.data?.phoneNumber;
            payload.ville.nom_comm = "";
            payload.user.adresse = response?.data?.address ;
            payload.user.delegation = "";
            payload.user.goverment = "";
            payload.user.first_login = 1;
          
            const data_signup = await signUpEhouwiya(payload);
            if (data_signup?.error === false || data_signup?.error?.status === 409 ) {
              const idRole = ( role === "investisseur" ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR : role === "formateur" ?  import.meta.env.VITE_APP_ID_ROLE_FORMATEUR : role === "directeur" ?  import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR : "" )
              try {
              const data = await login("", "",data_signup?.api_key || data_signup?.error?.response?.data?.api_key, idRole);
              const first_login = data?.first_login 
              setToken(data?.Acces_Token);
              setRole(
                data.id_role === import.meta.env.VITE_APP_ID_ROLE_FORMATEUR
                  ? "formateur"
                  : "investisseur"
              );
              sessionStorage.setItem(
                "role",
                data.id_role === import.meta.env.VITE_APP_ID_ROLE_FORMATEUR
                  ? "formateur"
                  : "investisseur"
              );
              sessionStorage.setItem("-x-token-user", JSON.stringify(data));
              if(data.id_role === import.meta.env.VITE_APP_ID_ROLE_FORMATEUR)
              {
                navigate("/profile",
                  {   state: {
                        first_login:first_login,
                        ehouwiya: data?.ehouwiya 
                      },}
                )
              }
              else 
              {
                axiosInstance.get(`${ import.meta.env.VITE_APP_GEOPROD_API}/admin-bpm/contrat/${import.meta.env.VITE_ID_LIGNE_PRODUIT}`,{
                  headers:{
                    Idsession: data?.Acces_Token,
                  }
                }).then((res)=>{
                  setIsLoading(false);
                  setSurveyJson(res?.data?.contrat?.form)
                })
                if(first_login){
                  navigate("/etablissements")
                }
                else{
                  navigate("/demandes")
          
                }
              }

            } catch (error) {
              notification.error({
                message: i18n.t("Erreur"),
                description:
                  error?.response?.data?.message ||
                  i18n.t("Une erreur s'est produite lors de la connexion"),
                duration: 0,
              });
              setTimeout(() => {
                navigate(`/signin?role=${role}`)
              }, 2000);
            }
            }
            else
            {
              notification.error({
                message: i18n.t('Erreur'),
                description:
                    i18n.t(`${data_signup?.error?.response?.data?.message}`),
                duration: 0,
              });
              setTimeout(() => {
                navigate(`/signin?role=${role}`)
              }, 2000);
            }
    })
      .catch(() => {
        notification.error({
          message: i18n.t("Erreur"),
          description:
            i18n.t("Une erreur s'est produite lors de la connexion"),
          duration: 0,
        });
      });

    }
  };
  fetchData();
}, [])

  return (
    <Row gutter={24} style={{ display: "flex", justifyContent: "center" }}>
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
          }}
        >
          <img src={republique} style={{ height: "86px" }} />
          <img src={logo} />
          <Select
            style={{ width: "8rem", display: "flex", alignItems: "center" }}
            onChange={handleLanguageChange}
            value={localStorage.getItem("language") || "fr"}
          >
            <Option value="fr">Français</Option>
            <Option value="ar">العربية</Option>
          </Select>
        </Row>
      </Col>
      <Col lg={9} style={{textAlign:"center", alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column"}}>
      <CustomLoader  text="Veuillez patienter un instant..." />
      </Col>
    </Row>
  );
};

export default SignInEHouwiya;
