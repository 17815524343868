import { EyeOutlined } from "@ant-design/icons";
import { Modal, Button, Table, Tooltip, Space, notification } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../../context/LoaderContext";

const ModalListAnnexes = (props) => {
  const { listAnnexes, setListAnnexes, type } = props;
  const {
    setIsLoading,
    setSurveyJson,
    setSurveyData,
    setSurveyJsonValidationProvisoire,
  } = useLoader();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t("Date de soumission"),
      dataIndex: "date_creation",
      key: "date_creation",
      render: (date) => date || t("Non renseigné"),
    },
    {
      title: t("Dénomination"),
      key: "denomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.legal_entity_tax_number ||
        record?.numero_matricule_fiscal_numero_IURNE ||
        t("Non renseigné"),
    },
    {
      title: t("Numéro d'enregistrement"),
      dataIndex: "registration_number",
      key: "registration_number",
      render: (num) => num || t("N/A"),
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("Consulter")}>
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => {
                setIsLoading(true);
                sessionStorage.setItem("showingPreview", true);

                axiosInstance
                  .get(
                    import.meta.env.VITE_APP_GEOPROD_API +
                      "/get_info_specifique/" +
                      record?.id,
                    {
                      headers: {
                        Idsession: JSON.parse(
                          sessionStorage.getItem("-x-token-user")
                        )?.Acces_Token,
                      },
                    }
                  )
                  .then(async (res) => {
                    setIsLoading(false);
                    if (type !== "affaireParent") {
                      setSurveyJson(res?.data?.response?.form);
                      sessionStorage.setItem("demande", true);
                      const navigateTo = `/annexes/save?affaire_id=${record?.id}`;
                      if (sessionStorage.getItem("role") === "directeur") {
                        sessionStorage.setItem(
                          "user_id_to_get",
                          res?.data?.response?.responsable_EPF
                        );
                      } else {
                        sessionStorage.setItem(
                          "user_id_to_get",
                          res?.data?.response?.id_user
                        );
                      }
                      navigate(navigateTo, {
                        state: {
                          id_affaire: record.id,
                          etat_demande: record?.status,
                          contrat_id: res?.data?.response?.contrat_id
                        },
                      });
                      setSurveyData(res?.data?.response?.data);
                      setSurveyJsonValidationProvisoire(
                        res?.data?.response?.form_valid
                      );
                    } else {
                      await setSurveyData(res?.data?.response?.data);
                      await setSurveyJson(res?.data?.response?.form);
                      if (sessionStorage.getItem("role") === "directeur") {
                        sessionStorage.setItem(
                          "user_id_to_get",

                          res?.response?.id_user
                        );
                      } else {
                        sessionStorage.setItem(
                          "user_id_to_get",
                          res?.response?.responsable_EPF
                        );
                      }

                      navigate("/etablissements/save", {
                        state: {
                          id_affaire: record.id,
                          etat_demande: record?.status,
                          contrat_id: res?.data?.response?.contrat_id
                        },
                      });
                    }
                  })
                  .catch(() => {
                    setIsLoading(false);
                    notification.error({
                      message: t("Erreur"),
                      description:
                        "Une erreur a été rencontré lors de chargement de formulaire de l'annexe",
                      duration: 0,
                    });
                  });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title={
        type === "affaireParent" ? t("EPF principale") : t("Liste des annexes")
      }
      open={listAnnexes?.length > 0}
      onCancel={() => setListAnnexes([])}
      footer={null}
      width={900}
      styles={{
        padding: "24px",
        // backgroundColor: "#f0f2f5",
        // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",

        borderRadius: "8px",
      }}
    >
      <Table
        locale={{ emptyText: t("Aucune donnée") }}
        columns={columns}
        style={{
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
        dataSource={listAnnexes}
        rowKey="id"
        bordered
        pagination={false}
      />
    </Modal>
  );
};

export default ModalListAnnexes;
