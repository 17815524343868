import { useEffect, useState } from "react";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import {
  updateActionAffaire,
  updateAffaire,
  uploadDocumentAffaire,
} from "../../../api/geoprod";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomWidgetCollection, Model } from "survey-core";
import DatePikckerComponent from "../../Etablissements/CreateEpf/DatePIkcer";

import {
  ConfigProvider,
  Divider,
  Image,
  notification,
  Tag,
  Typography,
} from "antd";
import mime from "mime";
import { t } from "i18next";
import RefreshBarComponent from "../../Etablissements/CreateEpf/RefreshBarComponent";
import { useLoader } from "../../../context/LoaderContext";
import ButtonComponent from "../../Etablissements/CreateEpf/ButtonComponent";
import { settings, surveyLocalization } from "survey-core";
import { themeJson } from "../../Etablissements/CreateEpf/CreateEpfComponents/creationJson";
import { modifyFileLinks } from "../../../utils";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { InfoCircleOutlined, PaperClipOutlined } from "@ant-design/icons";
import axiosInstance from "../../../utils/axios";
import { useTranslation } from "react-i18next";
import exempleMatriculeFiscal from "../../../assets/exemple-matricule-fiscal.png";

settings.customIcons["icon-clear"] = "icon-delete";
settings.customIcons["icon-choosefile"] = "icon-modernbooleancheckchecked";
surveyLocalization.defaultLocale = "fr";
let survey = new Model();
survey.applyTheme(themeJson);

const ValidationProvisoire = () => {
  const params = useLocation();
  const [visible, setVisible] = useState(false);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [commentaires, setCommentaire] = useState(null);
  const { getSurveyData, getSurveyJsonValidationProvisoire, setIsLoading } =
    useLoader();

  const contrat_id = params?.state?.contrat_id;

  //const surveyJson =
  // {
  //   "completeText": "Soumettre la demande - تقديم المطلب",
  //   "editText": "Modifier - تعديل",
  //   "logoPosition": "right",
  //   "pageNextText": "Suivant - متابعة",
  //   "pagePrevText": "Précédant - رجوع",
  //   "pages": [
  //       {
  //           "description": "",
  //           "elements": [
  //               {
  //                   "elements": [
  //                       {
  //                           "errorLocation": "bottom",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "maskSettings": {
  //                               "pattern": "9999999a"
  //                           },
  //                           "maskType": "pattern",
  //                           "name": "numero_matricule_fiscal_numero_IURNE",
  //                           "startWithNewLine": false,
  //                           "title": "Matricule fiscal / Numéro IURNE - رقم المعرّف الجبائي",
  //                           "type": "text"
  //                       },
  //                       {
  //                           "errorLocation": "bottom",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "maskSettings": {
  //                               "pattern": "999"
  //                           },
  //                           "maskType": "pattern",
  //                           "name": "numero_etablissement_secondaire",
  //                           "startWithNewLine": false,
  //                           "title": "Numéro établissement secondaire - رقم الفرع الثانوي",
  //                           "type": "text"
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "inputType": "Button",
  //                           "maxWidth": "10%",
  //                           "name": "data_validation_provisoire_search",
  //                           "startWithNewLine": false,
  //                           "title": " ",
  //                           "type": "text"
  //                       },
  //                       {
  //                           "errorLocation": "bottom",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "numero_matricule_CNSS",
  //                           "readOnly": true,
  //                           "startWithNewLine": true,
  //                           "title": "Numéro de matricule CNSS - رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "denomination_officielle_CNSS",
  //                           "readOnly": true,
  //                           "startWithNewLine": false,
  //                           "title": "Raison CNSS - تسمية المؤسسة لدى الصندوق الوطني للضمان الاجتماعي",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "denomination_officielle_RNE_arabe",
  //                           "readOnly": true,
  //                           "title": "Dénomination officielle RNE (en arabe) - (بالعربية) التسمية الرسمية",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "denomination_officielle_RNE_latin",
  //                           "readOnly": true,
  //                           "startWithNewLine": false,
  //                           "title": "Dénomination officielle RNE (en français) - (بالفرنسية) التسمية الرسمية",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "denomination_commerciale_RNE_arabe",
  //                           "readOnly": true,
  //                           "startWithNewLine": true,
  //                           "title": "Dénomination commerciale  RNE (en arabe) - (بالعربية) التسمية التجارية",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "denomination_commerciale_RNE_latin",
  //                           "readOnly": true,
  //                           "startWithNewLine": false,
  //                           "title": "Dénomination commerciale RNE (en français) - (بالفرنسية) التسمية التجارية",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "adresse_EPF",
  //                           "readOnly": true,
  //                           "title": "Adresse EPF - عنوان المؤسسة",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "adresse_epf_DGI",
  //                           "readOnly": true,
  //                           "startWithNewLine": false,
  //                           "title": "Adresse EPF (DGI) - عنوان المؤسسة",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "adresse_epf_RNE",
  //                           "readOnly": true,
  //                           "startWithNewLine": false,
  //                           "title": "Adresse EPF (RNE) - عنوان المؤسسة",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "activite_principale_RNE",
  //                           "readOnly": true,
  //                           "title": "Activité principale (RNE) - نشاط رئيسي",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "activite_secondaire_RNE",
  //                           "readOnly": true,
  //                           "startWithNewLine": false,
  //                           "title": "Activité secondaire (RNE) - نشاط ثانوي",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "activite_principale_DGI",
  //                           "readOnly": true,
  //                           "title": "Activité principale (DGI) - نشاط رئيسي",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "activite_secondaire_DGI",
  //                           "readOnly": true,
  //                           "startWithNewLine": false,
  //                           "title": "Activité secondaire (DGI) - نشاط ثانوي",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "errorLocation": "bottom",
  //                           "hideNumber": true,
  //                           "inputType": "date",
  //                           "isRequired": true,
  //                           "name": "date_expiration_protection_civile",
  //                           "title": "Date de l’expiration de l'attestation de la protection civile - تاريخ انتهاء صلاحية شهادة الوقاية",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //                           "errorLocation": "bottom",
  //                           "filePlaceholder": "Téléchargez une copie du RNE - قم بتحميل نسخة من مضمون السجل الوطني للمؤسسات ",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "doc_rne",
  //                           "storeDataAsText": false,
  //                           "title": "RNE - مضمون السجل الوطني للمؤسسات",
  //                           "type": "file",
  //                           "visible": false
  //                       },
  //                       {
  //                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //                           "errorLocation": "bottom",
  //                           "filePlaceholder": "Téléchargez une copie de la Patente - قم بتحميل نسخة من  بطاقة التعريف الجبائي - الباتيندة",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "doc_patente",
  //                           "startWithNewLine": false,
  //                           "storeDataAsText": false,
  //                           "title": "Patente - بطاقة التعريف الجبائي - الباتيندة",
  //                           "type": "file",
  //                           "visible": false
  //                       },
  //                       {
  //                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //                           "errorLocation": "bottom",
  //                           "filePlaceholder": "Téléchargez une copie de l'affiliation CNSS - قم بتحميل نسخة من شهادة الانخراط بالصندوق الوطني للضمان الاجتماع",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "doc_affiliation_CNSS",
  //                           "startWithNewLine": false,
  //                           "storeDataAsText": false,
  //                           "title": "Affiliation CNSS - نسخة من شهادة الانخراط بالصندوق الوطني للضمان الاجتماعي",
  //                           "type": "file",
  //                           "visible": false
  //                       },
  //                       {
  //                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //                           "errorLocation": "bottom",
  //                           "filePlaceholder": "Téléchargez une copie de l'attestation de la protection civile - قم بتحميل نسخة من شهادة الوقاية",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "doc_attestation_protection_civile",
  //                           "storeDataAsText": false,
  //                           "title": "Attestation de la protection civile - شهادة الوقاية",
  //                           "type": "file",
  //                           "visible": false
  //                       },
  //                       {
  //                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //                           "errorLocation": "bottom",
  //                           "filePlaceholder": "Téléchargez une copie de B3 de l'investisseur - قم بتحميل نسخة من  بطاقة السوابق العدلية عدد 3 للمستثمر",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "doc_b3_investisseur",
  //                           "startWithNewLine": false,
  //                           "storeDataAsText": false,
  //                           "title": "B3 de l'investisseur - بطاقة السوابق العدلية عدد 3 للمستثمر",
  //                           "type": "file",
  //                           "visible": false
  //                       },
  //                       {
  //                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //                           "errorLocation": "bottom",
  //                           "filePlaceholder": "Téléchargez une copie du B3 du directeur - قم بتحميل نسخة من بطاقة السوابق العدلية عدد 3 للمدير",
  //                           "hideNumber": true,
  //                           "isRequired": true,
  //                           "name": "doc_b3_directeur",
  //                           "startWithNewLine": false,
  //                           "storeDataAsText": false,
  //                           "title": "B3 du directeur - بطاقة السوابق العدلية عدد 3 للمدير",
  //                           "type": "file",
  //                           "visible": false
  //                       },

  //                       {
  //                         "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //                         "errorLocation": "bottom",
  //                         "filePlaceholder": "Téléchargez une copie du B3 du responsable de l'annexe - قم بتحميل نسخة من بطاقة السوابق العدلية عدد 3 للمسؤول عن الفرع",
  //                         "hideNumber": true,
  //                         "isRequired": true,
  //                         "name": "doc_b3_responsable",
  //                         "startWithNewLine": false,
  //                         "storeDataAsText": false,
  //                         "title": "B3 du responsable de l'annexe - بطاقة السوابق العدلية عدد 3 للمسؤول عن الفرع",
  //                         "type": "file",
  //                         "visible": false
  //                     },

  //                       {
  //                           "acceptedTypes": ".jpg,.jpeg,.png,.pdf",
  //                           "errorLocation": "bottom",
  //                           "filePlaceholder": "Téléchargez d'autres documents - قم بتحميل وثائق أخرى ",
  //                           "hideNumber": true,
  //                           "isRequired": false,
  //                           "name": "doc_autres",
  //                           "storeDataAsText": false,
  //                           "title": "Autres documents - وثائق أخرى",
  //                           "type": "file",
  //                           "visible": false
  //                       },
  //                       {
  //                           "hideNumber": true,
  //                           "inputType": "refreshbar",
  //                           "name": "data_spinner",
  //                           "startWithNewLine": false,
  //                           "title": " ",
  //                           "type": "text",
  //                           "visible": false
  //                       },
  //                       {
  //                           "name": "validation_provisoire_check",
  //                           "type": "text",
  //                           "visible": false
  //                       }
  //                   ],
  //                   "name": "question1",
  //                   "type": "panel"
  //               }
  //           ],
  //           "name": "page1"
  //       }
  //   ],
  //   "previewText": "Aperçu - عرض",
  //   "progressBarShowPageTitles": false,
  //   "sendResultOnPageNext": true,
  //   "showCompletedPage": false,
  //   "showPreviewBeforeComplete": false,
  //   "storeOthersAsComment": false
  // }
  const onUploadFiles = (survey, id_affaire, id_type_doc) => {
    survey.onUploadFiles.add((_, options) => {
      const formData = new FormData();
      options.files.forEach((file) => {
        const newFileName = `${
          options.question.title.split("-")[0] + nanoid(12)
        }.${file.name.split(".").pop()}`;
        const renamedFile = new File([file], newFileName, { type: file.type });
        formData.append("id_affaire", id_affaire);
        formData.append("id_type_doc", id_type_doc);
        formData.append("name_doc", options.name);
        // formData.append("file[]", file);
        formData.append("file[]", renamedFile);
        formData.append("key", options.name);
      });

      uploadDocumentAffaire(formData)
        .then((data) => {
          options.callback(
            options.files.map((file) => {
              return {
                file: file,
                content: data.data.path,
              };
            })
          );

          setTimeout(() => {
            const linkElement = document?.querySelector(
              `a[href="${data?.data?.path}"][title="${data?.data?.path
                .split("/")
                .pop()}"][download="${data?.data?.path?.split("/")?.pop()}"]`
            );

            if (linkElement) {
              linkElement?.setAttribute("target", "_blank");
            } else {
              console.log("Element not found");
            }
          }, [3000]);
        })
        .catch((error) => {
          options.callback(
            [],
            [
              t(
                "Une erreur est survenue lors du téléversement de votre document. Veuillez réessayer."
              ),
            ]
          );
        });
    });
  };

  const onComplete = (survey) => {
    survey.onCompleting.add(async (sender, options) => {
      if (!survey.getValue("validation_provisoire_check")) {
        options.allowComplete = false;
        notification.error({
          message: t("Erreur"),
          description: t(
            "Veuillez valider les données en utilisant le bouton de recherche."
          ),
          duration: 0,
        });
      } else {
        setIsLoading(true);
        // survey.getQuestionByName("data_spinner").visible = true;
        const dataUpdateStatus = await updateActionAffaire({
          id_affaire: params?.state?.id_affaire,
          id_status: import.meta.env
            .VITE_APP_ID_STATUS_COMPLEMENT_VALIDATION_PROVISOIRE,
        });

        if (dataUpdateStatus) {
          const payload = {
            additional_form: "{}",
            json_prod: { ...survey.data },
          };
          if (payload) {
            const data = await updateAffaire(
              params?.state?.id_affaire,
              payload,
              ""
            );
            if (data) {
              // survey.getQuestionByName("data_spinner").visible = false;
              setIsLoading(false);
              notification.success({
                // message: t("Succès"),
                description: t(
                  "Les informations fournies ont été ajoutées avec succès à votre demande. Vous recevrez bientôt une notification par email ou sur la plateforme."
                ),
                duration: 0,
              });

              navigate("/demandes");
            } else {
              // survey.getQuestionByName("data_spinner").visible = false;
              setIsLoading(false);

              notification.error({
                description: t("Enregistrement échoué, merci de réessayer."),
              });
            }
          }
        } else {
          // survey.getQuestionByName("data_spinner").visible = false;
          setIsLoading(false);

          notification.error({
            description: t("Enregistrement échoué, merci de réessayer."),
          });
        }
      }
    });
  };

  const onErrorCustomText = (survey) => {
    survey.onErrorCustomText.add(function (sender, options) {
      if (options.name == "required") {
        options.text = t("Veuillez remplir ce champ.");
      }
    });
  };
  const onValueChanging = (survey) => {
    const page = survey.getPageByName("page1");
    survey.onValueChanged.add(async function (sender, options) {
      if (
        options.name === "numero_matricule_fiscal_numero_IURNE" ||
        options.name === "numero_etablissement_secondaire"
      ) {
        page.questions.forEach((question) => {
          const valuesVisible = [
            "numero_etablissement_secondaire",
            "numero_matricule_fiscal_numero_IURNE",
            "data_validation_provisoire_search",
          ];
          if (!valuesVisible.includes(question.name)) {
            survey.setValue("validation_provisoire_check", false);
            question.visible = false;
            question.clearValue();
          }
        });
      }
    });
  };

  const handleGetComment = (id_affaire) => {
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        const id_user = JSON.parse(
          sessionStorage.getItem("-x-token-user")
        ).id_user;
        if (
          res?.data?.info?.filter((item) => item.user_id !== id_user).length > 0
        ) {
          setCommentaire(
            res?.data?.info?.filter((item) => item.user_id !== id_user).pop()
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const surveyJson = getSurveyJsonValidationProvisoire();

    if (surveyJson) {
      survey?.setJsonObject(surveyJson);

      onErrorCustomText(survey);
      onValueChanging(survey);
      onComplete(survey);
      onUploadFiles(
        survey,
        params?.state?.id_affaire,
        import.meta.env.VITE_APP_ID_TYPE_DOC_COMPLEMENT_VALIDATION_PROVISOIRE
      );

      survey.onAfterRenderQuestion.add(function (sender, options) {
        const fileInputButton =
          options?.htmlElement?.querySelector(".sd-file__wrapper");
        if (fileInputButton) {
          const spanElement = fileInputButton?.querySelector("span");
          if (spanElement) {
            spanElement.textContent = "Télécharger le fichier - تحميل الملف";
          }
        }
        if (options.question.getType() === "file") {
          setTimeout(() => {
            modifyFileLinks(survey, options);
          }, [1000]);
        }
        const fileInputButtonBeforeChoose = options?.htmlElement?.querySelector(
          ".sd-file__choose-btn"
        );
        if (fileInputButtonBeforeChoose) {
          const spanElement =
            fileInputButtonBeforeChoose?.querySelector("span");
          if (spanElement) {
            spanElement.textContent = "Télécharger le fichier - تحميل الملف";
          }
        }

        const emptyTextDiv = options?.htmlElement?.querySelector(
          ".sv-list__empty-text"
        );
        if (emptyTextDiv) {
          emptyTextDiv.textContent =
            "Veuillez d'abord remplir le champ précédent !";
        }

        const placeholderNoFile = options?.htmlElement?.querySelector(
          ".sd-file__decorator"
        );
        if (placeholderNoFile) {
          const placeholder = placeholderNoFile?.querySelector(
            ".sd-file__drag-area-placeholder"
          );
          if (placeholder) {
            const spanElement = placeholder?.querySelector(".sv-string-viewer");
            if (
              spanElement &&
              spanElement?.textContent === "No file selected"
            ) {
              spanElement.textContent =
                "Aucun fichier téléchargé - لم يتم تحميل أي ملف";
            }
          }
        }
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "datepicker",
        title: "Date picker",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType === "date"
          );
        },
        render: function (question, element) {
          return <DatePikckerComponent question={question} survey={survey} />;
        },
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "Button",
        title: "Button",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType?.toLowerCase() === "button"
          );
        },
        render: function (question, element) {
          return (
            <ButtonComponent question={question} contrat_id={contrat_id} />
          );
        },
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "refreshbar",
        title: "Refresh Bar",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType === "refreshbar"
          );
        },
        render: function (question, element) {
          return <RefreshBarComponent question={question} />;
        },
      });

      async function fetchData() {
        const data = await getSurveyData();

        survey.setValue("validation_provisoire_check", false);
        const regex_matricule = /^[0-9]{7}[A-Z]$/;
        if (
          data?.investor_RNE ===
            "رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)" &&
          ((data?.legal_entity_tax_number &&
            regex_matricule.test(data?.legal_entity_tax_number)) ||
            (data?.establishment_data_tax_number &&
              regex_matricule.test(data?.establishment_data_tax_number)))
        ) {
          survey.setValue(
            "numero_matricule_fiscal_numero_IURNE",
            data?.legal_entity_tax_number
              ? data?.legal_entity_tax_number
              : data?.establishment_data_tax_number
          );
          survey.getQuestionByName(
            "numero_matricule_fiscal_numero_IURNE"
          ).readOnly = true;
        } else if (
          contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE &&
          (data?.numero_matricule_fiscal_numero_IURNE ||
            data?.numero_matricule_fiscal_IURNE)
        ) {
          survey.setValue(
            "numero_matricule_fiscal_numero_IURNE",
            data?.numero_matricule_fiscal_numero_IURNE
              ? data?.numero_matricule_fiscal_numero_IURNE
              : data?.numero_matricule_fiscal_IURNE
          );
          survey.getQuestionByName(
            "numero_matricule_fiscal_numero_IURNE"
          ).readOnly = true;
        }
        if (
          sessionStorage.getItem("consulter") === "true" &&
          (params?.state?.etat_demande ===
            parseInt(import.meta.env.VITE_APP_ETAT_EN_COURS_DE_VALIDATION) ||
            params?.state?.etat_demande ===
              parseInt(
                import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_CREATION_EPF
              ) ||
            params?.state?.etat_demande ===
              parseInt(import.meta.env.VITE_APP_ETAT_VALIDATION_FINALE) ||
            params?.state?.etat_demande ===
              parseInt(import.meta.env.VITE_APP_ETAT_DEMANDE_REFUSEE))
        ) {
          survey.getAllQuestions().map((question) => {
            question.readOnly = true;
          });
          const numeroEtatSecondaire = data?.numero_etablissement_secondaire;
          if (numeroEtatSecondaire === "000") {
            survey.getQuestionByName("numero_matricule_CNSS").visible = true;
            survey.getQuestionByName(
              "denomination_officielle_CNSS"
            ).visible = true;
            survey.getQuestionByName(
              "denomination_officielle_RNE_arabe"
            ).visible = true;
            survey.getQuestionByName(
              "denomination_officielle_RNE_latin"
            ).visible = true;
            survey.getQuestionByName(
              "denomination_commerciale_RNE_arabe"
            ).visible = true;
            survey.getQuestionByName(
              "denomination_commerciale_RNE_latin"
            ).visible = true;
            survey.getQuestionByName("adresse_EPF").visible = true;
            survey.getQuestionByName("adresse_epf_DGI").visible = true;
            survey.getQuestionByName("activite_principale_DGI").visible = true;
            survey.getQuestionByName("activite_secondaire_DGI").visible = true;

            survey.getQuestionByName(
              "date_expiration_protection_civile"
            ).visible = true;
            survey.getQuestionByName("doc_rne").visible = true;
            if (
              data?.investor_RNE ===
              "الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)"
            ) {
              survey.getQuestionByName("doc_patente").visible = true;
            }

            if (contrat_id !== import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE) {
              survey.getQuestionByName("doc_affiliation_CNSS").visible = true;
            }

            survey.getQuestionByName(
              "doc_attestation_protection_civile"
            ).visible = true;
            if (
              data?.investor_nationality === "تونسية" &&
              contrat_id !== import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
            ) {
              survey.getQuestionByName("doc_b3_investisseur").visible = true;
            }

            if (
              data?.establishment_director_data_identity ===
                "مدير غير المستثمر" &&
              data?.establishment_director_data_nationality === "تونسية"
            ) {
              const questionName =
                contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                  ? "doc_b3_responsable"
                  : "doc_b3_directeur";
              survey.getQuestionByName(questionName).visible = true;
            }
            survey.getQuestionByName("doc_autres").visible = true;

            survey.getQuestionByName("adresse_epf_RNE").visible = true;
            survey.getQuestionByName("activite_principale_RNE").visible = true;
            survey.getQuestionByName("activite_secondaire_RNE").visible = true;
          } else {
            survey.getQuestionByName("numero_matricule_CNSS").visible = true;
            survey.getQuestionByName(
              "denomination_officielle_CNSS"
            ).visible = true;
            survey.getQuestionByName(
              "denomination_officielle_RNE_arabe"
            ).visible = true;
            survey.getQuestionByName(
              "denomination_officielle_RNE_latin"
            ).visible = true;
            survey.getQuestionByName(
              "denomination_commerciale_RNE_arabe"
            ).visible = true;
            survey.getQuestionByName(
              "denomination_commerciale_RNE_latin"
            ).visible = true;
            survey.getQuestionByName("adresse_EPF").visible = true;
            survey.getQuestionByName("adresse_epf_DGI").visible = true;
            survey.getQuestionByName("activite_principale_DGI").visible = true;
            survey.getQuestionByName("activite_secondaire_DGI").visible = true;
            survey.getQuestionByName(
              "date_expiration_protection_civile"
            ).visible = true;
            survey.getQuestionByName("doc_rne").visible = true;
            if (
              data?.investor_RNE ===
              "الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)"
            ) {
              survey.getQuestionByName("doc_patente").visible = true;
            }
            if (contrat_id !== import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE) {
              survey.getQuestionByName("doc_affiliation_CNSS").visible = true;
            }
            survey.getQuestionByName(
              "doc_attestation_protection_civile"
            ).visible = true;
            if (
              data?.investor_nationality === "تونسية" &&
              contrat_id !== import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
            ) {
              survey.getQuestionByName("doc_b3_investisseur").visible = true;
            }

            if (
              data?.establishment_director_data_identity ===
                "مدير غير المستثمر" &&
              data?.establishment_director_data_nationality === "تونسية"
            ) {
              const questionName =
                contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                  ? "doc_b3_responsable"
                  : "doc_b3_directeur";
              survey.getQuestionByName(questionName).visible = true;
            }
            survey.getQuestionByName("doc_autres").visible = true;
          }

          survey.showCompleteButton = false;
          survey.showPreviewBeforeComplete = false;
        }

        const currentData = {
          ...survey.data,
          ...data,
        };

        data?.response?.key_urls?.forEach((item) => {
          const fileType = mime.getType(item.name_doc);
          currentData[item.key] = [
            {
              content: item.url,
              name: item.name_doc,
              type: fileType,
            },
          ];
        });
        survey.mergeData(currentData);
        survey.data = currentData;
        survey.render();
      }
      handleGetComment(params?.state?.id_affaire);
      fetchData();
    }

    return () => {
      survey.dispose(true, true);
      survey = new Model();
    };
  }, []);

  return (
    <div>
      <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
        <h1 style={{ fontSize: "24px", textAlign: "center" }}>
          Étape de l'obtention de la validation finale - مرحلة الحصول على
          المصادقة النهائية
        </h1>
        {commentaires && (
          <div style={{ textAlign: i18n.dir() === "rtl" ? "right" : "left" }}>
            <Divider orientation={"left"}>
              {t("Commentaires  et Documents de l'Administration")}
            </Divider>
            <Typography.Paragraph style={{ fontSize: 14 }}>
              {t(
                "Lors du traitement de votre demande, l'administration a ajouté des documents ou des commentaires pour vous guider dans les démarches à suivre, vous les trouverez dans cette section :"
              )}
            </Typography.Paragraph>

            <div
              style={{
                background: "#FFFFFF",
                borderRadius: "13px",
                padding: "10px",
              }}
            >
              <Typography.Paragraph style={{ fontSize: 14 }}>
                {commentaires?.commentaire}
                <br />
                <i style={{ fontSize: 12 }}>
                  {dayjs(commentaires?.date).format("DD/MM/YYYY HH:mm")}
                </i>
              </Typography.Paragraph>
              {commentaires?.docs?.length > 0 && (
                <Typography.Paragraph style={{ fontSize: 14 }}>
                  {commentaires?.docs?.map((doc, index) => (
                    <>
                      <a href={doc?.doc} target="_blank" key={"doc-" + index}>
                        <Tag color="geekblue">
                          <PaperClipOutlined /> {doc?.name}
                        </Tag>
                      </a>
                    </>
                  ))}
                </Typography.Paragraph>
              )}
            </div>
          </div>
        )}
        <Divider orientation={"left"}>
          {t("Fourniture des Informations et Documents Requis")}
        </Divider>
        <Typography.Paragraph
          style={{
            fontSize: 14,
            textAlign: i18n.dir() === "rtl" ? "right" : "left",
          }}
        >
          {t(
            "Cette section est dédiée à la saisie des informations demandées et au téléchargement des documents nécessaires pour compléter votre demande. Assurez-vous de remplir correctement tous les champs requis et de fournir les fichiers dans le format demandé, afin de faciliter le traitement de votre dossier. Avant de valider, vérifiez que toutes les informations et documents ont bien été fournis."
          )}
          <p>
            <InfoCircleOutlined
              style={{
                color: "#1777FF",
              }}
            />{" "}
            {t(
              "Si vous ne savez pas où obtenir votre « Numéro d'établissement secondaire - رقم الفرع الثانوي », vous pouvez consulter votre carte d'identification fiscale"
            )}{" "}
            -{" "}
            <a onClick={() => setVisible(true)}>
              {t("Voici un exemple de carte d'identification fiscale")}
            </a>
            <Image
              width={200}
              style={{
                display: "none",
              }}
              src={exempleMatriculeFiscal}
              preview={{
                visible,
                scaleStep: 0.3,
                src: exempleMatriculeFiscal,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            />
          </p>
        </Typography.Paragraph>
      </div>

      <ConfigProvider direction="ltr">
        <div style={{ direction: "ltr" }}>
          <Survey model={survey} />
        </div>
      </ConfigProvider>
    </div>
  );
};

export default ValidationProvisoire;
