import { CalendarOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Modal, Tag, Timeline, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

const CommentModal = (props) => {
  const { commentaire, setCommentaire } = props;
  const id_user = JSON.parse(sessionStorage.getItem("-x-token-user")).id_user;
  const { t } = useTranslation();

  return (
    <Modal
      title={t("Commentaires de l'administration concernée")}
      open={commentaire?.length > 0}
      width={1000}
      onCancel={() => setCommentaire([])}
      footer={null}
    >
      <Timeline
        style={{ paddingTop: "20px" }}
        items={commentaire
          ?.slice(0)
          .reverse()
          .map((comment) => ({
            children: (
              <Typography.Paragraph
                style={{
                  fontSize: 16,
                  background:
                    comment.user_id === id_user ? "#FAFAFA" : "#E8F0FE",
                  borderRadius: "13px",
                  padding: "10px",
                  border: "1px solid #E8E8E8",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {comment.user_id === id_user
                      ? t("Moi :")
                      : t("Administration :")}
                  </span>

                  <span
                    style={{
                      color: "#024fa8",
                      fontStyle: "italic",
                      fontSize: 12,
                    }}
                  >
                    <CalendarOutlined />
                    {comment?.date &&
                      dayjs(comment?.date).format("DD/MM/YYYY HH:mm:ss")}{" "}
                  </span>
                </div>

                <span>{comment?.commentaire}</span>
                {comment?.docs?.map((doc, index) => (
                  <>
                    <br />
                    <a href={doc?.doc} target="_blank" key={"doc-" + index}>
                      <Tag color="geekblue">
                        <PaperClipOutlined /> {doc?.name}
                      </Tag>
                    </a>
                  </>
                ))}
              </Typography.Paragraph>
            ),
          }))}
      />
    </Modal>
  );
};

export default CommentModal;
