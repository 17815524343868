import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseModal from "./CloseModal/CloseModal";
import SelectionModal from "./SelectionModal/SelectionModal";
import {
  BranchesOutlined,
  DownloadOutlined,
  CommentOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import classes from "../Etablissements/Etablissements.module.css";
import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Button,
  Space,
  notification,
  Dropdown,
} from "antd";
import { useNavigate } from "react-router-dom";
import { getAffaires, getSchema } from "../../api/geoprod";
import CommentModal from "../Etablissements/CreateEpf/Modals/CommentModal";
import { useLoader } from "../../context/LoaderContext";
import ModalModification from "../Etablissements/CreateEpf/Modals/ModalModification";
import axiosInstance from "../../utils/axios";
import dayjs from "dayjs";
import ModalListAnnexes from "../Etablissements/CreateEpf/Modals/ModalListAnnexes";

const Etablissements = () => {
  const navigate = useNavigate();
  const [stopModal, setStopModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [listDataValidee, setListDataValidee] = useState([]);
  const [listBrouillonAnnexe, setListBrouillonAnnexe] = useState([]);
  const [commentaire, setCommentaire] = useState([]);
  const { t } = useTranslation();
  const [brouillonEpfPrinicipalOrExistant, setBrouillonEpfPrincipalOrExistant] =
    useState({
      modification: [],
    });
  const idAffaire = new URLSearchParams(window.location.search).get("id");
  const {
    setIsLoading,
    setSurveyJson,
    setSurveyData,
    setSurveyJsonValidationProvisoire,
  } = useLoader();
  const [modal, setModal] = useState("");
  const [investisseurDirecteur, setInvestisseurDIrecteur] = useState();
  const [epfPrincipale, setEpfPrincipale] = useState([]);

  const role = sessionStorage.getItem("role");

  const demandeModifictaion = async (id_affaire) => {
    setIsLoading(true);
    sessionStorage.setItem("demande", false);
    const data = await getSchema(id_affaire);
    if (data?.response?.data) {
      setIsLoading(false);

      await setSurveyData(data);

      if (sessionStorage.getItem("role") === "directeur") {
        sessionStorage.setItem(
          "user_id_to_get",

          data?.response?.id_user
        );
      } else {
        sessionStorage.setItem(
          "user_id_to_get",
          data?.response?.responsable_EPF
        );
      }

      const establishment_director_data_identity =
        data?.response?.data?.establishment_director_data_identity;

      const anyPanelIsHomologed =
        data?.response?.data?.training_nature_data?.some(
          (panel) => panel.training_type_data_type === "منظرة"
        );
      sessionStorage.setItem("Modifier_sans_validation", !anyPanelIsHomologed);

      if (
        role !== "directeur" &&
        establishment_director_data_identity === "مدير غير المستثمر"
      ) {
        setInvestisseurDIrecteur("investisseur");
      } else if (
        role !== "directeur" &&
        establishment_director_data_identity === "المستثمر نفسه"
      ) {
        setInvestisseurDIrecteur("investisseur-directeur");
      } else {
        setInvestisseurDIrecteur("directeur");
      }

      setModal(id_affaire);
    } else {
      setIsLoading(false);
    }
  };

  const handleGetEPFPrincipale = (id_affaire) => {
    setIsLoading(true);

    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/v1/get_contrat_affaire/${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setEpfPrincipale([res?.data?.contrat?.affaire_parent]);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: t("Numéro d'enregistrement"),
      key: "Numéro d'enregistrement",
      render: (_, record) =>
        record?.registration_number
          ? record?.registration_number
          : t("Non renseigné"),
    },
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.establishment_data_official_name ||
        record?.legal_entity_official_name,
    },
    {
      title: t("Responsable"),
      dataIndex: "responsable",
      key: "responsable",
      render: (_, record) =>
        record?.establishment_director_data_last_name +
        " " +
        record?.establishment_director_data_first_name,
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record?.establishment_data_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record?.establishment_data_delegation,
    },
    {
      title: t("Matricule fiscal"),
      key: "matricule",
      render: (_, record) =>
        record?.establishment_data_tax_number ||
        record?.legal_entity_tax_number ||
        record?.numero_matricule_fiscal_numero_IURNE ||
        t("Non renseigné"),
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record?.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record?.establishment_data_training_type,
    },
    {
      title: t("Date de création"),
      key: "Date de création",
      render: (_, record) =>
        record?.last_update
          ? dayjs(record?.last_update).format("DD/MM/YYYY HH:MM:ss")
          : t("Non renseigné"),
    },
    // {
    //   title: t("Statut"),
    //   key: "statut",
    //   render: (_, record) => {
    //     return <Tag color={record?.etat_background}>{record?.etat}</Tag>;
    //   },
    // },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          {/*  */}
          {/* <Tooltip title={t("Consulter")}>
            <EyeOutlined
              onClick={() => {
                setIsLoading(true);
                sessionStorage.setItem("showingPreview", true);


                axiosInstance
                  .get(
                    import.meta.env.VITE_APP_GEOPROD_API +
                      "/get_info_specifique/" +
                      record?.affaire_id,
                    {
                      headers: {
                        Idsession: JSON.parse(
                          sessionStorage.getItem("-x-token-user")
                        )?.Acces_Token,
                      },
                    }
                  )
                  .then(async (res) => {
                    setIsLoading(false);
                    setSurveyJson(res?.data?.response?.form);
                    sessionStorage.setItem("demande", true);
                    const navigateTo = `/annexes/save?affaire_id=${record?.affaire_id}`;
                    if (sessionStorage.getItem("role") === "directeur") {
                      sessionStorage.setItem(
                        "user_id_to_get",
                        res?.data?.response?.responsable_EPF
                      );
                    } else {
                      sessionStorage.setItem(
                        "user_id_to_get",
                        res?.data?.response?.id_user
                      );
                    }
                    navigate(navigateTo, {
                      state: {
                        id_affaire: record.affaire_id,
                        etat_demande: record?.id_etat_dossier,
                        contrat_id: res?.data?.response?.contrat_id,
                      },
                    });
                    setSurveyData(res?.data?.response?.data);
                    setSurveyJsonValidationProvisoire(
                      res?.data?.response?.form_valid
                    );
                  })
                  .catch(() => {
                    setIsLoading(false);
                    notification.error({
                      message: t("Erreur"),
                      description:
                        "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                      duration: 0,
                    });
                  });
              }}
            />
          </Tooltip> */}
          {/*  */}
          {/* <Tooltip title={t("Modifier")}>
            <EditOutlined
              onClick={() => {
                demandeModifictaion(record?.affaire_id);

                // setModal()
              }}
            />
          </Tooltip> */}
          {/*  */}
          {/* <Tooltip title={t("Commentaires")}>
            <CommentOutlined
              onClick={() => handleGetComment(record?.affaire_id)}
            />
          </Tooltip> */}
          {/*  */}
          {/* <Tooltip title={t("EPF principale")}>
            <BranchesOutlined
              onClick={() => handleGetEPFPrincipale(record?.affaire_id)}
            />
          </Tooltip> */}
          {/*  */}

          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => {
                        setIsLoading(true);
                        sessionStorage.setItem("consulter", true);
                        sessionStorage.setItem("showingPreview", true);

                        axiosInstance
                          .get(
                            import.meta.env.VITE_APP_GEOPROD_API +
                              "/get_info_specifique/" +
                              record?.affaire_id,
                            {
                              headers: {
                                Idsession: JSON.parse(
                                  sessionStorage.getItem("-x-token-user")
                                )?.Acces_Token,
                              },
                            }
                          )
                          .then(async (res) => {
                            setIsLoading(false);
                            setSurveyJson(res?.data?.response?.form);
                            sessionStorage.setItem("demande", true);
                            const navigateTo = `/annexes/save?affaire_id=${record?.affaire_id}`;
                            if (
                              sessionStorage.getItem("role") === "directeur"
                            ) {
                              sessionStorage.setItem(
                                "user_id_to_get",
                                res?.data?.response?.responsable_EPF
                              );
                            } else {
                              sessionStorage.setItem(
                                "user_id_to_get",
                                res?.data?.response?.id_user
                              );
                            }
                            navigate(navigateTo, {
                              state: {
                                id_affaire: record.affaire_id,
                                etat_demande: record?.id_etat_dossier,
                                contrat_id: res?.data?.response?.contrat_id,
                              },
                            });
                            setSurveyData(res?.data?.response?.data);
                            setSurveyJsonValidationProvisoire(
                              res?.data?.response?.form_valid
                            );
                          })
                          .catch(() => {
                            setIsLoading(false);
                            notification.error({
                              message: t("Erreur"),
                              description:
                                "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                              duration: 0,
                            });
                          });
                      }}
                    >
                      {t("Consulter l'annexe")}
                    </Button>
                  ),
                },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => {
                        demandeModifictaion(record?.affaire_id);

                        // setModal()
                      }}
                    >
                      {t("Modifier")}
                    </Button>
                  ),
                },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => handleGetComment(record?.affaire_id)}
                    >
                      {t("Commentaires")}
                    </Button>
                  ),
                },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => handleGetEPFPrincipale(record?.affaire_id)}
                    >
                      {t("EPF principale")}
                    </Button>
                  ),
                },
              ],
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {t("Liste")}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState();

  const openModalSelection = () => {
    if (listBrouillonAnnexe?.length > 0) {
      notification.info({
        message: t("info"),
        description: t(
          "Vous avez déjà commencé une demande de création d'annexe. Merci de consulter votre brouillon."
        ),
        duration: 0,
      });
    } else {
      setIsModalVisible(listDataValidee);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
          filters: {
            contrat: [
              import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE,
              import.meta.env.VITE_ID_LIGNE_PRODUIT,
              import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE,
              import.meta.env
                .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO,
              import.meta.env
                .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO,
              import.meta.env
                .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO,
            ],
            status: [
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF,
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF_EXIST,
              import.meta.env.VITE_APP_ID_STATUS_SOUMISE_SANS_VALIDATION,
              import.meta.env
                .VITE_APP_STATUS_VALIDEE_MODIFICATION_AVEC_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,

              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
            ],
          },
        });
        const excludedStatuses = [
          import.meta.env.VITE_APP_ID_STATUS_BROUILLON,
          import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
          import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
          import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
        ];
        setData(
          response?.aaData?.filter(
            (elem) =>
              elem?.etat === "Demande validée" &&
              elem.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE &&
              elem.id_etat_dossier_md5 !==
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON
          )
        );
        setListDataValidee(
          response?.aaData.filter(
            (elem) =>
              elem?.affaire_id_contrat !==
                import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE &&
              !excludedStatuses.includes(elem.id_etat_dossier_md5)
          )
        );

        setListBrouillonAnnexe(
          response?.aaData.filter(
            (elem) =>
              elem?.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE &&
              elem.id_etat_dossier_md5 ===
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON
          )
        );

        setBrouillonEpfPrincipalOrExistant((prevState) => ({
          ...prevState,

          modification: response?.aaData?.filter(
            (elem) =>
              elem?.id_etat_dossier_md5 ===
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION ||
              elem?.id_etat_dossier_md5 ===
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION
          ),
        }));
        setLoading(false);
      } catch (error) {}
    }

    fetchData();
    if (idAffaire) {
      handleGetComment(idAffaire);
    }
  }, []);

  const handleGetComment = (id_affaire) => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.info?.length > 0) {
          setCommentaire(res?.data?.info);
        } else {
          notification.warning({
            message: t(
              "Vous ne disposez pas de commentaire pour cette demande !"
            ),
            duration: 0,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <CloseModal stopModal={stopModal} setStopModal={setStopModal} />

      <SelectionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <ModalListAnnexes
        listAnnexes={epfPrincipale}
        setListAnnexes={setEpfPrincipale}
        type="affaireParent"
      />

      <CommentModal commentaire={commentaire} setCommentaire={setCommentaire} />
      <ModalModification
        modal={modal}
        setModal={setModal}
        role={investisseurDirecteur}
        type={"annexe"}
        brouillonEpfPrinicipalOrExistant={brouillonEpfPrinicipalOrExistant}
        // surveyData={surveyData}
      />
      {role !== "directeur" && (
        <Row className={classes.rowButtonCreate}>
          <Tooltip
            title={
              listDataValidee?.length === 0
                ? t(
                    "Vous devez disposer d'au moins un EPF pour pouvoir créer une annexe"
                  )
                : ""
            }
          >
            <Button
              disabled={listDataValidee?.length === 0}
              className={classes.buttonCreate}
              onClick={openModalSelection}
            >
              <PlusOutlined />
              {t("Créer une nouvelle Annexe")}
            </Button>
          </Tooltip>
        </Row>
      )}

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste de mes Annexes")}
        >
          <Table
            loading={loading}
            locale={{ emptyText: t("Aucune donnée.") }}
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Etablissements;
