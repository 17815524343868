import React, { useContext, useEffect } from "react";
import { useLoader } from "../../context/LoaderContext";
import { Model, CustomWidgetCollection, surveyLocalization } from "survey-core";
import { Survey } from "survey-react-ui";
import GlobalContext from "../../context/GlobalContext";
import {
  fetchCodePostalList,
  fetchDelegationList,
} from "../../context/FetchLists";
import { onValueChanging } from "../Etablissements/CreateEpf/CreateEpfComponents/surveyEvents";
import { updateInfoProfile } from "../../api/geoprod";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import DatePikckerComponent from "../Etablissements/CreateEpf/DatePIkcer";
import ButtonComponent from "../Etablissements/CreateEpf/ButtonComponent";
import mime from "mime";
import axiosInstance from "../../utils/axios";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";

surveyLocalization.defaultLocale = "fr";
const SurveyProfile = () => {
  const { t } = useTranslation();
  const { getSurveyJson } = useLoader();
  const navigate = useNavigate();
  const ProfileJson = getSurveyJson();
  const surveyProfile = new Model({ locale: "fr", ...ProfileJson });

  surveyProfile.onErrorCustomText.add(function (sender, options) {
    if (options.name == "required") {
      options.text = t("Veuillez remplir ce champ.");
    }
  });
  const { gouvernoratList, nationalityList, listAllDelegations } =
    useContext(GlobalContext);
  const role = sessionStorage.getItem("role");
  const displayQuestionsFunction = () => {
    surveyProfile.getAllQuestions().forEach(async (question) => {
      const page = surveyProfile.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        const director_questions_to_modify = [
          "establishment_director_data_address",
          "establishment_director_data_governorate",
          "establishment_director_data_delegation",
          "establishment_director_data_email",
          "establishment_director_data_phone_number",
          "establishment_director_data_education_level",
          "establishment_director_data_director_folder",
          "establishment_director_data_date_delivrance",
          "establishment_director_data_experience_years",
          "establishment_director_data_specific_needs",
          "establishment_director_data_cin_copie",
          "copie_autorisation_ministre",
          "establishment_director_data_passeport_copie",
        ];
        page.questions.map((question) => {
          question.readOnly = !director_questions_to_modify.includes(
            question.name
          );
          if (
            question.name !== "establishment_director_data_test_valid" &&
            question.name !== "establishment_director_data_passeport_copie" &&
            question.name !== "establishment_director_data_cin_copie" &&
            question.name !== "establishment_director_data_date_delivrance" &&
            question.name !==
              "establishment_director_data_ministerial_auth_date" &&
            question.name !== "establishment_director_data_passeport" &&
            question.name !== "establishment_director_data_cin_number" &&
            question.name !== "establishment_director_data_spinner" &&
            question.name !==
              "establishment_director_date_expiration_passeport" &&
            question.name !== "director_search"
          ) {
            question.visible = true;
          }
          if (
            surveyProfile.getQuestionByName(
              "establishment_director_data_nationality"
            ).value === "تونسية"
          ) {
            if (
              question.name === "establishment_director_data_cin_copie" ||
              question.name === "establishment_director_data_date_delivrance"
            ) {
              question.visible = true;
            }
          } else if (
            surveyProfile.getQuestionByName(
              "establishment_director_data_nationality"
            ).value !== "تونسية"
          ) {
            if (
              question.name === "establishment_director_data_passeport_copie" ||
              question.name ===
                "establishment_director_data_ministerial_auth_date" ||
              question.name ===
                "establishment_director_date_expiration_passeport"
            ) {
              question.visible = true;
            }
          }
        });
      }

      const invPage = surveyProfile.getPageByName("page1");
      if (invPage) {
        const investorQuestionsToChange = [
          "investor_date_delivrance",
          "investor_residence_governorate",
          "investor_residence_delegation",
          "investor_residence_postal_code",
          "investor_phone_number",
          "investor_specific_needs",
          "investor_residence_address",
          "establishment_director_data_specific_needs",
          "investor_eduction_level",
          "investor_passport",
          "investor_copie_passeport",
          "investor_copie_cv",
          "investor_copie_cin",
          "investor_date_expiration_passeport",
          "investor_email",
        ];

        if (surveyProfile.data.investor_nationality !== "تونسية") {
          investorQuestionsToChange.push("investor_civility");
          investorQuestionsToChange.push("investor_birth_place");
        }

        if (
          !surveyProfile.data.investor_last_name_fr ||
          !surveyProfile.data.investor_first_name_fr
        ) {
          investorQuestionsToChange.push("investor_last_name_fr");
          investorQuestionsToChange.push("investor_first_name_fr");
        }

        invPage.questions.forEach((question) => {
          question.readOnly = !investorQuestionsToChange.includes(
            question.name
          );
        });
      }
    });
  };

  const setFileValues = (value) => {
    const fileType = mime.getType(value.name);
    const file = {
      content: value.content,
      name: value.name,
      type: fileType,
    };
    return file;
  };

  const parseDate = (dateStr) => {
    if (dayjs(dateStr, "YYYY-MM-DD", true).isValid()) {
      return dayjs(dateStr).format("YYYY-MM-DD");
    }
    if (dayjs(dateStr, "YYYY/MM/DD", true).isValid()) {
      return dayjs(dateStr, "YYYY/MM/DD").format("YYYY-MM-DD");
    }
    if (dayjs(dateStr, "DD/MM/YYYY", true).isValid()) {
      return dayjs(dateStr, "DD/MM/YYYY").format("YYYY-MM-DD");
    }
  };

  const setUserData = (userData) => {
    const fieldsToSet = [
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_passeport"
            : "investor_passport",
        userDataField: "passport",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_first_name"
            : "investor_first_name_ar",
        userDataField: "prenom_ar",
        readOnly: true,
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_last_name"
            : "investor_last_name_ar",
        userDataField: "nom_ar",
        readOnly: true,
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_experience_years"
            : "",
        userDataField: "experience_years",
        readOnly: true,
      },

      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_first_name_fr"
            : "investor_first_name_fr",
        userDataField: "prenom",
        readOnly: true,
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_last_name_fr"
            : "investor_last_name_fr",
        userDataField: "nom",
        readOnly: true,
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_email"
            : "investor_email",
        userDataField: "mail",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_date_expiration_passeport"
            : "investor_date_expiration_passeport",
        userDataField: "expiration_passeport",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_birth_date"
            : "investor_birth_date",
        userDataField: "date_naiss_user",
        readOnly: true,
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_genre"
            : "investor_civility",
        userDataField: "civilite",
      },

      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_nationality"
            : "investor_nationality",
        userDataField: "nationality",
        readOnly: true,
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_cin_number"
            : "investor_cin",
        userDataField: "cin",
        readOnly: true,
      },

      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_date_delivrance"
            : "investor_date_delivrance",
        userDataField: "delivrance_date",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_birth_place"
            : "investor_birth_place",
        userDataField: "birthplace",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_education_level"
            : "investor_eduction_level",
        userDataField: "niveau_scolaire",
        readOnly: true,
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_phone_number"
            : "investor_phone_number",
        userDataField: "telephone",
        readOnly: true,
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_address"
            : "investor_residence_address",
        userDataField: "adresse",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_governorate"
            : "investor_residence_governorate",
        userDataField: "goverment",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_delegation"
            : "investor_residence_delegation",
        userDataField: "delegation",
      },
      {
        surveyField:
          role === "directeur" ? "" : "investor_residence_postal_code",
        userDataField: "code_postal",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_specific_needs"
            : "investor_specific_needs",
        userDataField: "besoins_specifiques",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_cin_copie"
            : "investor_copie_cin",
        userDataField: "copie_cin",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_passeport_copie"
            : "investor_copie_passeport",
        userDataField: "copie_passeport",
      },
      {
        surveyField:
          role === "directeur"
            ? "establishment_director_data_director_folder"
            : "investor_copie_cv",
        userDataField: "copie_cv",
      },
      {
        surveyField: role === "directeur" ? "copie_autorisation_ministre" : "",
        userDataField: "copie_autorisation_ministre",
      },
    ];

    fieldsToSet.forEach((field) => {
      const { surveyField, userDataField, readOnly } = field;
      const value =
        surveyField.includes("copie") && userData[userDataField]
          ? setFileValues(userData[userDataField])
          : surveyField.includes("_date") && userData[userDataField]
          ? parseDate(userData[userDataField])
          : userData[userDataField];
      const question = surveyProfile.getQuestionByName(surveyField);

      if (!value) {
        question ? (question.readOnly = false) : null;
      } else {
        surveyProfile.setValue(surveyField, value);
        if (readOnly) {
          question.readOnly = true;
        }
      }
    });
  };

  const getData = () => {
    const user = JSON.parse(sessionStorage.getItem("-x-token-user"));

    // async function fetchData() {
    //   // setIsLoading(true);
    //   let data;
    //   if (user?.nationality === "تونسية" && user?.ehouwiya !== 1) {
    //     data = await checkCitizenStatusByCinDn(
    //       user?.user?.date_naiss_user,
    //       user?.cin
    //     );
    //   }

    //   if (data?.prenomAr) {
    //     survey.setValue("investor_first_name_ar", data?.prenomAr);
    //     const name_ar = survey.getQuestionByName("investor_first_name_ar");
    //     name_ar.readOnly = true;
    //   }
    //   if (data?.nomAr) {
    //     survey.setValue("investor_last_name_ar", data?.nomAr);
    //     const prenom_ar = survey.getQuestionByName("investor_last_name_ar");
    //     prenom_ar.readOnly = true;
    //   }
    //   if (data?.commune) {
    //     let lieu_naissance = await getCommuneByCodeCommune(data?.commune);

    //     survey.setValue("investor_birth_place", lieu_naissance);

    //     survey.getQuestionByName("investor_birth_place").readOnly = true;

    //     // Object.keys(codes_communes).map((e) => {
    //     //   Object.keys(codes_communes[e].communes).map((elem) => {
    //     //     if (codes_communes[e].communes[elem] === data?.commune) {
    //     //
    //     //     }
    //     //   });
    //     // });
    //   }
    //   if (data?.prenomFr) {
    //     survey.setValue("investor_last_name_fr", data?.prenomFr || user?.nom);
    //     const name_fr = survey.getQuestionByName("investor_last_name_fr");
    //     name_fr.readOnly = true;
    //   }
    //   if (data?.nomFr) {
    //     survey.setValue("investor_first_name_fr", data?.nomFr || user?.prenom);
    //     const prenom_fr = survey.getQuestionByName("investor_first_name_fr");
    //     prenom_fr.readOnly = true;
    //   }

    //   if (user?.email) {
    //     survey.setValue("investor_email", user?.email);
    //     const investor_email = survey.getQuestionByName("investor_email");
    //     investor_email.readOnly = true;
    //   }

    //   survey.setValue("investor_phone_number", user?.mobile);
    //   survey.setValue("investor_nationality", user?.nationality);
    //   survey.setValue(
    //     "investor_civility",
    //     data?.genre === "1" ? "ذكر" : "أنثى"
    //   );
    //   survey.setValue("investor_cin", user?.cin);
    //   survey.setValue(
    //     "investor_birth_date",
    //     dayjs(user?.user?.date_naiss_user).format("YYYY-MM-DD")
    //   );

    //   // setIsLoading(false);
    // }

    // fetchData();

    axiosInstance
      .get(`${import.meta.env.VITE_APP_GEOPROD_API}/get_user_with_id`, {
        headers: {
          Idsession: user?.Acces_Token,
        },
      })
      .then((data) => {
        surveyProfile.getAllQuestions().map(async (question) => {
          if (question.name?.toLowerCase().includes("_governorate")) {
            question.choices = gouvernoratList;

            const delegateKey = question.name.replace(
              "_governorate",
              "_delegation"
            );
            const selectedGouvernorat = question.value;
            const selectedDelegate =
              surveyProfile.getQuestionByName(delegateKey);
            const id_goverment = gouvernoratList.find(
              (e) => e.value === selectedGouvernorat
            )?.id;
            if (id_goverment) {
              const delegations = await fetchDelegationList(id_goverment);

              selectedDelegate.choices = delegations || [];

              const selectedDelegateValue = selectedDelegate.value;
              const postalCodeQuestion = surveyProfile.getQuestionByName(
                question.name.replace("_delegation", "_postal_code")
              );

              const idDelegation = listAllDelegations.find(
                (elem) => elem.value === selectedDelegateValue
              )?.id;

              if (idDelegation) {
                const listPostalCodes = await fetchCodePostalList(idDelegation);
                if (listPostalCodes) {
                  postalCodeQuestion.choices = listPostalCodes.map((code) => ({
                    value: code.value,
                    text: code.text,
                  }));
                } else {
                  postalCodeQuestion.choices = [];
                }
              }
            }
          }

          // if (question.name?.includes("_delegation")) {
          //   const selectedDelegation = question.value;
          //   const postalCodeQuestion = surveyProfile.getQuestionByName(
          //     question.name.replace("_delegation", "_postal_code")
          //   );

          //   const idDelegation = listAllDelegations.find(
          //     (elem) => elem.value === selectedDelegation
          //   )?.id;

          //   if (idDelegation) {
          //     const listPostalCodes = await fetchCodePostalList(idDelegation);
          //     if (listPostalCodes) {
          //       postalCodeQuestion.choices = listPostalCodes.map((code) => ({
          //         value: code.value,
          //         text: code.text,
          //       }));
          //     } else {
          //       postalCodeQuestion.choices = [];
          //     }
          //   }}

          if (question.name.toLowerCase().includes("_nationality")) {
            question.choices = nationalityList;
          }
        });
        setUserData(data?.data?.user);

        //   data?.response?.key_urls?.forEach((item) => {
        //     const fileType = mime.getType(item.name_doc);
        //     currentData[item.key] = [
        //       {
        //         content: item.url,
        //         name: item.name_doc,
        //         type: fileType,
        //       },
        //     ];
        //   });
        surveyProfile.showProgressBar = "off";

        surveyProfile.showPreviewBeforeComplete = false;

        // surveyProfile.data = currentData;

        displayQuestionsFunction();
      });
  };

  onValueChanging(surveyProfile, gouvernoratList, listAllDelegations);

  surveyProfile.onUploadFiles.add((_, options) => {
    const formData = new FormData();
    const id_user = JSON.parse(
      sessionStorage.getItem("-x-token-user")
    )?.id_user;

    let fileName;
    switch (options.name) {
      case "establishment_director_data_passeport_copie":
      case "investor_copie_passeport":
        fileName = "copie_passeport";
        break;
      case "establishment_director_data_cin_copie":
      case "investor_copie_cin":
        fileName = "copie_cin";
        break;
      case "establishment_director_data_director_folder":
      case "investor_copie_cv":
        fileName = "copie_cv";
        break;
      case "copie_autorisation_ministre":
        fileName = "copie_autorisation_ministre";
        break;
    }

    options.files.forEach((file) => {
      const newFileName = `${
        options.question.title.split("-")[0] + nanoid(12)
      }.${file.name.split(".").pop()}`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      formData.append("id_user", id_user);
      // formData.append(fileName, file);
      formData.append(fileName, renamedFile);
    });

    axiosInstance
      .post(
        `${import.meta.env.VITE_APP_GEOPROD_API}/upload_doc_user`,
        formData,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        options.callback(
          options.files.map((file) => {
            return {
              file: file,
              content: res.data.url,
            };
          })
        );

        setTimeout(() => {
          const linkElement = document?.querySelector(
            `a[href="${res?.data?.path}"][title="${res?.data?.path
              .split("/")
              .pop()}"][download="${res?.data?.path?.split("/")?.pop()}"]`
          );

          if (linkElement) {
            linkElement.setAttribute("target", "_blank");
          } else {
            console.log("Element not found");
          }
        }, [3000]);
      });

    // uploadDocumentAffaire(formData)
    //   .then((data) => {
    //     options.callback(
    //       options.files.map((file) => {
    //         return {
    //           file: file,
    //           content: data.data.path[0],
    //         };
    //       })
    //     );
    //   })
    //   .catch((error) => {
    //     options.callback(
    //       [],
    //       ["Veuillez importer seulement des images et des documents pdf."]
    //     );
    //   });
  });

  surveyProfile.onComplete.add(async (sender, options) => {
    const rolePayload =
      role === "investisseur"
        ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR
        : role === "directeur"
        ? import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR
        : role === "investisseur-directeur"
        ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR_DIRCETEUR
        : import.meta.env.VITE_APP_ID_ROLE_FORMATEUR;

    const payloadUpdateProfil = {
      adresse: {
        geom: "",
      },
      user: {
        acc_affaire: [],
        acc_opportunite: [],
        acces_avenant: 2,
        acces_communiques: 2,
        acces_courrier_entrant: 0,
        acces_distrib: 0,
        acces_edit_maileva: 2,
        acces_ett: true,
        acces_fusion: 2,
        acces_maileva: 2,
        acces_qualite: 2,
        acces_reclamation_ticket: 1,
        acces_signature: 0,
        act_grp_communication: 2,
        act_grp_negociateur: 2,
        act_grp_pubpostage: 2,
        act_grp_statut: 2,
        act_grp_tags: 2,
        active: 1,
        admin_restreint: 0,
        admin_tpv: 2,
        adresse:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_address
            : surveyProfile.data.investor_residence_address,
        adresse_auto: "*",
        api_key: "",
        assistant: 0,
        beta: 0,
        birthplace:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_birth_place
            : surveyProfile.data.investor_birth_place,
        bloc_env: 0,
        cin:
          role === "directeur" &&
          surveyProfile.data.establishment_director_data_nationality ===
            "تونسية"
            ? surveyProfile.data.establishment_director_data_cin_number
            : surveyProfile.data.investor_nationality === "تونسية"
            ? surveyProfile.data.investor_cin
            : "",
        civilite:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_genre
            : surveyProfile.data.investor_civility,
        code: null,
        comparateur: 0,
        date_integration: null,
        experience_years:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_experience_years
            : "",
        date_naiss_user:
          role === "directeur"
            ? dayjs(
                surveyProfile.data.establishment_director_data_birth_date
              ).format("YYYY-MM-DD")
            : dayjs(surveyProfile.data.investor_birth_date).format(
                "YYYY-MM-DD"
              ),
        delegation:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_delegation
            : surveyProfile.data.investor_residence_delegation,
        ecrit_comp_aff: 0,
        ecriture_transaction_financiere_client: 2,
        ecriture_transaction_financiere_distribution: 2,
        entreprise_id: import.meta.env.VITE_APP_ID_Entreprise_INVESTISSEUR,
        espace_distrib_access: {
          acces_info_spec: 2,
          changement_statut: 2,
        },
        delivrance_date:
          role === "directeur"
            ? dayjs(
                surveyProfile.data.establishment_director_data_date_delivrance
              )
            : dayjs(surveyProfile.data.investor_date_delivrance).format(
                "DD/MM/YYYY"
              ),
        expiration_passeport:
          role === "directeur"
            ? dayjs(
                surveyProfile?.data
                  ?.establishment_director_date_expiration_passeport
              ).format("YYYY-MM-DD")
            : dayjs(
                surveyProfile?.data?.investor_date_expiration_passeport
              ).format("YYYY-MM-DD"),
        execution_tpv: 2,
        exp_aff: null,
        exp_maileva: 2,
        exp_opp: null,
        export_aff_det: 0,
        export_maileva_det: 2,
        export_opp_det: 0,
        fiche_dec: 0,
        filtre: 0,
        first_login: null,
        goverment:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_governorate
            : surveyProfile.data.investor_residence_governorate,
        groupe_commission: null,
        groupe_user: [],
        groupe_user_alert: [],
        groupe_user_alert_nom: [],
        heure_debut: "0:00:00",
        heure_fin: null,
        hist: 2,
        hist_comm: 2,
        id: 1002234,
        identite_reelle: null,
        lect_comp_aff: 0,
        lecture_tpv: 2,
        lecture_transaction_financiere_client: 2,
        lecture_transaction_financiere_distribution: 2,
        limit_listing_aff: "100",
        limit_listing_maileva: "2",
        limit_listing_opp: "100",
        location: null,
        mail:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_email
            : surveyProfile.data.investor_email,
        manager: null,
        manager_de: [],
        manager_de_nom: [],
        manager_list: [],
        manager_nom: [],
        matricule_rh: null,
        mobile:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_phone_number
            : surveyProfile.data.investor_phone_number,
        autoriser_dossier: "",
        nationality:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_nationality
            : surveyProfile.data.investor_nationality,
        nom:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_last_name_fr
            : surveyProfile.data.investor_last_name_fr,
        lieu_naissance: "directeur"
          ? surveyProfile.data.establishment_director_data_birth_place
          : surveyProfile.data?.investor_birth_place,
        nom_ar:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_last_name
            : surveyProfile.data.investor_last_name_ar,
        nom_groupe_commission: null,
        nom_organisme: "",
        nom_producteur: null,
        nom_role: "",
        nom_zone: null,
        num_interne: null,
        orias_producteur: null,
        passport:
          role === "directeur" &&
          surveyProfile.data.establishment_director_data_nationality !==
            "تونسية"
            ? surveyProfile.data.establishment_director_data_passeport
            : surveyProfile.data.investor_nationality !== "تونسية"
            ? surveyProfile.data.investor_passport
            : "",
        password: "",
        photo: null,
        pointage: 0,
        prenom:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_first_name_fr
            : surveyProfile.data.investor_first_name_fr,
        prenom_ar:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_first_name
            : surveyProfile.data.investor_first_name_ar,

        prenom_producteur: null,
        proprietaire: true,
        qualite_producteur: null,
        role: rolePayload,

        role_auto: "[]",
        role_auto_nom: [],
        samedi_off: 0,
        signature: null,
        skype: null,
        ssl_mail_data: 0,
        super_admin: true,
        tab_code: [],
        tarificateur: 2,
        tel2: null,
        tel_urgence: null,
        telephone:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_phone_number
            : surveyProfile.data.investor_phone_number,
        transaction_financiere: 2,
        type_user: "c81e728d9d4c2f636f067f89cc14862c",
        user_groupe_nom: [],
        user_pwd_data: null,
        validate_paiement: 2,
        domaines_specialite: "",
        code_postal:
          role === "directeur"
            ? ""
            : surveyProfile.data.investor_residence_postal_code,
        niveau_scolaire:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_education_level
            : surveyProfile.data.investor_eduction_level,
        besoins_specifiques:
          role === "directeur"
            ? surveyProfile.data.establishment_director_data_specific_needs
            : surveyProfile.data.investor_specific_needs,
        zone: null,
      },
      ville: {},
    };
    const user = JSON.parse(sessionStorage.getItem("-x-token-user"));
    const dataProfile = updateInfoProfile(payloadUpdateProfil, user.id_user);

    if (dataProfile) {
      navigate("/demandes");
      sessionStorage.removeItem("id_affaire_profile");
    }
  });

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "datepicker",
    title: "Date picker",
    isFit: function (question) {
      return (
        question.getType() === "text" && question?.jsonObj?.inputType === "date"
      );
    },
    render: function (question, element) {
      return (
        <DatePikckerComponent question={question} survey={question.survey} />
      );
    },
  });

  CustomWidgetCollection.Instance.addCustomWidget({
    name: "Button",
    title: "Button",
    isFit: function (question) {
      return (
        question.getType() === "text" &&
        question?.jsonObj?.inputType?.toLowerCase() === "button"
      );
    },
    render: function (question, element) {
      return <ButtonComponent question={question} />;
    },
  });

  useEffect(() => {
    getData();
  }, [surveyProfile]);

  return (
    <ConfigProvider direction="ltr">
      <div style={{ direction: "ltr" }}>
        <Survey model={surveyProfile} />
      </div>
    </ConfigProvider>
  );
};

export default SurveyProfile;
