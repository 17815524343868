import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  Select,
  notification,
  Spin,
  Space,
} from "antd";

import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/sigaf.svg";
import republique from "../../assets/tunisia.png";
import { useTranslation } from "react-i18next";

import classes from "./ResetPassword.module.css";
import axiosInstance from "../../utils/axios";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const { form, globalData, setGlobalData, setIsRtl } =
    useContext(GlobalContext);
  const { Option } = Select;
  const [loader, setLoader] = useState(false);
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);
    setIsRtl(value === "ar");
  };

  const token = new URLSearchParams(window.location.search).get("token");

  const handleFinish = async (values) => {
    setLoader(true);
    // setLoaderForget(true);
    axiosInstance
      .put(
        `${import.meta.env.VITE_APP_GEOPROD_API}/reset_password?password=${
          values.password_change_reset
        }`,
        {},
        {
          headers: {
            token: token,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        notification.success({
          message: t("Votre mot de passe a été réinitialisé avec succès."),
          duration: 0,
        });

        navigate(
          `/signin/role=${
            res.data?.role.role === "Investisseur"
              ? "investisseur"
              : "formateur"
          }`
        );
      })
      .catch((err) => {
        setLoader(false);

        notification.error({
          message: err?.response.data?.message,
          duration: 0,
        });
      });
  };

  return (
    <Row gutter={24} style={{ display: "flex", justifyContent: "center" }}>
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
          }}
        >
          <img src={republique} style={{ height: "86px" }} />
          <img src={logo} />
          <Select
            style={{ width: "8rem", display: "flex", alignItems: "center" }}
            onChange={handleLanguageChange}
            value={localStorage.getItem("language") || "fr"}
          >
            <Option value="fr">Français</Option>
            <Option value="ar">العربية</Option>
          </Select>
        </Row>
      </Col>

      <Col lg={9}>
        <Card
          className="p-4 p-sm-7"
        >
          <h2 className="mb-2">{t("Mot de passe oublié ?")}</h2>

          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            disabled={loader}
          >
            <Form.Item
              label={t("Mot de passe")}
              name="password_change_reset"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[*/.@-])[A-Za-z\d*/.@-]{6,}$/,
                  message: t(
                    "Le mot de passe doit contenir 6 caractères alphanumériques (A-Z, a-z, 0-9) et des caractères spéciaux (*/.@-)"
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("Confirmer le mot de passe")}
              name="confirm_password_reset"
              dependencies={["password_change"]}
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("password_change_reset") === value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t(
                          "Le mot de passe et la confirmation doivent être identiques."
                        )
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item>
                <Space>
                  {loader && <Spin />}
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#21A8A8",
                      width: "7rem",
                      color: "white",
                    }}
                    htmlType="submit"
                  >
                    {t("Confirmer")}
                  </Button>
                </Space>
              </Form.Item>
              <p
                style={{ textAlign: "left" }}
                className={classes.seConnecter}
                onClick={() => navigate(`/signin`)}
              >
                {t("Se connecter")}{" "}
              </p>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPassword;
