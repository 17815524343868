import {
  Button,
  Col,
  Form,
  Row,
  Upload,
  notification,
  Spin,
  Modal,
  Typography,
  Divider,
  Tag,
  Space,
} from "antd";
import {
  DownloadOutlined,
  InboxOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import classes from "./ComplementDossier.module.css";
import {
  updateActionAffaire,
  uploadDocumentAffaire,
} from "../../../api/geoprod";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "../../../utils/axios";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { useLoader } from "../../../context/LoaderContext";
const { Dragger } = Upload;

const ComplementDossier = () => {
  const [loader, setLoader] = useState(false);
  const { form } = useContext(GlobalContext);
  const params = useLocation();
  const data = params?.state?.data_complement_dossier || {};
  const id_user = JSON.parse(sessionStorage.getItem("-x-token-user")).id_user;
  const [confirmModal, setConfirmModal] = useState(false);
  const complement_dossier_uploaded =
    params?.state?.complement_dossier_uploaded || {};
  const { setIsLoading } = useLoader();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const initialFileList = Object?.keys(data)?.reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {});

  const [fileList, setFileList] = useState(initialFileList);
  const [commentaires, setCommentaire] = useState(null);

  const handleGetComment = (id_affaire) => {
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        if (
          res?.data?.info?.filter((item) => item.user_id !== id_user)?.length >
          0
        ) {
          setCommentaire(
            res?.data?.info?.filter((item) => item.user_id !== id_user).pop()
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (complement_dossier_uploaded) {
      const newFileList = { ...fileList };
      const currentFormValues = form?.getFieldsValue();
      complement_dossier_uploaded?.forEach((file) => {
        currentFormValues[file.key] = file?.url;
        newFileList[file?.key] = [
          {
            uid: file?.id,
            name: file?.name_doc,
            status: "done",
            url: file?.url,
          },
        ];
      });
      form.setFieldsValue(currentFormValues);
      setFileList(newFileList);
    }

    handleGetComment(params?.state?.id_affaire);
  }, []);

  const handleChange = async (info, type) => {
    switch (info?.file?.status) {
      case "uploading":
        setFileList({ ...fileList, [type]: [info?.file] });
        break;
      case "done":
        {
          setFileList({ ...fileList, [type]: [info?.file] });
          setLoader(true);
          const id_type_doc = import.meta.env
            .VITE_APP_ID_TYPE_DOC_COMPLEMENT_DOSSIER;
          const formData = new FormData();
          const newFileName = `${
            type + nanoid(12)
          }.${info?.file?.originFileObj.name.split(".").pop()}`;
          const renamedFile = new File(
            [info?.file?.originFileObj],
            newFileName,
            { type: info?.file?.originFileObj.type }
          );
          formData.append("id_affaire", params?.state?.id_affaire);
          formData.append("id_type_doc", id_type_doc);
          formData.append("name_doc", "Complement de dossier");
          formData.append("file[]", renamedFile);
          formData.append("key", type);
          try {
            await uploadDocumentAffaire(formData).then(async () => {
              setLoader(false);
            });
          } catch (error) {
            setLoader(false);
            notification.error({
              message: t(
                "Une erreur est survenue lors du téléversement de votre document. Veuillez réessayer."
              ),
              duration: 0,
            });
          }
        }

        break;
      default:
        setFileList({ ...fileList, [type]: [] });
        form.setFieldsValue({
          ...form.getFieldsValue(),
          [type]: undefined,
        });
        break;
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const change_status = () => {
    setConfirmModal(false);
    setIsLoading(true);
    updateActionAffaire({
      id_affaire: params?.state?.id_affaire,
      id_status:
        params?.state?.affaire_id_contrat ===
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO ||
        params?.state?.affaire_id_contrat ===
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
          ? import.meta.env.VITE_APP_ID_STATUS_COMPLEMENT_SOUMIS_MODIFICATION
          : params?.state?.affaire_id_contrat ===
            import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
          ? import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_SOUMIS_EPF_EXISTANT
          : import.meta.env.VITE_APP_ID_STATUS_COMPLEMENT_DOSSIER_SOUMISE,
    })
      .then(async () => {
        axiosInstance
          .post(
            `${import.meta.env.VITE_APP_GEOPROD_API}/commentaire/add`,
            {
              entity: "A",
              id_entity: params?.state?.id_affaire,
              commentaire: form.getFieldsValue()["commentaire"],
              visible_investisseur: 1,
            },
            {
              headers: {
                Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                  ?.Acces_Token,
              },
            }
          )
          .then(async (response) => {
            setIsLoading(false);
            notification.success({
              message: t("Tous les fichiers ont été téléchargés avec succès."),
              duration: 0,
            });
            navigate("/demandes");
          })
          .catch(() => {});
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        textAlign: i18n.dir() === "rtl" ? "right" : "left",
      }}
    >
      <Spin spinning={loader} tip="" style={{ width: "100%" }}>
        <Modal
          style={{ width: "100%" }}
          open={confirmModal}
          footer={null}
          width={600}
          onCancel={() => setConfirmModal(false)}
          title={t("Confirmation d'envoi ?")}
        >
          <h4>
            {" "}
            {t(
              "Êtes-vous sûr de vouloir envoyer les informations et documents ?"
            )}{" "}
          </h4>
          <Button type="primary" onClick={change_status}>
            {t("Oui")}
          </Button>
          <Button
            type="primary"
            style={{ marginInline: "15px" }}
            onClick={() => setConfirmModal(false)}
          >
            {" "}
            {t("Non")}
          </Button>
        </Modal>
        <div style={{ opacity: loader ? 0.7 : 1 }}>
          <Form form={form} layout="vertical" onFinish={change_status}>
            <Row justify="center">
              <Col lg={24} md={24} className={classes.Col}>
                <div className={classes.title}>
                  {t("Complétion de Votre Demande")}
                </div>
                {commentaires && (
                  <>
                    <Divider orientation={"left"}>
                      {t("Commentaires  et Documents de l'Administration")}
                    </Divider>
                    <Typography.Paragraph style={{ fontSize: 14 }}>
                      {t(
                        "Lors du traitement de votre demande, l'administration a ajouté des documents ou des commentaires pour vous guider dans les démarches à suivre, vous les trouverez dans cette section :"
                      )}
                    </Typography.Paragraph>

                    <div
                      style={{
                        background: "#FAFAFA",
                        borderRadius: "13px",
                        padding: "10px",
                      }}
                    >
                      <Typography.Paragraph style={{ fontSize: 14 }}>
                        {commentaires?.commentaire}
                        <br />
                        <i style={{ fontSize: 12 }}>
                          {dayjs(commentaires?.date).format("DD/MM/YYYY HH:mm")}
                        </i>
                      </Typography.Paragraph>
                      {commentaires?.docs?.length > 0 && (
                        <Typography.Paragraph style={{ fontSize: 14 }}>
                          {commentaires?.docs?.map((doc, index) => (
                            <>
                              <a
                                href={doc?.doc}
                                target="_blank"
                                key={"doc-" + index}
                              >
                                <Tag color="geekblue">
                                  <PaperClipOutlined /> {doc?.name}
                                </Tag>
                              </a>
                            </>
                          ))}
                        </Typography.Paragraph>
                      )}
                    </div>
                  </>
                )}
                <Divider orientation={"left"}>
                  {t("Téléchargement des Documents Requis")}
                </Divider>
                <Typography.Paragraph style={{ fontSize: 14 }}>
                  {t(
                    "Cette section est dédiée au téléchargement des documents demandés par l'administration pour compléter votre demande. Assurez-vous de fournir tous les fichiers requis au bon format afin de faciliter le traitement de votre dossier. Une fois les documents téléchargés, vérifiez que la liste est complète avant de valider."
                  )}
                </Typography.Paragraph>
                <Row gutter={[16, 16]}>
                  {Object.keys(data).map((type, index) => (
                    <Col sm={24} md={12} lg={12} key={index}>
                      <Form.Item
                        // className={classes.inputUpload}
                        name={type}
                        label={<label className={classes.label}>{type}</label>}
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez télécharger un fichier."),
                          },
                        ]}
                      >
                        <Dragger
                          onChange={(e) => handleChange(e, type)}
                          fileList={fileList[type]}
                          customRequest={dummyRequest}
                          maxCount={1}
                          listType="picture"
                          accept="jpg,.jpeg,.png,.pdf"
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Cliquez ou déposez votre fichier ici pour le
                            télécharger - انقر أو اسحب ملفك هنا لتحميله.
                          </p>
                          <p className="ant-upload-hint">
                            Formats acceptés - الملفات المسموح بها : .jpg,
                            .jpeg, .png, .pdf.
                          </p>
                        </Dragger>
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
                <Form.Item
                  name="commentaire"
                  label={
                    <label className={classes.label}>
                      Commentaires - التعاليق
                    </label>
                  }
                  rules={[{ required: false }]}
                >
                  <TextArea style={{ width: "100% !important" }} />
                </Form.Item>
                <Row justify="center">
                  <Col>
                    <Button
                      className={classes.buttonCreate}
                      loading={loader}
                      onClick={() => {
                        form
                          .validateFields()
                          .then(() => {
                            setConfirmModal(true);
                          })
                          .catch(() => {});
                      }}
                      htmlType="button"
                    >
                      <DownloadOutlined />
                      {t("Envoyer les documents")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default ComplementDossier;
