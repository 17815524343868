import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Button,
  Space,
  notification,
  Dropdown,
} from "antd";
import {
  DownloadOutlined,
  EditOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  PlusOutlined,
  CommentOutlined,
  BranchesOutlined,
  DownOutlined,
} from "@ant-design/icons";
import classes from "./Etablissements.module.css";
import { useState, useEffect } from "react";
import SelectionModal from "./SelectionModal/SelectionModal";
import { useLocation, useNavigate } from "react-router-dom";
import CloseModal from "./CloseModal/CloseModal";
import { useTranslation } from "react-i18next";
import { getAffaires, getDocByIdAffaire, getSchema } from "../../api/geoprod";
import ModalModification from "./CreateEpf/Modals/ModalModification";
import { useLoader } from "../../context/LoaderContext";
import { downloadFile } from "../../utils";
import CommentModal from "./CreateEpf/Modals/CommentModal";
import axiosInstance from "../../utils/axios";
import dayjs from "dayjs";
import ModalListAnnexes from "./CreateEpf/Modals/ModalListAnnexes";
import DownloadDocsModal from "./DownloadDocsModal";

const Etablissements = (props) => {
  const navigate = useNavigate();
  const [stopModal, setStopModal] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState("");
  const [investisseurDirecteur, setInvestisseurDIrecteur] = useState();
  const [commentaire, setCommentaire] = useState([]);
  const [brouillonEpfPrinicipalOrExistant, setBrouillonEpfPrincipalOrExistant] =
    useState({
      brouillonPrinipal: [],
      brouillonExistant: [],
      modification: [],
    });
  const [listAnnexes, setListAnnexes] = useState([]);
  // const [surveyData, setSurveyData] = useState({});
  const {
    setIsLoading,
    setSurveyJson,
    setSurveyData,
    setSurveyJsonValidationProvisoire,
  } = useLoader();
  const role = sessionStorage.getItem("role");
  // const path = window.location.pathname;
  const idAffaire = new URLSearchParams(window.location.search).get("id");
  const [docs, setDocs] = useState([]);
  const [openDocsModal, setOpenDocsModal] = useState(false);

  const demandeModifictaion = async (id_affaire) => {
    setIsLoading(true);
    sessionStorage.setItem("showingPreview", false);
    sessionStorage.setItem("demande", false);
    const data = await getSchema(id_affaire);
    if (data?.response?.data) {
      setIsLoading(false);

      await setSurveyData(data);

      if (sessionStorage.getItem("role") === "directeur") {
        sessionStorage.setItem("user_id_to_get", data?.response?.id_user);
      } else {
        sessionStorage.setItem(
          "user_id_to_get",
          data?.response?.responsable_EPF
        );
      }

      const establishment_director_data_identity =
        data?.response?.data?.establishment_director_data_identity;

      const anyPanelIsHomologed =
        data?.response?.data?.training_nature_data?.some(
          (panel) => panel.training_type_data_type === "منظرة"
        );
      sessionStorage.setItem("Modifier_sans_validation", !anyPanelIsHomologed);

      if (
        role !== "directeur" &&
        establishment_director_data_identity === "مدير غير المستثمر"
      ) {
        setInvestisseurDIrecteur("investisseur");
      } else if (
        role !== "directeur" &&
        establishment_director_data_identity === "المستثمر نفسه"
      ) {
        setInvestisseurDIrecteur("investisseur-directeur");
      } else {
        setInvestisseurDIrecteur("directeur");
      }

      setModal(id_affaire);
    } else {
      setIsLoading(false);
    }
  };

  // const determineNavigationPath = (record, form) => {
  //   const { affaire_id, affaire_id_contrat } = record;

  //   switch (affaire_id_contrat) {
  //     case import.meta.env
  //       .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
  //     case import.meta.env
  //       .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO:
  //     case import.meta.env
  //       .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
  //       return `/etablissements/edit`;

  //     default:
  //       return "/etablissements/save";
  //   }
  // };

  const columns = [
    {
      title: t("Numéro d'enregistrement"),
      key: "Numéro d'enregistrement",
      render: (_, record) =>
        record?.registration_number
          ? record?.registration_number
          : t("Non renseigné"),
    },
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.establishment_data_official_name ||
        record?.legal_entity_official_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record.establishment_director_data_last_name +
        " " +
        record.establishment_director_data_first_name,
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record.establishment_data_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record.establishment_data_delegation,
    },
    {
      title: t("Matricule fiscal"),
      key: "matricule",
      render: (_, record) =>
        record.establishment_data_tax_number ||
        record.legal_entity_tax_number ||
        record?.numero_matricule_fiscal_numero_IURNE ||
        t("Non renseigné"),
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record.establishment_data_training_type,
    },
    {
      title: t("Date de création"),
      key: "Date de création",
      render: (_, record) =>
        record?.last_update
          ? dayjs(record?.last_update).format("DD/MM/YYYY HH:MM:ss")
          : t("Non renseigné"),
    },
    // {
    //   title: t("Statut"),
    //   key: "statut",
    //   render: (_, record) => {
    //     return <Tag color={record?.etat_background}>{record?.etat}</Tag>;
    //   },
    // },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          {/*  */}
          {/* <Tooltip title={t("Consulter")}>
            <EyeOutlined
              onClick={async () => {
                setIsLoading(true);
                sessionStorage.setItem("showingPreview", true);

                const res = await getSchema(record.affaire_id);
                if (res?.response) {
                  setIsLoading(false);
                  const { form, data } = res?.response;
                  await setSurveyJson(form);
                  await setSurveyData(data);
                  setSurveyJsonValidationProvisoire(res?.response?.form_valid);
                  if (sessionStorage.getItem("role") === "directeur") {
                    sessionStorage.setItem(
                      "user_id_to_get",

                      res?.response?.id_user
                    );
                  } else {
                    sessionStorage.setItem(
                      "user_id_to_get",
                      res?.response?.responsable_EPF
                    );
                  }
                  if (
                    record?.affaire_id_contrat ===
                    import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
                  ) {
                    navigate("/etablissements/existante/save", {
                      state: {
                        idAffaireEpfExistant: record.affaire_id,
                      },
                    });
                  } else {
                    navigate("/etablissements/save", {
                      state: {
                        id_affaire: record.affaire_id,
                        etat_demande: record?.id_etat_dossier,
                        contrat_id: res?.response?.contrat_id,
                      },
                    });
                  }
                }
                setIsLoading(false);
              }}
            />
          </Tooltip> */}
          {/*  */}
          {/* <Tooltip title={t("Modifier")}>
            <EditOutlined
              onClick={() => {
                demandeModifictaion(record?.affaire_id);

                // setModal()
              }}
            />
          </Tooltip> */}
          {/*  */}
          {/* <Tooltip title={t("Télécharger: Déclaration / Décharge / BO")}>
            <DownloadOutlined
              onClick={async () => {
                setIsLoading(true);
                try {
                  const data = await getDocByIdAffaire(record?.affaire_id);
                  const documents = data?.data?.find(
                    (doc) =>
                      doc?.md5_id ===
                      import.meta.env.VITE_APP_ID_DOCUMENTS_RECU_EMAIL
                  );
                  const bo = data?.data?.find(
                    (doc) =>
                      doc?.md5_id === import.meta.env.VITE_APP_ID_DOCUMENTS_BO
                  );

                  if (documents?.files?.length) {
                    await Promise.all(
                      documents?.files.map((file) =>
                        downloadFile(file?.url_base64, file?.name)
                      )
                    );
                  }
                  if (bo?.files?.length) {
                    await Promise.all(
                      bo?.files.map((file) =>
                        downloadFile(file?.url_base64, file?.name)
                      )
                    );
                  }
                } catch (error) {
                  console.error("Erreur lors du téléchargement :", error);
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          </Tooltip> */}
          {/*  */}
          {/* <Tooltip title={t("Commentaires")}>
            <CommentOutlined
              onClick={() => handleGetComment(record?.affaire_id)}
            />
          </Tooltip> */}
          {/*  */}
          {/* <Tooltip title={t("Liste des annexes")}>
            <BranchesOutlined
              onClick={() => handleGetAnnexe(record?.affaire_id)}
            />
          </Tooltip> */}
          {/*  */}
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={async () => {
                        sessionStorage.setItem("consulter", true);
                        setIsLoading(true);
                        sessionStorage.setItem("showingPreview", true);

                        const res = await getSchema(record.affaire_id);
                        if (res?.response) {
                          setIsLoading(false);
                          const { form, data } = res?.response;
                          await setSurveyJson(form);
                          await setSurveyData(data);
                          setSurveyJsonValidationProvisoire(
                            res?.response?.form_valid
                          );
                          if (sessionStorage.getItem("role") === "directeur") {
                            sessionStorage.setItem(
                              "user_id_to_get",

                              res?.response?.id_user
                            );
                          } else {
                            sessionStorage.setItem(
                              "user_id_to_get",
                              res?.response?.responsable_EPF
                            );
                          }
                          if (
                            record?.affaire_id_contrat ===
                            import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
                          ) {
                            navigate("/etablissements/existante/save", {
                              state: {
                                idAffaireEpfExistant: record.affaire_id,
                              },
                            });
                          } else {
                            navigate("/etablissements/save", {
                              state: {
                                id_affaire: record.affaire_id,
                                etat_demande: record?.id_etat_dossier,
                                contrat_id: res?.response?.contrat_id,
                              },
                            });
                          }
                        }
                        setIsLoading(false);
                      }}
                    >
                      {t("Consulter l'établissement")}
                    </Button>
                  ),
                },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => {
                        demandeModifictaion(record?.affaire_id);

                        // setModal()
                      }}
                    >
                      {t("Modifier")}
                    </Button>
                  ),
                },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={async () => {
                        setIsLoading(true);
                        try {
                          const data = await getDocByIdAffaire(
                            record?.affaire_id
                          );
                          const documents = data?.data?.find(
                            (doc) =>
                              doc?.md5_id ===
                              import.meta.env.VITE_APP_ID_DOCUMENTS_RECU_EMAIL
                          );
                          const bo = data?.data?.find(
                            (doc) =>
                              doc?.md5_id ===
                              import.meta.env.VITE_APP_ID_DOCUMENTS_BO
                          );

                          setDocs([documents, bo]);
                          setOpenDocsModal(true);
                          // if (documents?.files?.length) {
                          //   await Promise.all(
                          //     documents?.files.map((file) =>
                          //       downloadFile(file?.url_base64, file?.name)
                          //     )
                          //   );
                          // }
                          // if (bo?.files?.length) {
                          //   await Promise.all(
                          //     bo?.files.map((file) =>
                          //       downloadFile(file?.url_base64, file?.name)
                          //     )
                          //   );
                          // }
                        } catch (error) {
                          console.error(
                            "Erreur lors du téléchargement :",
                            error
                          );
                        } finally {
                          setIsLoading(false);
                        }
                      }}
                    >
                      {t("Télécharger les documents ")}
                    </Button>
                  ),
                },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => handleGetComment(record?.affaire_id)}
                    >
                      {t("Commentaires")}
                    </Button>
                  ),
                },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => handleGetAnnexe(record?.affaire_id)}
                    >
                      {t("Liste des annexes")}
                    </Button>
                  ),
                },
              ],
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {t("Liste")}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
          filters: {
            contrat: [
              import.meta.env.VITE_ID_LIGNE_PRODUIT,
              import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE,
              import.meta.env
                .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO,
              import.meta.env
                .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO,
              import.meta.env
                .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO,
            ],
            status: [
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF,
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF_EXIST,
              import.meta.env.VITE_APP_ID_STATUS_SOUMISE_SANS_VALIDATION,
              import.meta.env
                .VITE_APP_STATUS_VALIDEE_MODIFICATION_AVEC_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON,

              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
            ],
          },
        });

        setData(
          data?.aaData?.filter(
            (elem) =>
              (elem?.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT &&
                elem?.id_etat_dossier_md5 !==
                  import.meta.env.VITE_APP_ID_STATUS_BROUILLON) ||
              (elem?.affaire_id_contrat ===
                import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE &&
                elem?.id_etat_dossier_md5 !==
                  import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT) ||
              (elem?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO &&
                elem?.id_etat_dossier_md5 !==
                  import.meta.env
                    .VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION) ||
              (elem?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO &&
                elem?.id_etat_dossier_md5 !==
                  import.meta.env
                    .VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION) ||
              (elem?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO &&
                elem?.id_etat_dossier_md5 !==
                  import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION)
          )
        );
        setBrouillonEpfPrincipalOrExistant((prevState) => ({
          ...prevState,
          brouillonPrinipal: data?.aaData?.filter(
            (elem) =>
              elem?.id_etat_dossier_md5 ===
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON &&
              elem?.affaire_id_contrat === import.meta.env.VITE_ID_LIGNE_PRODUIT
          ),
          brouillonExistant: data?.aaData?.filter(
            (elem) =>
              elem?.id_etat_dossier_md5 ===
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT ||
              (elem?.id_etat_dossier_md5 ===
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION &&
                elem?.investor_page === null &&
                elem?.origin_contrat_id !==
                  import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE)
          ),
          modification: data?.aaData?.filter(
            (elem) =>
              elem?.id_etat_dossier_md5 ===
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION ||
              elem?.id_etat_dossier_md5 ===
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION
          ),
        }));

        setLoading(false);
      } catch (error) {}
    }

    fetchData();

    if (idAffaire) {
      handleGetComment(idAffaire);
    }
  }, []);

  const [isModalVisible, setIsModalVisible] = useState();

  const openModalSelection = () => {
    sessionStorage.setItem("demande", false);
    setIsModalVisible(!isModalVisible);
  };

  const handleGetComment = (id_affaire) => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.info?.length > 0) {
          setCommentaire(res?.data?.info);
        } else {
          notification.warning({
            message: t(
              "Vous ne disposez pas de commentaire pour cette demande !"
            ),
            duration: 0,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleGetAnnexe = (id_affaire) => {
    setIsLoading(true);

    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/v1/get_contrat_affaire/${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.contrat?.affaire_fils?.length > 0) {
          setListAnnexes(res?.data?.contrat?.affaire_fils);
        } else {
          notification.warning({
            message: t("Vous ne disposez pas d'annexe pour cette EPF !"),
            duration: 0,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <CloseModal stopModal={stopModal} setStopModal={setStopModal} />
      <ModalModification
        modal={modal}
        setModal={setModal}
        role={investisseurDirecteur}
        brouillonEpfPrinicipalOrExistant={brouillonEpfPrinicipalOrExistant}
        // surveyData={surveyData}
      />
      <DownloadDocsModal
        open={openDocsModal}
        setOpen={setOpenDocsModal}
        docs={docs}
      />
      <ModalListAnnexes
        listAnnexes={listAnnexes}
        setListAnnexes={setListAnnexes}
      />
      <CommentModal commentaire={commentaire} setCommentaire={setCommentaire} />
      <SelectionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        brouillonEpfPrinicipalOrExistant={brouillonEpfPrinicipalOrExistant}
      />
      {role !== "directeur" && (
        <Row className={classes.rowButtonCreate}>
          <Space>
            <Button
              className={classes.buttonCreate}
              style={{ marginRight: "1rem" }}
              onClick={openModalSelection}
            >
              <PlusOutlined />
              {t("EPF existant")}
            </Button>
            <Button
              className={classes.buttonCreate}
              onClick={() => {
                if (
                  brouillonEpfPrinicipalOrExistant?.brouillonPrinipal?.length >
                  0
                ) {
                  notification.info({
                    message: t("info"),
                    description: t(
                      "Vous avez déjà commencé une demande de création d'un EPF. Merci de consulter votre brouillon."
                    ),
                    duration: 0,
                  });
                } else {
                  if (
                    JSON.parse(sessionStorage.getItem("-x-token-user"))
                      ?.Acces_Token
                  ) {
                    setIsLoading(true);
                    axiosInstance
                      .get(
                        `${
                          import.meta.env.VITE_APP_GEOPROD_API
                        }/admin-bpm/contrat/${
                          import.meta.env.VITE_ID_LIGNE_PRODUIT
                        }`,
                        {
                          headers: {
                            Idsession: JSON.parse(
                              sessionStorage.getItem("-x-token-user")
                            )?.Acces_Token,
                          },
                        }
                      )
                      .then((res) => {
                        setIsLoading(false);
                        setSurveyJson(res?.data?.contrat?.form);
                        sessionStorage.setItem("showingPreview", false);

                        navigate("/etablissements/save");
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        notification.error({
                          message: t("Erreur"),
                          description:
                            "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                          duration: 0,
                        });
                      });
                  } else {
                    notification.error({
                      message: t("Erreur"),
                      description: "Veuillez connecter s'il vous plait.",
                      duration: 0,
                    });
                  }
                }
              }}
            >
              <PlusOutlined />
              {t("Créer un nouvel EPF")}
            </Button>
          </Space>
        </Row>
      )}

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={
            <div>
              <div>{t("Liste de mes établissements")}</div>

              {data?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                >
                  <InfoCircleOutlined
                    style={{ color: "#1890ff", marginRight: "8px" }}
                  />
                  <span>
                    {t(
                      "Vous pouvez consulter les détails de la demande, télécharger les documents associés, et visualiser les commentaires en utilisant la liste des actions disponible devant chaque demande."
                    )}
                  </span>
                </div>
              )}
            </div>
          }
        >
          <Table
            locale={{ emptyText: t("Aucune donnée") }}
            loading={loading}
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Etablissements;
