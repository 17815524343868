/* eslint-disable react/prop-types */
import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../utils/axios";
import axios from "axios";

const AnnexeLoaderContext = createContext();

export const AnnexeLoaderProvider = ({ children }) => {
  const [isLoadingAnnexe, setIsLoading] = useState(false);
  const [surveyJson, setSurveyJson] = useState(null);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("-x-token-user"))?.Acces_Token) {
      setIsLoading(true);
      axios
        .get(
          `${import.meta.env.VITE_APP_GEOPROD_API}/admin-bpm/contrat/${
            import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
          }`,
          {
            headers: {
              Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                ?.Acces_Token,
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          setSurveyJson(res?.data?.contrat?.form);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <AnnexeLoaderContext.Provider
      value={{ isLoadingAnnexe, setIsLoading, surveyJson, setSurveyJson }}
    >
      {children}
    </AnnexeLoaderContext.Provider>
  );
};

// Custom hook to use loader context
export const useAnnexeLoader = () => useContext(AnnexeLoaderContext);
