import { Row, Card, Table, Tooltip, Tag, notification, Space } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Etablissements/Etablissements.module.css"; // Assurez-vous d'ajuster le chemin du fichier CSS
import { getListFormateursByUser } from "../api/geoprod";

const Formateurs = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getListFormateursByUser();
        setData(data?.data?.data);
        setLoading(false);
      } catch (error) {
        notification.error({
          message: t("Erreur"),
          description:
            error?.response?.data?.message ||
            "Une erreur s'est produite lors de la récupération des données",
          duration: 0,
        });
      }
    }

    fetchData();
  }, []);

  const columns = [
    {
      title: t("ID"),
      key: "ID",
      render: (_, record) => record[0]["md5(id)"] || "non renseigné"
    },
    {
      title: t("Nom"),
      key: "Nom",
      render: (_, record) => record[0]?.nom_ar || "non renseigné",
    },
    {
      title: t("Prénom"),
      key: "Prénom",
      render: (_, record) => record[0]?.prenom_ar || "non renseigné",
    },
    {
      title: t("Genre"),
      key: "Genre",
      render: (_, record) => record[0]?.civilite || "non renseigné",
    },
    {
      title: t("Adresse e-mail"),
      key: "Email",
      render: (_, record) => record[0]?.mail || "non renseigné",
    },
    {
      title: t("Numéro de téléphone"),
      key: "Téléphone",
      render: (_, record) => record[0]?.mobile || "non renseigné",
    },
    {
      title: t("Dénomination EPF"),
      key: "denomination",
      render: (_, record) =>
        record[0].denomination || "non renseigné",
    },
    {
      title: t("Personne à besoin spécifique"),
      key: "BesoinSpécifique",
      render: (_, record) => (record[0]?.special_needs_person ? "Oui" : "Non"),
    },
    {
      title: t("Situation"),
      key: "Situation",
      render: (_, record) => record[0]?.situation || "non renseigné",
    },
    {
      title: t("Statut"),
      key: "Statut",
      render: (_, record) => {
        let color = "";
        switch (record[0]?.status) {
          case 1:
            color = "green";
            break;
          case 0:
            color = "red";
            break;
          case "Blacklisté":
            color = "volcano";
            break;
          default:
            color = "default";
        }
        return (
          <Tag color={color}>
            {record[0]?.status === 1 ? "Opérationnel" : "Non opérationnel"}
          </Tag>
        );
      },
    },
    // {
    //   title: t("Actions"),
    //   key: "Actions",
    //   render: (_, record) => (
    //     <Space>
    //       <Tooltip title={t("Voir")}>
    //         <EyeOutlined onClick={() => {}} />
    //       </Tooltip>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div>
      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste des formateurs")}
        >
          <Table
            locale={{ emptyText: t("Aucune donnée") }}
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
            loading={loading}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Formateurs;
