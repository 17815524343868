import { useContext, useEffect, useState } from "react";
import GlobalContext from "./GlobalContext";
import axiosInstance from "../utils/axios";
import { useSessionStorageState } from "ahooks";

export const fetchGovermentList = () => {
  const [gouvernorats, setGovernorats] = useSessionStorageState(
    "gouvernorats-list",
    { defaultValue: [] }
  );

  useEffect(() => {
    if (gouvernorats.length === 0) {
      axiosInstance
        .get(`${import.meta.env.VITE_APP_GEOPROD_API}/config/goverment/all`)
        .then((res) => {
          setGovernorats(res?.data?.data);
        });
    }
  }, []);

  return gouvernorats;
};

export const fetchDelegationList = async (id) => {
  const { data } = await axiosInstance.get(
    `${
      import.meta.env.VITE_APP_GEOPROD_API
    }/config/delegation/by_goverment/${id}`
  );

  if (data) {
    return data?.data.map((e) => {
      return {
        text: e?.name,
        value: e?.value,
        id: e?.id,
      };
    });
  }
};
export const fetchDelegationListByGoverment = async (name) => {
  const { data } = await axiosInstance.get(
    `${
      import.meta.env.VITE_APP_GEOPROD_API
    }/config/delegation/by_name_goverment/${name}`
  );

  if (data) {
    return data?.data.map((e) => {
      return {
        text: e.name,
        value: e.value,
        id: e?.id,
      };
    });
  }
};

export const fetchPostalCodeListByDelegation = async (name) => {
  const { data } = await axiosInstance.get(
    `${
      import.meta.env.VITE_APP_GEOPROD_API
    }/config/codes_postaux/by_name_delegation/${name}`
  );

  if (data) {
    const codes = data?.data?.map((e) => e?.code);
    const uniqueCodes = codes?.filter(
      (code, index) => codes?.indexOf(code) === index
    );
    return uniqueCodes?.map((code) => ({
      text: code,
      value: code,
    }));
  }
};

export const fetchNationlaityList = () => {
  const [nationalites, setNationalites] = useSessionStorageState(
    "nationalities-list",
    { defaultValue: [] }
  );

  useEffect(() => {
    if (nationalites.length === 0) {
      axiosInstance
        .get(`${import.meta.env.VITE_APP_GEOPROD_API}/config/nationality/all`)
        .then((res) => {
          setNationalites(res?.data?.data);
        });
    }
  }, []);

  return nationalites;
};

export const fetchAllDelegationList = () => {
  const [delegations, setDelegations] = useSessionStorageState(
    "delegations-list",
    { defaultValue: [] }
  );
  useEffect(() => {
    if (delegations.length === 0) {
      axiosInstance
        .get(`${import.meta.env.VITE_APP_GEOPROD_API}/config/delegation/all`)
        .then((res) => {
          setDelegations(res?.data?.data);
        });
    }
  }, []);

  return delegations;
};

export const fetchFormesJuridiquesList = () => {
  const [formes, setFormes] = useSessionStorageState("formes-list", {
    defaultValue: [],
  });

  useEffect(() => {
    if (formes.length === 0) {
      axiosInstance
        .get(
          `${import.meta.env.VITE_APP_GEOPROD_API}/config/forme_juridique/all`
        )
        .then((res) => {
          setFormes(res?.data?.data);
        });
    }
  }, []);

  return formes;
};

export const fetchSectorList = () => {
  const [secteurs, setSecteurs] = useSessionStorageState("secteurs-list", {
    defaultValue: [],
  });

  useEffect(() => {
    if (secteurs.length === 0) {
      axiosInstance
        .get(`${import.meta.env.VITE_APP_GEOPROD_API}/config/secteur/all`)
        .then((res) => {
          setSecteurs(res?.data?.data);
        });
    }
  }, []);

  return secteurs;
};

export const fetchSubSectorList = async (sector) => {
  const { data } = await axiosInstance.get(
    `${
      import.meta.env.VITE_APP_GEOPROD_API
    }/config/sous_secteur/by_secteur_id/${sector}`
  );
  if (data) {
    // Map the data and return the formatted result
    return data?.data.map((elem) => {
      return {
        text: elem.name, // Display name
        value: elem.value, // Option value
        id: elem.id, // Sector ID
      };
    });
  }
};
export const fetchSpetialityList = async (subsector) => {
  const { data } = await axiosInstance.get(
    `${
      import.meta.env.VITE_APP_GEOPROD_API
    }/config/specialite_diplome/by_sous_secteur_id/${subsector}`
  );
  if (data) {
    return data?.data.map((elem) => {
      return [
        {
          spetialite: elem.specialite,
          diplome: elem.diplome,
        },
      ];
    });
  }
};

export const getCommuneByCodeCommune = async (id) => {
  const { data } = await axiosInstance.get(
    `${import.meta.env.VITE_APP_GEOPROD_API}/config/code_commune/get/${id}`
  );
  if (data) {
    return data?.response.value;
  }
};

export const getVillesById = async (id) => {
  const { data } = await axiosInstance.get(
    `${
      import.meta.env.VITE_APP_GEOPROD_API
    }/config/delegation/by_id_ville/${id}`
  );
  if (data) {
    return data?.value;
  }
};

export const fetchCodePostalList = async (id) => {
  const { data } = await axiosInstance.get(
    `${
      import.meta.env.VITE_APP_GEOPROD_API
    }/config/code_postal/by_delegation/${id}`
  );

  if (data) {
    const codes = data?.data.map((e) => e.code);
    const uniqueCodes = codes.filter(
      (code, index) => codes.indexOf(code) === index
    );
    return uniqueCodes.map((code) => ({
      text: code,
      value: code,
    }));
    // return uniqueCodes.map((code) => {
    //   return {
    //     text: code,
    //     value: code,
    //   };
    // });
  }
};
