// src/axiosInstance.js
import axios from "axios";
import { Button, Modal } from "antd";
import ModalSessionExpired from "./ModalSessionExpired";

const {confirm} = Modal;

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      return showSessionExpiredModal();
    }
    return Promise.reject(error);
  }
);

function showSessionExpiredModal() {
  Modal.info({
    icon: null, 
    content: (
     <ModalSessionExpired />
    ),
    footer: null, 
    closable: false,
    width:500
  });
}

export default axiosInstance;
