import { Button, Form, Image, Input, Modal, notification, Radio } from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../../context/GlobalContext";
import { useLoader } from "../../../context/LoaderContext";
import classes from "./SelectionModal.module.css";
import axiosInstance from "../../../utils/axios";
import exempleMatriculeFiscal from "../../../assets/exemple-matricule-fiscal.png";
import { InfoCircleOutlined } from "@ant-design/icons";

const SelectionModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [demandeType, setDemandeType] = useState();
  const [view, setView] = useState("buttons");
  const {
    isModalVisible,
    setIsModalVisible,
    brouillonEpfPrinicipalOrExistant,
  } = props;
  const { setIsLoading, setSurveyJson, setSurveyData } = useLoader();
  const { t } = useTranslation();
  const {
    form,
    setPersonneMorale,
    setMatriculeFiscale,
    matriculeFiscale,
    numeroEnregistrement,
    setNumeroEnregistrement,
    setEmployeurByPatNumPatCL,
    setFicheEntrepriseDGI,
    setEtablissementSecondaireDGI,
    setListActiviteRNE,
    setEntrepriseRegistrationId,
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsModalVisible(false);
    setView("buttons");
    form.resetFields();
  };

  const handleButtonClick = (buttonType) => {
    if (buttonType === "createEpf") {
      if (
        brouillonEpfPrinicipalOrExistant?.brouillonExistant?.some(
          (elem) =>
            elem?.id_etat_dossier_md5 ===
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT
        )
      ) {
        notification.info({
          message: t("info"),
          description: t(
            "Vous avez déjà commencé une demande de création d'un EPF existant. Merci de consulter votre brouillon."
          ),
          duration: 0,
        });
      } else {
        setView("form");

        setDemandeType("createEpf");
      }
    } else if (buttonType === "changeInvestor") {
      if (
        brouillonEpfPrinicipalOrExistant?.brouillonExistant?.some(
          (elem) =>
            elem?.id_etat_dossier_md5 ===
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION
        )
      ) {
        notification.info({
          message: t("info"),
          description: t(
            "Vous avez déjà commencé une demande de modification de ce type. Merci de consulter votre brouillon."
          ),
          duration: 0,
        });
      } else {
        setView("form");

        setDemandeType("changeInvestor");
      }
      // navigate("/investisseur/changer");
    }
  };

  const getDataApis = async (t, matricule, etat_secondaire) => {
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/config/RNE_ExtraitRegistreEntrepriseParId?Identifiant_Unique=${matricule}`
      )
      .then((responseRneExtrait) => {
        if (responseRneExtrait?.data?.message?.code === "404") {
          notification.error({
            message: t("Erreur"),
            description: t("Saisie invalide, veuillez vérifier vos données."),
            duration: 0,
          });
          return false;
        } else {
          setEntrepriseRegistrationId(responseRneExtrait);

          axiosInstance
            .get(
              `${
                import.meta.env.VITE_APP_GEOPROD_API
              }/config/CNSS_EmployeurByPatNumPatCl?patNum=${matricule.slice(
                0,
                7
              )}&patCle=${matricule.slice(-1)}`
            )
            .then((responseEmp) => {
              setEmployeurByPatNumPatCL(responseEmp);

              if (etat_secondaire === "000") {
                axiosInstance
                  .get(
                    `${
                      import.meta.env.VITE_APP_GEOPROD_API
                    }/config/FicheEntreprise?typeid=${1}&ident=${matricule}`
                  )
                  .then((responseFicheDgi) => {
                    setFicheEntrepriseDGI(responseFicheDgi);

                    axiosInstance
                      .get(
                        `${
                          import.meta.env.VITE_APP_GEOPROD_API
                        }/config/RNE_Listeactivites?Identifiant_Unique=${matricule}`
                      )

                      .then((responseListAct) => {
                        setListActiviteRNE(responseListAct);
                        setIsLoading(false);

                        navigate("/etablissements/existante/save");
                        form.resetFields();
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        setIsModalVisible(false);
                        setListActiviteRNE({});
                        form.resetFields();
                        notification.error({
                          message: t("Erreur"),
                          description: error.response.data.message
                            ? error.response.data.message
                            : t("Erreur lors de la récupération des données."),
                          duration: 0,
                        });
                        throw error;
                      });
                  })
                  .catch((error) => {
                    setFicheEntrepriseDGI({});
                    form.resetFields();
                    setIsLoading(false);
                    setIsModalVisible(false);
                    console.log("error.message", error);
                    notification.error({
                      message: t("Erreur"),
                      description: error.response.data.message
                        ? error.response.data.message
                        : t("Erreur lors de la récupération des données."),
                      duration: 0,
                    });
                    // throw error;
                  });
              } else {
                axiosInstance
                  .get(
                    `${
                      import.meta.env.VITE_APP_GEOPROD_API
                    }/config/DGI_EtabSecondaires?matfis=${matricule}`
                  )
                  .then((responseEtabSecondaire) => {
                    setEtablissementSecondaireDGI(responseEtabSecondaire);
                    setIsLoading(false);
                    navigate("/etablissements/existante/save");
                    form.resetFields();
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setIsModalVisible(false);
                    form.resetFields();
                    setEtablissementSecondaireDGI({});
                    notification.error({
                      message: t("Erreur"),
                      description: t(
                        "Erreur lors de la récupération des données."
                      ),
                      duration: 0,
                    });
                    // throw error;
                  });
              }
            })
            .catch((error) => {
              setEmployeurByPatNumPatCL({});
              setIsLoading(false);
              setIsModalVisible(false);
              form.resetFields();
              notification.error({
                message: t("Erreur"),
                description: t("Erreur lors de la récupération des données."),
                duration: 0,
              });
              // throw error;
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        form.resetFields();
        setEntrepriseRegistrationId({});
      });
  };
  const searchClick = async () => {
    const matricule = sessionStorage
      ?.getItem("matriculeFiscale")
      ?.substring(0, 8);
    const etat_secondaire = sessionStorage
      ?.getItem("matriculeFiscale")
      ?.substring(8, 11);
    // if (etat_secondaire === "000") {
    await getDataApis(t, matricule, etat_secondaire);
    //   await getEmployeurByPatNumPatCL(t,matricule);
    //   await getFicheEntrepriseDGI(t,matricule);
    //   await getListActiviteRNE( t,matricule);
    // } else {
    //   await getEntrepriseRegistrationId( t,matricule);
    //   await getEmployeurByPatNumPatCL(t,matricule);
    //   await getEtablissementSecondaireDgi(t,matricule);
    // }
  };
  const handleOk = () => {
    form.validateFields().then(() => {
      const userData = JSON.parse(sessionStorage.getItem("-x-token-user"));
      const matriculeFiscale =
        form.getFieldValue("identifiantRNE") +
        form.getFieldValue("codeEtablissementSecondaire");
      setMatriculeFiscale(matriculeFiscale);
      sessionStorage.setItem("matriculeFiscale", matriculeFiscale);
      setPersonneMorale(form.getFieldValue("EPFType") === "personneMorale");
      sessionStorage.setItem(
        "personneMorale",
        form.getFieldValue("EPFType") === "personneMorale"
      );
      setNumeroEnregistrement(form.getFieldValue("numeroEnregistrement"));
      sessionStorage.setItem(
        "numeroEnregistrement",
        form?.getFieldValue("numeroEnregistrement")
      );
      if (JSON.parse(sessionStorage.getItem("-x-token-user"))?.Acces_Token) {
        setIsLoading(true);
        axiosInstance
          .get(
            `${import.meta.env.VITE_APP_GEOPROD_API}/admin-bpm/contrat/${
              demandeType === "changeInvestor"
                ? import.meta.env
                    .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
                : import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
            }`,
            {
              headers: {
                Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                  ?.Acces_Token,
              },
            }
          )
          .then((res) => {
            let surveuForm = res?.data?.contrat?.form;
            surveuForm.pages =
              demandeType === "changeInvestor"
                ? Array(
                    res?.data?.contrat?.form?.pages?.find((page) => {
                      return page?.name === "ChnangementInvestisseur";
                    })
                  )
                : res?.data?.contrat?.form.pages;

            // const temp = {
            //   ...res?.data?.contrat?.form,
            //   pages: surveuForm,
            //   showProgressBar: "none",
            // }
            setSurveyJson(surveuForm);
            if (demandeType === "changeInvestor") {
              axiosInstance
                .get(
                  `${
                    import.meta.env.VITE_APP_GEOPROD_API
                  }/search_by_registration_number?registration_number=${form.getFieldValue(
                    "numeroEnregistrement"
                  )}`
                )
                .then((res) => {
                  sessionStorage.setItem(
                    "idAffaireEpfExistant",
                    res?.data?.data?.id_affaire
                  );
                  if (res?.data?.existe === true) {
                    axiosInstance
                      .get(
                        `${
                          import.meta.env.VITE_APP_GEOPROD_API
                        }/config/proxy/registreEntrepriseParId/${matriculeFiscale.substring(
                          0,
                          8
                        )}`
                      )
                      .then(async (response) => {
                        const dirigeants = response.data.direction;
                        if (response?.data?.code === "404") {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Aucune entreprise n'a été trouvée avec ce matricule fiscal."
                            ),
                            duration: 0,
                          });
                        } else if (
                          // TODO: check if the user is authorized to create an EPF for this entreprise
                          dirigeants.find((dirigeant) => {
                            dirigeant?.cin === userData?.cin;
                          })
                        ) {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Vous n'êtes pas autorisé à créer un établissement pour cette entreprise."
                            ),
                            duration: 0,
                          });
                        } else {
                          // const data = await getSchema(
                          //   res?.data?.data?.id_affaire
                          // );
                          // if (data) {
                          // setSurveyData(data?.response?.data);
                          form.resetFields();
                          sessionStorage.setItem(
                            "selectedOption",
                            "avec_bo_validation"
                          );
                          setIsLoading(false);
                          sessionStorage.setItem("showingPreview", false);

                          navigate("/investisseur/changer", {
                            state: {
                              idAffaireChangerInv: res?.data?.data?.id_affaire,
                              selectedOption: "avec_bo_validation",
                            },
                          });
                          // }
                        }
                      });
                  } else {
                    setIsLoading(false);
                    notification.error({
                      message: t("Erreur"),
                      description: t(
                        "Aucune entreprise n'a été trouvée avec ce numéro enregistrement."
                      ),
                      duration: 0,
                    });
                  }
                })
                .catch(() => {
                  setIsLoading(false);
                });
            } else {
              axiosInstance
                .get(
                  `${
                    import.meta.env.VITE_APP_GEOPROD_API
                  }/search_documents?number=${form.getFieldValue(
                    "numeroEnregistrement"
                  )}`
                )
                .then((res) => {
                  if (!res?.data?.exists) {
                    setIsLoading(false);
                    notification?.error({
                      message: t("Erreur"),
                      description: t(
                        "Aucune entreprise n'a été trouvée avec ce numéro enregistrement."
                      ),
                      duration: 0,
                    });
                  } else {
                    axiosInstance
                      .get(
                        `${
                          import.meta.env.VITE_APP_GEOPROD_API
                        }/config/proxy/registreEntrepriseParId/${matriculeFiscale.substring(
                          0,
                          8
                        )}`
                      )
                      .then(async (response) => {
                        const dirigeants = response.data.direction;
                        if (response?.data?.code === "404") {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Aucune entreprise n'a été trouvée avec ce matricule fiscal."
                            ),
                            duration: 0,
                          });
                        } else if (
                          dirigeants.find((dirigeant) => {
                            dirigeant?.cin == userData?.cin;
                          })
                        ) {
                          setIsLoading(false);

                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Vous n'êtes pas autorisé à créer un établissement pour cette entreprise."
                            ),
                            duration: 0,
                          });
                        }
                        //  else if (
                        //   response?.data?.nomCommercial &&
                        //   form.getFieldValue("EPFType") === "personnePhysique"
                        // ) {
                        //   setIsLoading(false);

                        //   notification.error({
                        //     message: t("Erreur"),
                        //     description: t(
                        //       "vous ne disposez pas d’un nom commercial. Pour continuer l’inscription vous êtes invité à prendre attache au RNE pour avoir un nom commercial."
                        //     ),
                        //     duration: 0,
                        //   });
                        // }
                        else {
                          // setIsModalVisible(false);
                          // form.resetFields();
                          sessionStorage.setItem("showingPreview", false);
                          await searchClick();
                        }
                      })
                      .catch((error) => {
                        setIsModalVisible(false);
                        notification.error({
                          message: t("Erreur"),
                          description:
                            error?.response?.data?.message ||
                            "Une erreur s'est produite lors de la récupération des données.",
                          duration: 0,
                        });
                      });
                  }
                })
                .catch(() => {
                  setIsLoading(false);
                });
            }
          })
          .catch(() => {
            setIsLoading(false);
            notification.error({
              message: t("Erreur"),
              description:
                "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
              duration: 0,
            });
          });
      } else {
        setIsLoading(false);
        notification.error({
          message: t("Erreur"),
          description: "Veuillez connecter s'il vous plait.",
          duration: 0,
        });
      }
    });
  };

  return (
    <Modal
      title={
        <div style={{ marginBottom: "25px", textAlign: "center" }}>
          {view === "buttons" ? (
            t("Choisissez une option")
          ) : (
            <>
              {t(
                "Veuillez renseigner les informations concernant votre établissement de formation privée"
              )}
              <br />
              يرجى تعبئة معلومات مؤسسة التكوين المهني الخاصة
            </>
          )}
        </div>
      }
      open={isModalVisible}
      footer={false}
      onCancel={handleCancel}
      style={{ minWidth: "100vh" }}
      maskClosable={false}
    >
      {view === "buttons" && (
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#214082",
              borderColor: "#214082",
              width: "15rem",
            }}
            onClick={() => handleButtonClick("createEpf")}
          >
            {t("Ajouter un EPF existant")}
          </Button>
          <Button
            style={{
              backgroundColor: "#E2E9F7",
              color: "#214082",
              border: "#9BACCF 1px solid",
              width: "15rem",
            }}
            onClick={() => handleButtonClick("changeInvestor")}
          >
            {t("Changer l'investisseur d'un EPF")}
          </Button>
        </div>
      )}

      {view === "form" && (
        <>
          <p>
            <InfoCircleOutlined
              style={{
                color: "#1777FF",
              }}
            />{" "}
            {t(
              "Si vous ne savez pas où obtenir votre « Numéro d'établissement secondaire - رقم الفرع الثانوي », vous pouvez consulter votre carte d'identification fiscale"
            )}{" "}
            -{" "}
            <a onClick={() => setVisible(true)}>
              {t("Voici un exemple de carte d'identification fiscale")}
            </a>
            <Image
              width={200}
              style={{
                display: "none",
              }}
              src={exempleMatriculeFiscal}
              preview={{
                visible,
                scaleStep: 0.3,
                src: exempleMatriculeFiscal,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            />
          </p>
          <Form form={form} layout="vertical" style={{direction: "ltr"}}>
            {demandeType === "createEpf" && (
              <Form.Item
                name="EPFType"
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
                label={
                  <span className={classes.label}>
                    Type de l'établissement - الصبغة القانونية
                  </span>
                }
                required
              >
                <Radio.Group
                  onChange={(e) => setSelectedOption(e.target.value)}
                  value={selectedOption}
                >
                  <Radio
                    value="personnePhysique"
                    style={{ marginRight: "20px" }}
                  >
                    Personne physique - شخص طبيعي
                  </Radio>
                  <Radio value="personneMorale">
                    Personne morale - شخص معنوي
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              label={
                <span className={classes.label}>
                  Numéro d'enregistrement - رقم التسجيل
                </span>
              }
              name="numeroEnregistrement"
              rules={[
                { required: true, message: t("Veuillez remplir ce champ.") },
                {
                  pattern: /^\d{10}$/,
                  message: t("Le champ doit contenir 10 chiffres."),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div
              style={{
                color: "#17a2b8",
                fontSize: "15px",
                marginBottom: "14px",
              }}
            >
              {t(
                "Le numéro d'enregistremessnt doit être composé de 10 chiffres (ex : 1720254273)."
              )}
            </div>
            {/* <Form.Item
            label={
              <span className={classes.label}>{t("Matricule fiscal")}</span>
            }
            required
          > */}
            {/* <Row gutter={8}>
              <Col span={15}> */}
            <Form.Item
              label={
                <span className={classes.label}>
                  Matricule fiscal / Numéro IURNE - رقم المعرّف الجبائي
                </span>
              }
              name="identifiantRNE"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^\d{7}[A-Z]$/,
                  message: t(
                    "Le champ doit contenir 7 chiffres et une lettre à la fin."
                  ),
                },
              ]}
            >
              <Input maxLength={8} />
            </Form.Item>
            <div
              style={{
                color: "#17a2b8",
                fontSize: "15px",
                marginBottom: "14px",
              }}
            >
              {t(
                "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 0123456A)."
              )}
            </div>
            {/* </Col>
              <Col span={9}> */}
            <Form.Item
              label={
                <span className={classes.label}>
                  Numéro établissement secondaire - رقم الفرع الثانوي
                </span>
              }
              name="codeEtablissementSecondaire"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^\d{3}$/,
                  message: t("Le code doit contenir 3 chiffres."),
                },
              ]}
            >
              <Input maxLength={3} />
            </Form.Item>
            <div
              style={{
                color: "#17a2b8",
                fontSize: "15px",
                marginBottom: "14px",
              }}
            >
              {t(
                "Le numéro de l'établissement secondaire doit être composé de 3 chiffres (ex : 000)."
              )}
            </div>
            {/* </Col>
            </Row> */}
            {/* </Form.Item> */}
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={handleOk}
                type="primary"
                style={{
                  backgroundColor: "#214082",
                  borderColor: "#214082",
                  marginRight: "10px",
                  width: "7rem",
                }}
              >
                {t("Valider")}
              </Button>
              <Button
                style={{
                  backgroundColor: "#E2E9F7",
                  color: "#214082",
                  border: "#9BACCF 1px solid",
                  marginInline: "0.5rem",
                  width: "7rem",
                }}
                onClick={handleCancel}
              >
                {t("Annuler")}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default SelectionModal;
