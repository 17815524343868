import React from "react";
import { PaperClipOutlined } from "@ant-design/icons";
import { Button, Divider, Empty, Modal, Tag } from "antd";
import { useTranslation } from "react-i18next";

const DownloadDocsModal = ({ open, setOpen, docs }) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      title={t("Télécharger les documents ")}
      open={open}
      onCancel={handleClose}
      footer={() => (
        <Button onClick={handleClose} type="primary">
          {t("Fermer")}
        </Button>
      )}
    >
      <p>{t("Cliquez sur le nom du document pour le télécharger")}</p>
      {docs?.map((doc) => (
        <div key={doc.md5_id}>
          <Divider orientation="left">
            <span style={{ fontSize: 14 }}>{t(doc?.name)}</span>
          </Divider>
          {doc?.files?.length === 0 && (
            <Empty description={t("Aucun document n'est disponible.")} />
          )}
          {doc?.files?.map((file) => (
            <a
              style={{ cursor: "pointer" }}
              key={file.md5_id}
              href={file.url_file || file?.url}
              download={file.name}
            >
              <Tag color="geekblue">
                <PaperClipOutlined /> {file.name}
              </Tag>
            </a>
          ))}
        </div>
      ))}
    </Modal>
  );
};

export default DownloadDocsModal;
