import { Form } from "antd";
import GlobalContext from "./GlobalContext";
import { useEffect, useMemo, useState } from "react";
import i18n from "../i18n";
import { Model } from "survey-core";
import {
  fetchAllDelegationList,
  fetchFormesJuridiquesList,
  fetchGovermentList,
  fetchNationlaityList,
  fetchSectorList,
} from "./FetchLists";
import { useSessionStorageState } from "ahooks";
const AppContext = (props) => {
  const [globalData, setGlobalData] = useState(
    sessionStorage.getItem("globalData")
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : {}
  );
  const [isRtl, setIsRtl] = useState(localStorage.getItem("language") === "ar");
  const [form] = Form.useForm();
  const [porteDoc, setPorteDocs] = useState([]);
  const [pageN, setPageN] = useState();
  const [brouillon, setBrouillon] = useState([]);
  const [personneMorale, setPersonneMorale] = useState(
    sessionStorage.getItem("personneMorale") === "true" ? true : false
  );
  const [employeurByPatNumPatCL, setEmployeurByPatNumPatCL] = useSessionStorageState(
    "employeurByPatNumPatCLData",
    { defaultValue: [] }
  );
  const [ficheEntrepriseDGI, setFicheEntrepriseDGI] = useSessionStorageState(
    "ficheEntrepriseDGIData",
    { defaultValue: [] }
  );
  const [etablissementSecondaireDGI, setEtablissementSecondaireDGI] = useSessionStorageState(
    "etablissementSecondaireDGIData",
    { defaultValue: [] }
  );
  const [listActiviteRNE, setListActiviteRNE] = useSessionStorageState(
    "listActiviteRNEData",
    { defaultValue: [] }
  );
  const [entrepriseRegistrationId, setEntrepriseRegistrationId] = useSessionStorageState(
    "entrepriseRegistrationIdData",
    { defaultValue: [] }
  );

  const [matriculeFiscale, setMatriculeFiscale] = useState(sessionStorage?.getItem("matriculeFiscale"));
  const [numeroEnregistrement, setNumeroEnregistrement] = useState(sessionStorage?.getItem("numeroEnregistrement") ? sessionStorage?.getItem("numeroEnregistrement") : "");
  const gouvernorats = {
    "تونس - Tunis": {
      delegations: [
        " قرطاج - Carthage",
        "المدينة - La Medina",
        "باب البحر - Bab El Bhar",
        "باب سويقة - Bab Souika",
        "العمران - El Omrane",
        "العمران الأعلى - El Omrane Supérieur",
        "التحرير - Ettahrir",
        "المنزه - El Menzah",
        "حي الخضراء - Cité El Khadhra",
        "باردو - Le Bardo",
        "السيجومي - Sijoumi",
        "الزهور - Ezzouhour",
        "الحرائرية - El Hrairia",
        "سيدي حسين - Sidi Hassine",
        "الوردية - El Ouardia",
        "الكبارية - El Kabaria",
        "سيدي البشير - Sidi El Béchir",
        "جبل الجلود - Djebel Djelloud",
        "حلق الوادي - La Goulette",
        "الكرم - Le Kram",
        "المرسى - La Marsa",
      ],
    },
    "أريانة - Ariana": {
      delegations: [
        " أريانة المدينة - L'Ariana Ville",
        "سكرة - Soukra",
        "رواد - Raoued",
        "قلعة الأندلس - Kalaât El Andalous",
        "سيدي ثابت - Sidi Thabet",
        "حي التضامن - Cité Ettadhamen",
        "المنيهلة - El Mnihla",
      ],
    },
    "باجة - Bèja": {
      delegations: [
        " باجة الشمالية - Béja Nord",
        "باجة الجنوبية - Béja Sud",
        "عمدون - Amdoun",
        "نفزة - Nefza",
        "تبرسق - Teboursouk",
        "تيبار - Tibar",
        "تستور - Testour",
        "قبلاط - Goubellat",
        "مجاز الباب - Medjez El Bab",
      ],
    },

    "بن عروس - Ben Arous": {
      delegations: [
        "بن عروس - Ben Arous",
        "المدينة الجديدة - La Nouvelle Medina",
        "المروج - El Mourouj",
        "حمام الأنف - Hammam Lif",
        "حمام الشط - Hammam Chôtt",
        "بومهل البساتين - Bou Mhel El Bassatine",
        "الزهراء - Ezzahra",
        "رادس - Radès",
        "مقرين - Megrine",
        "المحمدية - Mohamedia",
        "فوشانة - Fouchana",
        "مرناق - Mornag",
      ],
    },
    "بنزرت - Bizerte": {
      delegations: [
        " بنزرت الشمالية - Bizerte Nord",
        "جرزونة - Zarzouna",
        "بنزرت الجنوبية - Bizerte Sud",
        "سجنان - Sedjnane",
        "جومين - Djoumine",
        "ماطر - Mateur",
        "غزالة - Ghezala",
        "منزل بورقيبة - Menzel Bourguiba",
        "تينجة - Tinja",
        "أوتيك - Utique",
        "غار الملح - Ghar El Meleh",
        "منزل جميل - Menzel Djemil",
        "العالية - El Alia",
        "رأس الجبل - Ras Djebel",
      ],
    },

    "قابس - Gabès": {
      delegations: [
        " قابس المدينة - Gabes Medina",
        "قابس الغربية - Gabes Ouest",
        "قابس الجنوبية - Gabes Sud",
        "غنوش - Ghanouch",
        "المطوية - El Metouia",
        "منزل الحبيب - Menzel El Habib",
        "الحامة - El Hamma",
        "مطماطة - Matmata",
        "مطماطة الجديدة - Nouvelle Matmata",
        "مارث - Mareth",
      ],
    },

    "جندوبة - Jendouba": {
      delegations: [
        " جندوبة - Jendouba",
        "جندوبة الشمالية - Jendouba Nord",
        "بوسالم - Bou Salem",
        "طبرقة - Tabarka",
        "عين دراهم - Ain Draham",
        "فرنانة - Fernana",
        "غارالدماء - Ghardimaou",
        "وادي مليز - Oued Meliz",
        "بلطة بوعوان - Balta - Bou Aouane",
      ],
    },
    "القيروان - Kairouan": {
      delegations: [
        " القيروان الشمالية - Kairouan Nord",
        "القيروان الجنوبية - Kairouan Sud",
        "الشبيكة - Echebika",
        "السبيخة - Sbikha",
        "الوسلاتية - EL Ouslatia",
        "حفوز - Haffouz",
        "العلاء - El Alâa",
        "حاجب العيون - Hajeb el Ayoun",
        "نصر الله - Nasrallah",
        "الشراردة - Echrarda",
        "بوحجلة - Bouhajla",
      ],
    },
    "القصرين - Kasserine": {
      delegations: [
        " القصرين الشمالية - Kasserine Nord",
        "القصرين الجنوبية - Kasserine Sud",
        "الزهور - Ezzouhour",
        "حاسي الفريد - Hassi Ferid",
        "سبيطلة - Sbeitla",
        "سبيبة - Sbiba",
        "جدليان - Djedeliane",
        "العيون - El Ayoun",
        "تالة - Thala",
        "حيدرة - Hidra",
        "فوسانة - Foussana",
        "فريانة - Feriana",
        "ماجل بلعباس - Majel Bel Abbès",
      ],
    },
    "قبلي - Kébili": {
      delegations: [
        " دوز الجنوبية - Kebili Sud",
        "دوز الشمالية - Kebeli Nord",
        "فوار - Souk El Ahed",
        "دوز الشمالية - Douz Nord",
        "دوز الجنوبية - Douz Sud",
        "سوق الاحد - Faouar",
      ],
    },

    "المهدية - Mahdia": {
      delegations: [
        " المهدية - Mahdia",
        "بومرداس - Bou Merdès",
        "أولاد شامخ - Ouled Chamekh",
        "شربان - Chorbane",
        "هبيرة - Hebira",
        "السواسي - Essouassi",
        "الجم - El Djem",
        "الشابة - Chebba",
        "ملولش - Melloulech",
        "سيدي علوان - Sidi Alouane",
        "قصورالساف - Ksour Essef",
      ],
    },

    "منوبة - Mannouba": {
      delegations: [
        " منوبة - Mannouba",
        "دوار هيشر - Douar Hicher",
        "وادي الليل - Oued Ellil",
        "المرناقية - Mornaguia",
        "برج العامري - Borj Amri",
        "الجديدة - Djedeida",
        "طبربة - Tebourba",
        "البطان - El Battane",
      ],
    },
    "نابل - Nabeul": {
      delegations: [
        " نابل - Nabeul",
        "دار شعبان - Dar Chaabane",
        "بني خيار - Beni khiar",
        "قربة - Korba",
        "منزل تميم - Menzel Temime",
        "الميدة - El Mida",
        "قليبية - Kelibia",
        "حمام الأغزاز - Hammam El Guezaz",
        "الهوارية - El Haouaria",
        "تاكلسة - Takelsa",
        "سليمان - Soliman",
        "منزل بوزلفة - Menzel Bouzelfa",
        "بني خلاد - Beni Khalled",
        "قرمبالية - Grombalia",
        "بوعرقوب - Bou Argoub",
        "الحمامات - Hammamet",
      ],
    },
    "زغوان - Zaghouan": {
      delegations: [
        " زغوان - Zaghouan",
        "الزريبة - Ez-Zeriba",
        "بئر مشارقة - Bir Mchergua",
        "الفحص - El Fahs",
        "الناظور - En-Nadhour",
        "صواف - Saouaf",
      ],
    },
    "الكاف - El Kef": {
      delegations: [
        " الكاف الغربية - Kef Ouest",
        "الكاف الشرقية - Kef Est",
        "نبر - Nebeur",
        "ساقية سيدي يوسف - Sakiet Sidi Youssef",
        "تاجروين - Tajerouine",
        "قلعة سنان - Kalâat Snan",
        "القلعة الخصباء - Kalâat Khasbah",
        "الجريصة - Djerissa",
        "القصور - El Ksour",
        "الدهماني - Dahmani",
        "السرس - Es-Sers",
      ],
    },
    "سليانة - Siliana": {
      delegations: [
        " سليانة الشمالية - Siliana Nord",
        "سليانة الجنوبية - Siliana Sud",
        "بوعرادة - Bou Arada",
        "ڨعفور - Gaâfour",
        "الكريب - El Krib",
        "بورويس - Bourouis",
        "مكثر - Makthar",
        "الروحية - Er-Rouhia",
        "كسرى - Kesra",
        "برقو - Bargou",
        "العروسة - El Aroussa",
      ],
    },
    "سوسة - Sousse": {
      delegations: [
        " سوسة المدينة - Sousse Medina",
        "سوسة الرياض - Sousse Riadh",
        "سوسة جوهرة - Sousse Jawhara",
        "سوسة سيدي عبد الحميد - Sousse Sidi Abdelhamid",
        "حمام سوسة - Hammam Sousse",
        "أكودة - Akouda",
        "القلعة الكبرى - Kalaâ Kebira",
        "سيدي بوعلي - Sidi Bou Ali",
        "هرقلة - Hergla",
        "النفيضة - Enfidha",
        "بوفيشة - Bouficha",
        "كندار - Kondar",
        "سيدي الهاني - Sidi El Héni",
        "مساكن - M’saken",
        "القلعة الصغرى - Kalaâ Seghira",
        "الزاوية - القصيبة - الثريات - Zaouia - Ksiba - Thrayet",
      ],
    },
    "المنستير - Monastir": {
      delegations: [
        " المنستير - Monastir",
        "الوردانين - Ouerdanine",
        "الساحلين - Sahline",
        "زرمدين - Zermadine",
        "بني حسان - Beni Hassen",
        "جمال - Jammel",
        "بنبلة - Bembla",
        "المكنين - Moknine",
        "البقالطة - Bekalta",
        "طبلبة - Teboulba",
        "قصر هلال - Ksar Helal",
        "قصيبة المديوني - Ksibet El Mediouni",
        "صيادة - لمطة - بوحجر - Sayada-Lamta Bou-Hjar",
      ],
    },
    "صفاقس - Sfax": {
      delegations: [
        " صفاقس المدينة - Sfax Ville",
        "صفاقس الغربية - Sfax Ouest",
        "ساقية الزيت - Sakiet Ezzit",
        "ساقية الدائر - Sakiet Eddaïer",
        "صفاقس الجنوبية - Sfax Sud",
        "طينة - Tina",
        "عقارب - Agareb",
        "جبنيانة - Djebeniana",
        "العامرة - El Amra",
        "الحنشة - El Hencha",
        "منزل شاكر - Menzel Chaker",
        "الغريبة - Ghraiba",
        "بئر علي بن خليفة - Bir ali Ben Kelifa",
        "الصخيرة - Skhira",
        "المحرس - Mahres",
        "قرقنة - Kerkenah",
      ],
    },
    "سيدى بوزيد - Sidi Bouzid": {
      delegations: [
        " سيدي بوزيد الغربية - Sidi Bouzid Ouest",
        "سيدي بوزيد الشرقية - Sidi Bouzid Est",
        "جلمة - Jilma",
        "سبالة أولاد عسكر - Cebalet Ouled Asker",
        "بئر الحفي - Bir El Hafey",
        "سيدي علي بن عون - Sidi Ali Ben Aoûn",
        "منزل بوزيان - Menzel Bouzaïenne",
        "المكناسي - Meknassy",
        "سوق الجديد - Souk Jedid",
        "المزونة - Mezzouna",
        "الرقاب - Regueb",
        "أولاد حفوز - Ouled Haffouz",
      ],
    },
    "مدنين - Medenine": {
      delegations: [
        " مدنين الشمالية - Medenine Nord",
        "مدنين الجنوبية - Medenine Sud",
        "بني خداش - Beni Khedech",
        "بنقردان - Ben Guerdane",
        "جرجيس - Zarzis",
        "جربة حومة السوق - Djerba Houmet Souk",
        "جربة ميدون - Djerba Midoun",
        "جربة أجيم - Djerba Ajim",
        "سيدي مخلوف - Sidi Makhloulf",
      ],
    },
    "تطاوين - Tataouine": {
      delegations: [
        " تطاوين الشمالية - Tataouine Nord",
        "تطاوين الجنوبية - Tataouine Sud",
        "الصمار - Smâr",
        "بئر الأحمر - Bir Lahmar",
        "غمراسن - Ghomrassen",
        "ذهيبة - Dhehiba",
        "رمادة - Remada",
      ],
    },
    "قفصة - Gafsa": {
      delegations: [
        " قفصة الشمالية - Gafsa Nord",
        "سيدي عيش - Sidi Aïch",
        "القصر - El Ksar",
        "قفصة الجنوبية - Gafsa Sud",
        "ام العرائس - Oum El Araies",
        "الرديف - Redeyef",
        "المتلوي - Metlaoui",
        "المضيلة - Mdhila",
        "القطار - EL Guetar",
        "بلخير - Belkhir",
        "السند - Sned",
      ],
    },

    "توزر - Tozeur": {
      delegations: [
        " توزر - Tozeur",
        "دقاش - Degach",
        "تمغزة - Tameghza",
        "نفطة - Nefta",
        "حزوة - Hazoua",
      ],
    },
  };
  const gouvernoratList = fetchGovermentList().map((res) => {
    return {
      text: res.name,
      value: res.value,
      id: res.id,
    };
  });

  const listAllDelegations = fetchAllDelegationList().map((res) => {
    return {
      text: res.name,
      value: res.value,
      id: res.id,
    };
  });

  const nationalityList = fetchNationlaityList().map((res) => {
    return {
      text: res.name,
      value: res.value,
    };
  });

  const FormesJuridiquesList = fetchFormesJuridiquesList().map((res) => {
    return {
      text: res.name,
      value: res.value,
    };
  });

  const secteurData = fetchSectorList().map((res) => {
    return {
      text: res.name,
      value: res.value,
      id: res.id,
    };
  });

  const nationalityOptions = nationalityList?.map((item) => ({
    label: item.text,
    value: item.value,
  }));

  const gouvernoratOptions = gouvernoratList?.map((item) => ({
    label: item.text,
    value: item.value,
    id: item.id,
  }));

  // const surveyJson = {
  //   logoPosition: "right",
  //   pages: [
  //     {
  //       name: "page1",
  //       title: "Données de l'investisseur - معطيات المستثمر ",

  //       elements: [
  //         {
  //           type: "panel",
  //           name: "InvestisseurData",
  //           elements: [
  //             {
  //               type: "text",
  //               name: "investor_last_name_ar",
  //               title:
  //                 "Nom de l'investisseur (En arabe) - لقب المسثمر (بالعربية)",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },
  //             {
  //               type: "text",
  //               name: "investor_first_name_ar",
  //               startWithNewLine: false,
  //               title:
  //                 "Prénom de l'investisseur (En arabe) - إسم المسثمر (بالعربية)",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },
  //             {
  //               type: "text",
  //               name: "investor_last_name_fr",
  //               title:
  //                 "Nom de l'investisseur (Français) - لقب المسثمر (بالفرنسية)",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },
  //             {
  //               type: "text",
  //               name: "investor_first_name_fr",
  //               startWithNewLine: false,
  //               title:
  //                 "Prénom de l'investisseur (En français) - إسم المسثمر (بالفرنسية)",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },
  //             {
  //               type: "text",
  //               name: "investor_email",
  //               title: "Adresse e-mail - البريد الإلكتروني",

  //               hideNumber: true,
  //               enableIf: "{investor_email} empty",
  //               startWithNewLine: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               inputType: "email",
  //             },
  //             {
  //               name: "investor_birth_date",
  //               type: "text",
  //               inputType: "date",

  //               startWithNewLine: false,
  //               title: "Date de naissance - تاريخ الولادة",
  //               dateFormat: "dd/mm/yy",
  //               hideNumber: true,
  //               readOnly: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //               placeholder: {
  //                 default: "DD/MM/YYYY",
  //                 ar: "اليوم / الشهر / السنة",
  //               },
  //             },

  //             {
  //               type: "dropdown",
  //               name: "investor_civility",
  //               title: "Genre - الجنس",

  //               hideNumber: true,
  //               enableIf: "{investor_civility} empty",
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               choices: [
  //                 {
  //                   value: "ذكر",
  //                   text: "Masculin - ذكر",
  //                 },
  //                 {
  //                   value: "أنثى",
  //                   text: "Féminin - أنثى",
  //                 },
  //               ],
  //               placeholder: "",
  //               allowClear: false,
  //               searchEnabled: false,
  //               startWithNewLine: false,
  //             },
  //             {
  //               type: "dropdown",
  //               name: "investor_nationality",
  //               startWithNewLine: true,
  //               title: "Nationalité - الجنسية",
  //               readOnly: true,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               choices: nationalityList,
  //               placeholder: "",
  //               allowClear: false,
  //               searchEnabled: true,
  //             },
  //             {
  //               type: "text",
  //               name: "investor_cin",
  //               visibleIf: "{investor_nationality} = 'تونسية'",
  //               startWithNewLine: false,
  //               title: "Numéro CIN - رقم بطاقة التعريف الوطنية",

  //               hideNumber: true,
  //               enableIf: "{investor_cin} empty",
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               validators: [
  //                 {
  //                   type: "expression",
  //                 },
  //               ],
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "99999999",
  //               },
  //             },
  //             {
  //               type: "text",
  //               name: "investor_passport",
  //               visibleIf: "{investor_nationality} <> 'تونسية'",
  //               startWithNewLine: false,
  //               title: "Numéro du passeport - رقم جواز السفر",
  //               readOnly: true,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //             },

  //             {
  //               type: "text",
  //               inputType: "date",

  //               name: "investor_date_delivrance",
  //               visibleIf: "{investor_nationality} = 'تونسية'",
  //               title:
  //                 "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية ",
  //               defaultValueExpression:
  //                 "{establishment_director_data_date_delivrance}",
  //               readOnly: false,
  //               errorLocation: "bottom",
  //               hideNumber: true,
  //               startWithNewLine: false,
  //               isRequired: true,
  //               dateFormat: "dd/mm/yy",
  //               placeholder: {
  //                 default: "DD/MM/YYYY",
  //                 ar: "اليوم / الشهر / السنة",
  //               },
  //             },
  //             {
  //               type: "text",
  //               name: "investor_birth_place",
  //               title:
  //                 "Lieu de naissance (En arabe) - مكان الولادة (بالعربية) ",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },

  //             {
  //               type: "dropdown",
  //               name: "investor_eduction_level",
  //               startWithNewLine: false,
  //               title: "Niveau scolaire - المستوى الدراسي ",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               choices: [
  //                 {
  //                   value: "إبتدائي",
  //                   text: "Primaire - إبتدائي",
  //                 },
  //                 {
  //                   value: "ثانوي",
  //                   text: "Secondaire - ثانوي",
  //                 },
  //                 {
  //                   value: "جامعي",
  //                   text: "Universitaire - جامعي",
  //                 },
  //                 {
  //                   value: "تكوين مهني",
  //                   text: "Formation professionnelle - تكوين مهني",
  //                 },
  //               ],
  //               placeholder: "",
  //               allowClear: false,
  //               searchEnabled: false,
  //             },

  //             {
  //               type: "text",
  //               name: "investor_phone_number",
  //               startWithNewLine: false,
  //               title: "Numéro de téléphone - رقم الهاتف",
  //               readOnly: true,
  //               hideNumber: true,
  //               isRequired: true,
  //               inputType: "tel",
  //               errorLocation: "bottom",
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "99999999",
  //               },
  //             },

  //             {
  //               type: "text",
  //               name: "investor_residence_address",
  //               title:
  //                 "Adresse de résidence (En arabe) - عنوان الإقامة (بالعربية)",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },

  //             {
  //               type: "dropdown",
  //               name: "investor_residence_governorate",
  //               title: "Gouvernorat de résidence - ولاية الإقامة",
  //               choices: gouvernoratList,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               placeholder: "",
  //               startWithNewLine: false,
  //               allowClear: false,
  //             },
  //             {
  //               type: "dropdown",
  //               name: "investor_residence_delegation",
  //               startWithNewLine: false,
  //               title: "Délégation de résidence - معتمدية الإقامة",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               placeholder: "",
  //               allowClear: false,
  //             },
  //             {
  //               type: "text",
  //               name: "investor_residence_postal_code",
  //               title: "Code postal - الترقيم البريدي",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "9999",
  //               },
  //             },
  //             {
  //               type: "radiogroup",
  //               name: "investor_specific_needs",
  //               minWidth: "100px",
  //               startWithNewLine: false,
  //               title: "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",
  //               errorLocation: "bottom",
  //               hideNumber: true,
  //               isRequired: true,
  //               choices: [
  //                 {
  //                   value: "نعم",
  //                   text: "Oui - نعم",
  //                 },
  //                 {
  //                   value: "لا",
  //                   text: "Non - لا",
  //                 },
  //               ],
  //               colCount: 5,
  //             },

  //             {
  //               type: "file",
  //               name: "investor_copie_cin",
  //               visibleIf: "{investor_nationality} = 'تونسية'",
  //               maxWidth: "30%",
  //               startWithNewLine: true,
  //               title: "Copie de la CIN - نسخة من بطاقة التعريف الوطنية ",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               filePlaceholder:
  //                 "Téléchargez une copie de la carte d'identité nationale\nقم بتحميل نسخة من بطاقة التعريف الوطنية",
  //               storeDataAsText: false,
  //             },
  //             {
  //               type: "file",
  //               name: "investor_copie_passeport",
  //               visibleIf: "{investor_nationality} <> 'تونسية'",
  //               maxWidth: "30%",
  //               startWithNewLine: false,
  //               title: "Copie du passeport - نسخة من جواز السفر",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               filePlaceholder:
  //                 "Téléchargez une copie du passeport\nيرجى تحميل نسخة من جواز السفر",
  //               storeDataAsText: false,
  //             },
  //             {
  //               type: "file",
  //               name: "investor_copie_cv",
  //               maxWidth: "30%",
  //               startWithNewLine: false,
  //               title: "Copie du CV -  السيرة الذاتية للمستثمر",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               filePlaceholder:
  //                 "Téléchargez le CV de l'investisseur\nانقر لتحميل السيرة الذاتية للمستثمر",
  //               storeDataAsText: false,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page0",
  //       title: "Type de l'établissement - الصبغة القانونية",
  //       minWidth: "800px",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "TypeInvestisseur",
  //           elements: [

  //             {
  //               type: "radiogroup",
  //               name: "inverstor_type",
  //               title: "Type de l'établissement - الصبغة القانونية",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               renderAs: "div",
  //               choices: [
  //                 {
  //                   value: "شخص طبيعي",
  //                   text: "Personne physique - شخص طبيعي",
  //                 },
  //                 {
  //                   value: "شخص معنوي",
  //                   text: "Personne morale - شخص معنوي",
  //                 },
  //               ],
  //               choicesOrder: "asc",
  //               colCount: 4,
  //             },
  //             {
  //               type: "radiogroup",
  //               name: "investor_RNE",
  //               title:
  //                 "Quel est l'état actuel de votre entreprise ? - ما هو الوضع الحالي لمؤسستكم؟ ",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               choices: [
  //                 {
  //                   value:
  //                     "الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)",
  //                   text: "Réservation RNE uniquement (Entreprise en cours de création) - الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)",
  //                 },
  //                 {
  //                   value:
  //                     "رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)",
  //                   text: "Numéro de Patente disponible (Entreprise déjà créée) - رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)",
  //                 },
  //               ],
  //               colCount: 2,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "legal_entity_page",
  //       title:
  //         "Données relatives à l'établissement (Personne Morale) - بيانات متعلقة بالمؤسسة (شخص معنوي)",
  //       visibleIf: "{inverstor_type} = 'شخص معنوي'",
  //       elements: [
  //         {
  //           type: "panel",
  //           name: "panel5",
  //           elements: [
  //             {
  //               type: "text",
  //               name: "legal_entity_validate",
  //               visible: false,
  //               defaultValue:false,
  //             },
  //             {
  //               type: "text",
  //               name: "legal_entity_reservation_number_official",
  //               visibleIf:
  //                 "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
  //               title:
  //                 "Numéro de réservation de la dénomination officielle - رقم حجز التسمية الرسمية",
  //               inputType: "api",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //             },

  //             {
  //               type: "text",
  //               name: "legal_entity_tax_number",
  //               visibleIf:
  //                 "{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
  //               title:
  //                 "Numéro d’identifiant fiscal / RNE - الرقم المعرف الجبائي لدى السجل الوطني للمؤسسات",
  //               startWithNewLine: false,
  //               inputType: "api",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //             },
  //             {
  //               type: "html",
  //               name: "file_description",
  //               visibleIf:"{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
  //               html: `<div class='description'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z" fill="#2BC1A7"/><path d="M12 10.25C11.59 10.25 11.25 10.59 11.25 11V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V11C12.75 10.59 12.41 10.25 12 10.25ZM11.08 8.381C11.13 8.501 11.2 8.611 11.29 8.711C11.39 8.801 11.5 8.871 11.62 8.921C11.8635 9.02102 12.1365 9.02102 12.38 8.921C12.5 8.871 12.61 8.801 12.71 8.711C12.8 8.611 12.87 8.501 12.92 8.381C12.97 8.261 13 8.131 13 8.001C13 7.871 12.97 7.741 12.92 7.621C12.87 7.491 12.8 7.391 12.71 7.291C12.61 7.201 12.5 7.131 12.38 7.081C12.26 7.031 12.13 7.001 12 7.001C11.87 7.001 11.74 7.031 11.62 7.081C11.5 7.131 11.39 7.201 11.29 7.291C11.2 7.391 11.13 7.491 11.08 7.621C11.03 7.741 11 7.871 11 8.001C11 8.131 11.03 8.261 11.08 8.381Z" fill="#194FC1"/></svg>Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A).</div>`,
  //             },
  //             {
  //               type: "text",
  //               name: "legal_entity_reservation_number_commercial",

  //               startWithNewLine: true,
  //               title:
  //                 "Numéro de réservation de la dénomination commerciale - رقم حجز التسمية التجارية",

  //               hideNumber: true,
  //               errorLocation: "bottom",
  //               inputType: "api",
  //             },

  //             {
  //               type: "text",
  //               name: "legal_entity_official_name",
  //               startWithNewLine: true,
  //               title: "Dénomination officielle - التسمية الرسمية",

  //               hideNumber: true,
  //               readOnly: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //             },
  //             {
  //               type: "text",
  //               name: "legal_entity_commercial_name",

  //               startWithNewLine: false,
  //               title: "Dénomination commerciale - التسمية التجارية",

  //               hideNumber: true,

  //               readOnly: true,
  //               errorLocation: "bottom",
  //             },
  //             {
  //               type: "text",
  //               inputType: "date",
  //               name: "legal_entity_reservation_officielle_expiration_date",
  //               visibleIf:
  //                 "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
  //               title:
  //                 "Date d’expiration de la réservation de la dénomination officielle - تاريخ انتهاء صلاحية حجز التسمية الرسمية",

  //               hideNumber: true,
  //               readOnly: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //               dateFormat: "dd/mm/yy",
  //               placeholder: {
  //                 default: "DD/MM/YYYY",
  //                 ar: "اليوم / الشهر / السنة",
  //               },
  //             },
  //             {
  //               type: "text",
  //               inputType: "date",
  //               name: "legal_entity_reservation_commerciale_expiration_date",
  //               visibleIf:
  //                 "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
  //               title:
  //                 "Date d’expiration de la réservation de la dénomination commerciale - تاريخ انتهاء صلاحية حجز الاسم التجاري",

  //               hideNumber: true,

  //               readOnly: true,
  //               errorLocation: "bottom",

  //               dateFormat: "dd/mm/yy",
  //               placeholder: {
  //                 default: "DD/MM/YYYY",
  //                 ar: "اليوم / الشهر / السنة",
  //               },
  //             },
  //             {
  //               type: "dropdown",
  //               name: "legal_entity_legal_form",
  //               title: "Forme Juridique - الشكل القانوني",

  //               allowClear: false,
  //               hideNumber: true,
  //               errorLocation: "bottom",
  //               choices: FormesJuridiquesList,
  //             },
  //             {
  //               type: "text",
  //               name: "legal_entity_head_office_address",
  //               startWithNewLine: false,
  //               title:
  //                 "Adresse du siège social (En arabe) - عنوان المقر الاجتماعي (بالعربية)",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },
  //             {
  //               type: "text",
  //               name: "legal_entity_sector_activity",
  //               startWithNewLine: true,
  //               title: "Secteur d’activité principale - مجال النشاط الرئيسي",
  //               isRequired: true,

  //               hideNumber: true,
  //               errorLocation: "bottom",
  //             },
  //             {
  //               type: "text",
  //               name: "legal_entity_secondary_sector_activity",
  //               title: "Secteur d’activité secondaire - مجال النشاط الثانوي",
  //               startWithNewLine: false,
  //               hideNumber: true,
  //               errorLocation: "bottom",
  //             },
  //             {
  //               type: "text",
  //               name: "legal_entity_cnss_registration_number",
  //               visibleIf:
  //                 "{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
  //               startWithNewLine: false,
  //               title:
  //                 "Numéro de matricule CNSS - رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",

  //               hideNumber: true,
  //               errorLocation: "bottom",
  //             },
  //             {
  //               type: "dropdown",
  //               name: "legal_entity_governorate",
  //               title: "Gouvernorat - الولاية",
  //               choices: gouvernoratList,
  //               hideNumber: true,

  //               isRequired: true,
  //               errorLocation: "bottom",
  //               allowClear: false,
  //             },
  //             {
  //               type: "dropdown",
  //               name: "legal_entity_delegation",
  //               startWithNewLine: false,
  //               title: "Délégation - المعتمدية",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               placeholder: "",
  //               allowClear: false,
  //               searchEnabled: false,
  //             },
  //             {
  //               type: "text",
  //               name: "legal_entity_phone_number",
  //               maxWidth: "50%",
  //               startWithNewLine: false,
  //               title: "Numéro du téléphone - رقم الهاتف",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "99999999",
  //               },
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page2",
  //       title: "Données de l’établissement de formation - معطيات مؤسسة التكوين",

  //       elements: [
  //         {
  //           type: "panel",
  //           name: "panel1",
  //           elements: [
  //             {
  //               type: "text",
  //               name: "establishment_data_tax_number",
  //               visibleIf:
  //                 " {inverstor_type} <> 'شخص معنوي' and {investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
  //               title:
  //                 "Numéro d’identifiant fiscal / RNE - رقم المعرف الجبائي لدى السجل الوطني للمؤسسات",
  //               enableIf: "{inverstor_type} <> 'شخص معنوي'",
  //               hideNumber: true,
  //               defaultValueExpression: "{legal_entity_tax_number}",
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               inputType: "api",
  //             },
  //             {
  //               type: "html",
  //               name: "file_description",
  //               visibleIf:"{inverstor_type} <> 'شخص معنوي' and {investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
  //               html: `<div class='description'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z" fill="#2BC1A7"/><path d="M12 10.25C11.59 10.25 11.25 10.59 11.25 11V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V11C12.75 10.59 12.41 10.25 12 10.25ZM11.08 8.381C11.13 8.501 11.2 8.611 11.29 8.711C11.39 8.801 11.5 8.871 11.62 8.921C11.8635 9.02102 12.1365 9.02102 12.38 8.921C12.5 8.871 12.61 8.801 12.71 8.711C12.8 8.611 12.87 8.501 12.92 8.381C12.97 8.261 13 8.131 13 8.001C13 7.871 12.97 7.741 12.92 7.621C12.87 7.491 12.8 7.391 12.71 7.291C12.61 7.201 12.5 7.131 12.38 7.081C12.26 7.031 12.13 7.001 12 7.001C11.87 7.001 11.74 7.031 11.62 7.081C11.5 7.131 11.39 7.201 11.29 7.291C11.2 7.391 11.13 7.491 11.08 7.621C11.03 7.741 11 7.871 11 8.001C11 8.131 11.03 8.261 11.08 8.381Z" fill="#194FC1"/></svg>Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A).</div>`,
  //             },

  //             {
  //               type: "text",
  //               name: "estblishment_validate",
  //               visible: false,
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_data_reservation_number_commercial",
  //               visibleIf: "{inverstor_type} <> 'شخص معنوي'",
  //               enableIf: "{inverstor_type} <> 'شخص معنوي'",
  //               title:
  //                 "Numéro de réservation de la dénomination commerciale - رقم حجز التسمية التجارية",
  //               requiredIf: "{inverstor_type} <> 'شخص معنوي'",
  //               hideNumber: true,

  //               errorLocation: "bottom",
  //               startWithNewLine: false,
  //               inputType: "api",
  //             },

  //             {
  //               type: "text",
  //               name: "establishment_data_official_name",
  //               visibleIf: "{inverstor_type} = 'شخص معنوي'",
  //               title: "Dénomination officielle - التسمية الرسمية",
  //               defaultValueExpression: "{legal_entity_official_name}",

  //               hideNumber: true,
  //               isRequired: true,
  //               readOnly: true,
  //               startWithNewLine: true,
  //               errorLocation: "bottom",
  //             },

  //             {
  //               type: "text",
  //               name: "establishment_data_commercial_name",

  //               title: "Dénomination commerciale - التسمية التجارية",
  //               readOnly: true,
  //               hideNumber: true,
  //               defaultValueExpression: "{legal_entity_commercial_name}",
  //               requiredIf: "{inverstor_type} <> 'شخص معنوي'",
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               startWithNewLine: false,
  //             },

  //             {
  //               type: "text",
  //               inputType: "date",
  //               name: "establishment_data_reservation_commerciale_expiration_date",
  //               visibleIf: "{inverstor_type} <> 'شخص معنوي'",
  //               title:
  //                 "Date d’expiration de la réservation de la dénomination commerciale - تاريخ انتهاء صلاحية حجز الاسم التجاري",
  //               startWithNewLine: false,
  //               readOnly: true,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //               dateFormat: "dd/mm/yy",
  //               placeholder: {
  //                 default: "DD/MM/YYYY",
  //                 ar: "اليوم / الشهر / السنة",
  //               },
  //             },

  //             {
  //               type: "text",
  //               name: "establishment_data_phone_number_1",
  //               title: "Numéro de téléphone 1 -  رقم الهاتف 1 ",
  //               hideNumber: true,
  //               errorLocation: "bottom",
  //               isRequired: true,
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "99999999",
  //               },
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_data_phone_number_2",
  //               startWithNewLine: false,
  //               title: "Numéro de téléphone 2 - رقم الهاتف 2 ",
  //               errorLocation: "bottom",
  //               hideNumber: true,
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "99999999",
  //               },
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_data_fax_number",
  //               startWithNewLine: false,
  //               title: "Numéro de fax - رقم الفاكس",

  //               hideNumber: true,
  //               errorLocation: "bottom",
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "99999999",
  //               },
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_data_email",
  //               title: "Adresse e-mail - البريد الإلكتروني",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               inputType: "email",
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_data_address",
  //               startWithNewLine: false,
  //               title:
  //                 "Adresse de l’établissement (En arabe) - عنوان المؤسسة (بالعربية)",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },
  //             {
  //               type: "dropdown",
  //               name: "establishment_data_training_type",
  //               startWithNewLine: false,
  //               title: "Nature de formation - طبيعة التكوين",
  //               errorLocation: "bottom",
  //               hideNumber: true,
  //               isRequired: true,
  //               choices: [
  //                 {
  //                   value: "أساسي",
  //                   text: "Initiale - أساسي",
  //                 },
  //                 {
  //                   value: "مستمر",
  //                   text: "Continue - مستمر",
  //                 },
  //                 {
  //                   value: "أساسي ومستمر",
  //                   text: "Initiale et continue - أساسي ومستمر",
  //                 },
  //               ],
  //               placeholder: "",
  //               allowClear: false,
  //             },
  //             {
  //               type: "dropdown",
  //               name: "establishment_data_governorate",
  //               title: "Gouvernorat - الولاية",
  //               choices: gouvernoratList,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               placeholder: "",
  //               allowClear: false,
  //             },
  //             {
  //               type: "dropdown",
  //               name: "establishment_data_delegation",
  //               startWithNewLine: false,
  //               title: "Délégation - المعتمدية",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               placeholder: "",
  //               allowClear: false,
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_data_postal_code",
  //               startWithNewLine: false,
  //               title: "Code postal - الترقيم البريدي",

  //               hideNumber: true,
  //               isRequired: true,
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "9999",
  //               },
  //             },
  //             {
  //               type: "file",
  //               name: "establishment_data_logo",
  //               title: "Logo de l'établissement - شعار المؤسسة",

  //               hideNumber: true,
  //               errorLocation: "bottom",
  //               acceptedTypes: ".jpg,.jpeg,.png",
  //               filePlaceholder:
  //                 "Télécharger le logo de l'établissement\nتحميل شعار الشركة",
  //               storeDataAsText: false,
  //             },
  //             {
  //               type: "file",
  //               name: "establishment_data_pattente",
  //               visibleIf:
  //                 "{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
  //               startWithNewLine: false,
  //               title:
  //                 "Copie RNE - نسخة من المعرف الجبائي أو ما يعرف بالباتيندا",
  //               isRequired: true,
  //               hideNumber: true,
  //               errorLocation: "bottom",
  //               acceptedTypes: ".jpg,.jpeg,.png",
  //               filePlaceholder:
  //                 "Téléchargez une copie RNE\nحمل نسخة من المعرف الجبائي أو ما يعرف بالباتيندا",
  //               storeDataAsText: false,
  //             },
  //             {
  //               type: "file",
  //               name: "establishment_data_commercial_name_file",

  //               title:
  //                 "Décharge de la réservation de la dénomination commerciale - تحميل وثيقة حجز التسمية التجارية",

  //               hideNumber: true,
  //               startWithNewLine: false,
  //               requiredIf: "{inverstor_type} <>'شخص معنوي'",
  //               errorLocation: "bottom",
  //               acceptedTypes: ".jpg,.jpeg,.png",
  //               filePlaceholder:
  //                 "Télécharger la décharge de la réservation de la dénomination commerciale\nحمل وثيقة حجز التسمية التجارية ",
  //               storeDataAsText: false,
  //             },
  //             {
  //               type: "file",
  //               name: "establishment_data_rne",
  //               visibleIf:
  //                 "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)' and {inverstor_type} = 'شخص معنوي'",
  //               title:
  //                 "Décharge de la réservation de la dénomination officielle - تحميل وثيقة حجز التسمية الرسمية",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               acceptedTypes: ".jpg,.jpeg,.png",
  //               filePlaceholder:
  //                 "Télécharger la décharge de la réservation RNE\nحمل وثيقة حجز التسمية الرسمية لدى السجل الوطني للمؤسسات",
  //               storeDataAsText: false,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "Données de Directeur de l’Établissement",
  //       title: "Données de Directeur de l’Établissement - بيانات مدير المؤسسة",

  //       elements: [
  //         {
  //           type: "panel",
  //           name: "panel2",
  //           elements: [
  //             {
  //               type: "dropdown",
  //               name: "establishment_director_data_identity",
  //               title: "Identité - الهوية",
  //               allowClear: false,
  //               errorLocation: "bottom",
  //               hideNumber: true,
  //               isRequired: true,
  //               choices: [
  //                 {
  //                   value: "مدير غير المستثمر",
  //                   text: "Directeur Autre que l’Investisseur - مدير غير المستثمر",
  //                 },
  //                 {
  //                   value: "المستثمر نفسه",
  //                   text: "Investisseur lui-même - المستثمر نفسه",
  //                 },
  //               ],
  //             },
  //             {
  //               type: "dropdown",
  //               name: "establishment_director_data_nationality",
  //               startWithNewLine: false,
  //               title: "Nationalité - الجنسية",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               choices: nationalityList,
  //               placeholder: "",
  //               defaultValue: "تونسية",
  //               allowClear: false,
  //               searchEnabled: true,
  //             },

  //             {
  //               type: "text",
  //               name: "establishment_director_data_cin_number",
  //               visibleIf:
  //                 "{establishment_director_data_nationality} = 'تونسية'",
  //               startWithNewLine: true,
  //               title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "99999999",
  //               },
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_director_data_passeport",
  //               visibleIf:
  //                 "{establishment_director_data_nationality} <> 'تونسية'",
  //               startWithNewLine: false,
  //               title: "Numéro du passeport - رقم جواز السفر",

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //             },
  //             {
  //               type: "text",
  //               inputType: "date",
  //               name: "establishment_director_data_birth_date",
  //               startWithNewLine: false,
  //               title: "Date de naissance - تاريخ الولادة",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               readOnly: false,
  //               dateFormat: "dd/mm/yy",
  //               placeholder: {
  //                 default: "DD/MM/YYYY",
  //                 ar: "اليوم / الشهر / السنة",
  //               },
  //             },
  //             {
  //               type: "text",
  //               inputType: "Button",
  //               title: " ",
  //               hideNumber: true,
  //               name: "director_search",
  //               startWithNewLine: false,
  //               maxWidth: "10%",
  //               visibleIf:
  //                 "{establishment_director_data_identity} <> 'المستثمر نفسه' and  {establishment_director_data_nationality} = 'تونسية'",
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_director_data_last_name",
  //               startWithNewLine: true,
  //               title: "Nom (En arabe) - اللقب (بالعربية)",
  //               visible: false,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //               readOnly: true,

  //             },
  //             {
  //               type: "text",
  //               name: "establishment_director_data_first_name",
  //               visible: false,
  //               startWithNewLine: false,
  //               title: "Prénom (En arabe) - الإسم (بالعربية)",
  //               errorLocation: "bottom",
  //               hideNumber: true,
  //               isRequired: true,
  //               readOnly: true,

  //             },
  //             {
  //               type: "text",
  //               name: "establishment_director_data_last_name_fr",
  //               startWithNewLine: true,
  //               title: "Nom (En français) - اللقب (بالفرنسية)",
  //               hideNumber: true,
  //               isRequired: true,
  //               visible: false,
  //               errorLocation: "bottom",

  //             },
  //             {
  //               type: "text",
  //               name: "establishment_director_data_first_name_fr",
  //               startWithNewLine: false,
  //               title: "Prénom (En français) - الإسم (بالفرنسية)",
  //               errorLocation: "bottom",
  //               visible: false,
  //               hideNumber: true,
  //               isRequired: true,

  //             },

  //             {
  //               type: "dropdown",
  //               name: "establishment_director_data_genre",
  //               title: "Genre - الجنس",
  //               errorLocation: "bottom",
  //               hideNumber: true,
  //               isRequired: true,
  //               visible: false,
  //               enableIf: "{establishment_director_data_nationality} <>'تونسية'",
  //               choices: [
  //                 {
  //                   value: "ذكر",
  //                   text: "Masculin - ذكر",
  //                 },
  //                 {
  //                   value: "أنثى",
  //                   text: "Féminin - أنثى",
  //                 },
  //               ],
  //               allowClear: false,

  //             },

  //             {
  //               type: "text",
  //               name: "establishment_director_data_birth_place",
  //               title: "Lieu de naissance (En arabe) - مكان الولادة (بالعربية)",
  //               visible: false,
  //               hideNumber: true,
  //               isRequired: true,
  //               enableIf: "{establishment_director_data_nationality} <>'تونسية'",
  //               errorLocation: "bottom",
  //               startWithNewLine: false,

  //             },
  //             {
  //               type: "text",
  //               inputType: "date",
  //               name: "establishment_director_data_ministerial_auth_date",
  //               visible: false,

  //               startWithNewLine: false,
  //               title: "Date d’autorisation de ministre - تاريخ ترخيص الوزير",
  //               readOnly: false,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //               dateFormat: "dd/mm/yy",
  //               placeholder: {
  //                 default: "DD/MM/YYYY",
  //                 ar: "اليوم / الشهر / السنة",
  //               },
  //             },

  //             {
  //               type: "text",
  //               inputType: "date",
  //               name: "establishment_director_data_date_delivrance",

  //               visible: false,
  //               startWithNewLine: false,
  //               errorLocation: "bottom",
  //               title:
  //                 "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
  //               readOnly: false,
  //               hideNumber: true,
  //               isRequired: true,
  //               dateFormat: "dd/mm/yy",
  //               placeholder: {
  //                 default: "DD/MM/YYYY",
  //                 ar: "اليوم / الشهر / السنة",
  //               },
  //             },

  //             {
  //               type: "text",
  //               name: "establishment_director_data_address",
  //               title: "Adresse (En arabe) - العنوان (بالعربية)",
  //               visible: false,

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",

  //             },
  //             {
  //               type: "dropdown",
  //               name: "establishment_director_data_governorate",
  //               startWithNewLine: false,
  //               title: "Gouvernorat - الولاية",
  //               visible: false,
  //               choices: gouvernoratList,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               placeholder: "",
  //               allowClear: false,
  //             },
  //             {
  //               type: "dropdown",
  //               name: "establishment_director_data_delegation",
  //               startWithNewLine: false,
  //               title: "Délégation - المعتمدية",
  //               visible: false,

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               placeholder: "",
  //               allowClear: false,
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_director_data_email",
  //               title: "Adresse e-mail - البريد الإلكتروني",
  //               visible: false,

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               inputType: "email",
  //             },
  //             {
  //               type: "text",
  //               name: "establishment_director_data_phone_number",
  //               maxWidth: "50%",
  //               startWithNewLine: false,
  //               title: "Numéro de téléphone - رقم الهاتف",
  //               visible: false,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               maskType: "pattern",
  //               maskSettings: {
  //                 pattern: "99999999",
  //               },
  //             },

  //             {
  //               type: "dropdown",
  //               name: "establishment_director_data_education_level",
  //               title: "Niveau scolaire - المستوى الدراسي",
  //               visible: false,
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               choices: [
  //                 {
  //                   value: "إبتدائي",
  //                   text: "Primaire - إبتدائي",
  //                 },
  //                 {
  //                   value: "ثانوي",
  //                   text: "Secondaire - ثانوي",
  //                 },
  //                 {
  //                   value: "جامعي",
  //                   text: "Universitaire - جامعي",
  //                 },
  //                 {
  //                   value: "تكوين مهني",
  //                   text: "Formation professionnelle - تكوين مهني",
  //                 },
  //               ],
  //               placeholder: "",
  //               allowClear: false,
  //               searchEnabled: false,
  //               startWithNewLine: false,
  //             },

  //             {
  //               type: "text",
  //               name: "establishment_director_data_experience_years",
  //               startWithNewLine: true,
  //               visible: false,
  //               title:
  //                 "Nombre d'années d'expériences professionnelles -  عدد سنوات الخبرة المهنية",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               inputType: "number",
  //               min: 0,
  //             },
  //             {
  //               type: "radiogroup",
  //               name: "establishment_director_data_specific_needs",
  //               startWithNewLine: false,
  //               title: "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",
  //               visible: false,

  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               choices: [
  //                 {
  //                   value: "نعم",
  //                   text: "Oui - نعم",
  //                 },
  //                 {
  //                   value: "لا",
  //                   text: "Non - لا",
  //                 },
  //               ],
  //               colCount: 4,
  //             },
  //             {
  //               type: "file",
  //               name: "establishment_director_data_director_folder",
  //               visible: false,
  //               title:
  //                 "Dossier du directeur (CV et autres) - ملف المدير (السيرة الذاتية وغيرها)",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               allowMultiple: true,
  //               fileOrPhotoPlaceholder:
  //                 "Cliquez ou glissez pour télécharger le CV et le dossier du directeur\nانقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
  //               photoPlaceholder: "",
  //               filePlaceholder:
  //                 "Cliquez ou glissez pour télécharger le CV et le dossier du directeur\nانقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
  //               storeDataAsText: false,
  //             },
  //             {
  //               type: "file",
  //               name: "establishment_director_data_cin_copie",
  //               visible: false,

  //               startWithNewLine: false,
  //               title: "Copie de la CIN - نسخة من بطاقة التعريف الوطنية",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               filePlaceholder:
  //                 "Télécharger la copie du CIN.\nحمّل نسخة من بطاقة التعريف الوطنية",
  //               storeDataAsText: false,
  //             },
  //             {
  //               type: "file",
  //               name: "copie_autorisation_ministre",
  //               visible: false,

  //               startWithNewLine: false,
  //               title:
  //                 "Copie de l’autorisation du ministre -  نسخة من ترخيص الوزير",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               filePlaceholder:
  //                 "Télécharger une copie de l'autorisation du ministre\nحمل نسخة من ترخيص الوزير",
  //               storeDataAsText: false,
  //             },
  //             {
  //               type: "file",
  //               name: "establishment_director_data_passeport_copie",

  //               visible: false,
  //               startWithNewLine: false,
  //               title: "Copie du passeport - نسخة من جواز السفر",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               hideNumber: true,
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               allowMultiple: true,
  //               filePlaceholder:
  //                 "Télécharger la copie du passeport.\nحمل نسخة من جواز السفر",
  //               storeDataAsText: false,
  //             },

  //             {
  //               type: "text",
  //               inputType: "refreshbar",
  //               name: "establishment_director_data_spinner",
  //               title: "Veuillez patientez ...",
  //               visible: false,
  //               startWithNewLine: false,
  //             },

  //             {
  //               type: "text",
  //               name: "establishment_director_data_test_valid",
  //               visible: false,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page4",
  //       title: "Données sur la nature de formation - بيانات حول طبيعة التكوين",

  //       elements: [
  //         {
  //           type: "panel",
  //           name: "panel_training",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "training_nature_data",
  //               title:
  //                 "Données sur la nature de formation - بيانات حول طبيعة التكوين",

  //               hideNumber: true,
  //               isRequired: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "training_type_data",
  //                   title:
  //                     "Formation - تكوين {panel.training_type_data_nature}",

  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_nature",
  //                       title: "Nature de la formation - طبيعة التكوين",
  //                       defaultValueExpression:
  //                         "{establishment_data_training_type}",
  //                       hideNumber: true,
  //                       enableIf:
  //                         "{establishment_data_training_type} = 'أساسي ومستمر'",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         {
  //                           value: "أساسي",
  //                           text: "Initiale - أساسي",
  //                         },
  //                         {
  //                           value: "مستمر",
  //                           text: "Continue - مستمر",
  //                         },
  //                       ],
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_type",
  //                       visibleIf:
  //                         "{panel.training_type_data_nature} = 'أساسي'",
  //                       startWithNewLine: false,
  //                       title: "Type - النوع",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         {
  //                           value: "منظرة",
  //                           text: "Homologuée - منظرة",
  //                         },
  //                         {
  //                           value: "غير منظرة",
  //                           text: "Non homologuée - غير منظرة",
  //                         },
  //                       ],
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_sector",
  //                       startWithNewLine: false,
  //                       title: "Secteur - القطاع",
  //                       allowClear: false,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: secteurData,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_sub_sector",
  //                       startWithNewLine: true,
  //                       title: "Sous-secteur - القطاع الفرعي",
  //                       allowClear: false,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [],
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_domain",
  //                       visibleIf:
  //                         "({panel.training_type_data_type} = 'غير منظرة') or ({panel.training_type_data_nature} = 'مستمر')",
  //                       startWithNewLine: false,
  //                       title: "Domaine (En arabe) - المجال (بالعربية)",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",

  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_specialty",
  //                       visibleIf: "{panel.training_type_data_type} = 'منظرة'",
  //                       title: "Spécialité - الإختصاص",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [],
  //                       allowClear: false,
  //                       startWithNewLine: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_diploma",
  //                       visibleIf: "{panel.training_type_data_type} = 'منظرة'",
  //                       startWithNewLine: true,
  //                       title: "Diplôme délivré - الشهادة المسلمة ",
  //                       readOnly: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_required_level",
  //                       visibleIf: "{panel.training_type_data_type} = 'منظرة'",
  //                       startWithNewLine: false,
  //                       title: "Niveau requis - المستوى الادنى المطلوب ",
  //                       allowClear: false,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         {
  //                           value:
  //                             "Certificat de compétence (CC) - شهادة المهارة",
  //                           text: "Certificat de compétence (CC) - شهادة المهارة",
  //                         },
  //                         {
  //                           value:
  //                             "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
  //                           text: "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
  //                         },
  //                         {
  //                           value:
  //                             "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
  //                           text: "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
  //                         },
  //                       ],
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_duration_hour",
  //                       title: "Durée en (H) - المدة بالساعات (س)",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 0,
  //                       startWithNewLine: true,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_duration_month",
  //                       startWithNewLine: false,
  //                       title: "Durée en mois - المدة بالاشهر",
  //                       visibleIf:
  //                         "{panel.training_type_data_nature} != 'مستمر'",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 0,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "training_type_data_duration_minimum",
  //                       visibleIf:
  //                         "({panel.training_type_data_nature} != 'مستمر') and ({panel.training_type_data_type} != 'غير منظرة')",
  //                       startWithNewLine: false,
  //                       title:
  //                         "Durée minimale en (H) - (المدة الدنيا بالساعات (س",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 0,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "training_type_data_mode",

  //                       visibleIf:
  //                         "{panel.training_type_data_nature} = 'أساسي'",
  //                       maxWidth: "50%",
  //                       startWithNewLine: false,
  //                       title: "Mode de formation - نمط التكوين",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         {
  //                           value: "حضوري",
  //                           text: "Présentielle - حضوري",
  //                         },
  //                         {
  //                           value: "عن بعد",
  //                           text: "À distance - عن بعد",
  //                         },
  //                         {
  //                           value: "بالتداول",
  //                           text: "En alternance - بالتداول",
  //                         },
  //                       ],
  //                       placeholder: "",
  //                       allowClear: false,
  //                       searchEnabled: false,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter une formation - إضافة تكوين",

  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page5",
  //       title: "Données des Formateurs - بيانات المكونين",

  //       elements: [
  //         {
  //           type: "panel",
  //           name: "trainer",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "trainer_data",
  //               title: "Données des Formateurs - بيانات المكونين",

  //               hideNumber: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "trainer_obj",
  //                   title: " Formateur - المكون {panelIndex}",

  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_nationality",
  //                       title: "Nationalité - الجنسية",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       choices: nationalityList,
  //                       defaultValue: "تونسية",
  //                       allowClear: false,
  //                       searchEnabled: true,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_passport",
  //                       visibleIf:
  //                         "{panel.trainer_data_nationality} <> 'تونسية'",
  //                       startWithNewLine: false,
  //                       title: "Numéro du passeport - رقم جواز السفر",
  //                       inputType:'api',
  //                       hideNumber: true,
  //                       isRequired: true,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_cin_number",
  //                       visibleIf:
  //                         "{panel.trainer_data_nationality} = 'تونسية'",
  //                       startWithNewLine: false,
  //                       title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية",
  //                       errorLocation: "bottom",
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       inputType: "api",

  //                     },

  //                     {
  //                       type: "text",
  //                       inputType: "date",
  //                       name: "trainer_data_cin_date_delivrance",

  //                       startWithNewLine: false,
  //                       title:
  //                         "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
  //                       readOnly: true,
  //                       hideNumber: true,
  //                       errorLocation: "bottom",
  //                       isRequired: true,
  //                       visible: false,
  //                       dateFormat: "dd/mm/yy",
  //                       placeholder: {
  //                         default: "DD/MM/YYYY",
  //                         ar: "اليوم / الشهر / السنة",
  //                       },
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_last_name",
  //                       title: "Nom (En arabe) - اللقب (بالعربية)",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       visible: false,
  //                       readOnly: true,

  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_first_name",
  //                       startWithNewLine: false,
  //                       title: "Prénom (En arabe) - الإسم (بالعربية)",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       visible: false,
  //                       readOnly: true,

  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_civility",
  //                       startWithNewLine: false,
  //                       title: "Genre - الجنس",
  //                       allowClear: false,
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       visible: false,
  //                       choices: [
  //                         {
  //                           value: "ذكر",
  //                           text: "Masculin - ذكر",
  //                         },
  //                         {
  //                           value: "أنثى",
  //                           text: "Féminin - أنثى",
  //                         },
  //                       ],
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_birth_date",
  //                       title: "Date de naissance - تاريخ الولادة",
  //                       inputType: "date",
  //                       hideNumber: true,
  //                       visible: false,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       placeholder: {
  //                         default: "DD/MM/YYYY",
  //                         ar: "اليوم / الشهر / السنة",
  //                       },
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_birth_place",
  //                       startWithNewLine: false,
  //                       title:
  //                         "Lieu de naissance (En arabe) - مكان الولادة (بالعربية)",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       visible: false,

  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_address",
  //                       startWithNewLine: false,
  //                       title: "Adresse (En arabe) - العنوان (بالعربية)",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       visible: false,

  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_governorate",
  //                       title: "Gouvernorat - الولاية",
  //                       choices: gouvernoratList,
  //                       visible: false,
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_delegation",
  //                       startWithNewLine: false,
  //                       title: "Délégation - المعتمدية",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       visible: false,
  //                       errorLocation: "bottom",
  //                       readOnly: true,
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_postal_code",
  //                       startWithNewLine: false,
  //                       title: "Code postal - الترقيم البريدي",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       visible: false,
  //                       readOnly: true,
  //                       maskType: "pattern",
  //                       maskSettings: {
  //                         pattern: "9999",
  //                       },
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_email",
  //                       title: "Adresse e-mail - البريد الإلكتروني",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       visible: false,
  //                       inputType: "email",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_phone_number",
  //                       startWithNewLine: false,
  //                       title: "Numéro du téléphone - رقم الهاتف",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       visible: false,
  //                       readOnly: true,
  //                       maskType: "pattern",
  //                       maskSettings: {
  //                         pattern: "99999999",
  //                       },
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_education_level",
  //                       startWithNewLine: false,
  //                       title: "Niveau scolaire - المستوى الدراسي",
  //                       allowClear: false,
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       visible: false,
  //                       choices: [
  //                         {
  //                           value: "إبتدائي",
  //                           text: "Primaire - إبتدائي",
  //                         },
  //                         {
  //                           value: "ثانوي",
  //                           text: "Secondaire - ثانوي",
  //                         },
  //                         {
  //                           value: "جامعي",
  //                           text: "Universitaire - جامعي",
  //                         },
  //                         {
  //                           value: "تكوين مهني",
  //                           text: "Formation professionnelle - تكوين مهني",
  //                         },
  //                       ],
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_specialty_domain",
  //                       title: "Domaines de spécialité - مجالات التخصص",

  //                       hideNumber: true,
  //                       visible: false,
  //                       isRequired: true,
  //                       readOnly: true,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "trainer_data_situation",
  //                       maxWidth: "50%",
  //                       startWithNewLine: false,
  //                       title: "Situation - الوضعية",
  //                       visible: false,

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       choices: [
  //                         {
  //                           value: "قار",
  //                           text: "Permanent - قار",
  //                         },
  //                         {
  //                           value: "غير قار",
  //                           text: "Vacataire - غير قار",
  //                         },
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "radiogroup",
  //                       name: "trainer_data_specific_needs",
  //                       startWithNewLine: false,
  //                       visible: false,
  //                       title:
  //                         "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       readOnly: true,
  //                       choices: [
  //                         {
  //                           value: "نعم",
  //                           text: "Oui - نعم",
  //                         },
  //                         {
  //                           value: "لا",
  //                           text: "Non - لا",
  //                         },
  //                       ],
  //                       colCount: 4,
  //                     },
  //                     {
  //                       type: "file",
  //                       visible: false,
  //                       name: "trainer_data_folder",
  //                       readOnly: true,
  //                       title:
  //                         "Dossier du formateur auprès de l'établissement - ملف المكون لدى المؤسسة ",
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       isRequired: true,
  //                       filePlaceholder:
  //                         "Téléchargez le dossier du formateur.\nحمل ملف المكون لدى المؤسسة \n",
  //                       storeDataAsText: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       visible: false,
  //                       name:"dossier_autoriser"
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "trainer_data_cin",
  //                       readOnly: true,
  //                       startWithNewLine: false,
  //                       title:
  //                         "Copie de la CIN - نسخة من بطاقة التعريف الوطنية ",
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       isRequired: true,
  //                       filePlaceholder:
  //                         "Téléchargez une copie de la CIN du formateur.\nحمل نسخة من بطاقة التعريف الوطنية للمكون",
  //                       storeDataAsText: false,
  //                       visible: false,
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "trainer_data_passeport",
  //                       readOnly: true,
  //                       startWithNewLine: false,
  //                       visible: false,
  //                       title: "Copie du passeport - نسخة من جواز السفر",
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       isRequired: true,
  //                       filePlaceholder:
  //                         "Téléchargez une copie du passeport du formateur.\nيرجى تحميل نسخة من جواز سفر للمكون",
  //                       storeDataAsText: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_data_test_valid",
  //                       visible: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "trainer_active",
  //                       visible: false,
  //                     },

  //                     {
  //                       type: "text",
  //                       inputType: "refreshbar",
  //                       name: "cin_check_spinner",
  //                       title: "Veuillez patientez ...",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un formateur - إضافة مكون",

  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page6",
  //       title: "Données des Espaces - بيانات الفضاءات",

  //       elements: [
  //         {
  //           type: "panel",
  //           name: "panel7",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "space_data",
  //               title: "Données des Espaces - بيانات الفضاءات",

  //               hideNumber: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "space_obj",
  //                   title: "Espace - فضاء {panelIndex}",

  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "space_data_type",
  //                       title: "Type - النوع",

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         {
  //                           value: "إدارة",
  //                           text: "Administration - إدارة",
  //                         },
  //                         {
  //                           value: "فضاء مشترك",
  //                           text: "Espace commun - فضاء مشترك",
  //                         },
  //                         {
  //                           value: "قاعة تدريس",
  //                           text: "Salle de classe - قاعة تدريس",
  //                         },
  //                         {
  //                           value: "ورشة",
  //                           text: "Atelier - ورشة",
  //                         },
  //                         {
  //                           value: "مخبر",
  //                           text: "Laboratoire - مخبر",
  //                         },
  //                       ],
  //                       showOtherItem: true,
  //                       otherText: "Autre - آخر",
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "space_data_label",
  //                       startWithNewLine: false,
  //                       title: "Libellé (En arabe) - التسمية (بالعربية)",

  //                       isRequired: true,
  //                       errorLocation: "bottom",

  //                     },
  //                     {
  //                       type: "text",
  //                       name: "space_data_surface_area",
  //                       title: "Superficie en m² - المساحة بالمتر المربع",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 0,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "space_data_capacity",
  //                       startWithNewLine: false,
  //                       title:
  //                         "Capacité :Nombre de personne - طاقة الإستيعاب (عدد الأشخاص)",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 0,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un espace - إضافة فضاء",

  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //             {
  //               type: "file",
  //               name: "plans_espaces_files",
  //               title: "Plans des espaces -  التصميم الهندسي للمساحات",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               allowMultiple: true,
  //               filePlaceholder:
  //                 "Téléchargez les plans des espaces\nتحميل التصميم الهندسي للمساحات ",
  //               storeDataAsText: false,
  //               hideNumber: true,
  //             },
  //             {
  //               type: "html",
  //               name: "file_description",
  //               html: `<div class='description'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z" fill="#2BC1A7"/><path d="M12 10.25C11.59 10.25 11.25 10.59 11.25 11V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V11C12.75 10.59 12.41 10.25 12 10.25ZM11.08 8.381C11.13 8.501 11.2 8.611 11.29 8.711C11.39 8.801 11.5 8.871 11.62 8.921C11.8635 9.02102 12.1365 9.02102 12.38 8.921C12.5 8.871 12.61 8.801 12.71 8.711C12.8 8.611 12.87 8.501 12.92 8.381C12.97 8.261 13 8.131 13 8.001C13 7.871 12.97 7.741 12.92 7.621C12.87 7.491 12.8 7.391 12.71 7.291C12.61 7.201 12.5 7.131 12.38 7.081C12.26 7.031 12.13 7.001 12 7.001C11.87 7.001 11.74 7.031 11.62 7.081C11.5 7.131 11.39 7.201 11.29 7.291C11.2 7.391 11.13 7.491 11.08 7.621C11.03 7.741 11 7.871 11 8.001C11 8.131 11.03 8.261 11.08 8.381Z" fill="#194FC1"/></svg>Vous avez la possibilité de joindre plusieurs fichiers simultanément. - يمكنكم إرفاق عدة ملفات في نفس الوقت.</div>`,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page7",
  //       title:
  //         "Données des équipements de formation - بيانات التجهيزات التكوينية ",

  //       elements: [
  //         {
  //           type: "panel",
  //           name: "equipmets",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "equipments_data",
  //               title:
  //                 "Données des équipements de formation - بيانات التجهيزات التكوينية",

  //               hideNumber: true,
  //               isRequired: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "equipement_obj",
  //                   title:
  //                     "Équipement de formation - تجهيز تكويني {panelIndex}",

  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "text",
  //                       name: "equipment_label",
  //                       title: "Équipement de formation - تجهيز تكويني",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "equipment_number",
  //                       startWithNewLine: false,
  //                       title: "Nombre - العدد",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                       min: 1,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText: "Ajouter un équipement - إضافة تجهيزات",
  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //             {
  //               type: "file",
  //               name: "equipment_tech_file",
  //               title:
  //                 "Fiche technique des équipements de formation -  الوثائق الفنية للتجهيزات التكوينية ",
  //               acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //               isRequired: true,
  //               errorLocation: "bottom",
  //               allowMultiple: true,
  //               filePlaceholder:
  //                 "Téléchargez les fiches techniques des équipements de formation\nتحميل الوثائق الفنية للتجهيزات التكوينية ",
  //               storeDataAsText: false,
  //               hideNumber: true,
  //             },
  //             {
  //               type: "html",
  //               name: "file_description",
  //               html: `<div class='description'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z" fill="#2BC1A7"/><path d="M12 10.25C11.59 10.25 11.25 10.59 11.25 11V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V11C12.75 10.59 12.41 10.25 12 10.25ZM11.08 8.381C11.13 8.501 11.2 8.611 11.29 8.711C11.39 8.801 11.5 8.871 11.62 8.921C11.8635 9.02102 12.1365 9.02102 12.38 8.921C12.5 8.871 12.61 8.801 12.71 8.711C12.8 8.611 12.87 8.501 12.92 8.381C12.97 8.261 13 8.131 13 8.001C13 7.871 12.97 7.741 12.92 7.621C12.87 7.491 12.8 7.391 12.71 7.291C12.61 7.201 12.5 7.131 12.38 7.081C12.26 7.031 12.13 7.001 12 7.001C11.87 7.001 11.74 7.031 11.62 7.081C11.5 7.131 11.39 7.201 11.29 7.291C11.2 7.391 11.13 7.491 11.08 7.621C11.03 7.741 11 7.871 11 8.001C11 8.131 11.03 8.261 11.08 8.381Z" fill="#194FC1"/></svg>Vous avez la possibilité de joindre plusieurs fichiers simultanément. - يمكنكم إرفاق عدة ملفات في نفس الوقت.</div>`,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "page8",
  //       title:
  //         "Données du personnel d'encadrement et de gestion - بيانات أعوان الاحاطة والتأطير",

  //       elements: [
  //         {
  //           type: "panel",
  //           name: "management_staff",
  //           elements: [
  //             {
  //               type: "paneldynamic",
  //               name: "management_data",
  //               title:
  //                 "Données du personnel d'encadrement et de gestion - بيانات أعوان الاحاطة والتأطير",

  //               hideNumber: true,
  //               templateElements: [
  //                 {
  //                   type: "panel",
  //                   name: "management_data_obj",
  //                   title: "Personnel - عون {panelIndex}",

  //                   state: "expanded",
  //                   elements: [
  //                     {
  //                       type: "dropdown",
  //                       name: "management_data_staff_nationality",
  //                       title: "Nationalité - الجنسية",
  //                       allowClear: false,

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: nationalityList,
  //                       defaultValue: "تونسية",
  //                     },

  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_cin_number",
  //                       visibleIf:
  //                         "{panel.management_data_staff_nationality} = 'تونسية'",
  //                       startWithNewLine: true,
  //                       title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية ",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       maskType: "pattern",
  //                       maskSettings: {
  //                         pattern: "99999999",
  //                       },
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_passport_number",
  //                       visibleIf:
  //                         "{panel.management_data_staff_nationality} <> 'تونسية'",
  //                       title: "Numéro du passeport - رقم جواز السفر",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       startWithNewLine: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       inputType: "date",
  //                       name: "management_data_staff_birth_date",
  //                       startWithNewLine: false,
  //                       title: "Date de naissance - تاريخ الولادة",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       readOnly: false,
  //                       dateFormat: "dd/mm/yy",
  //                       placeholder: {
  //                         default: "DD/MM/YYYY",
  //                         ar: "اليوم / الشهر / السنة",
  //                       },
  //                     },

  //                     {
  //                       type: "text",
  //                       inputType: "Button",
  //                       title: " ",
  //                       hideNumber: true,
  //                       name: "management_data_search",
  //                       startWithNewLine: false,
  //                       visibleIf:
  //                         " {panel.management_data_staff_nationality} = 'تونسية'",
  //                     },

  //                     {
  //                       type: "dropdown",
  //                       name: "management_data_staff_category",
  //                       title: "Catégorie - الفئة",

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       visible: false,
  //                       choices: [
  //                         {
  //                           value: "تأطير",
  //                           text: "Encadrement - تأطير",
  //                         },
  //                         {
  //                           value: "إدارة",
  //                           text: "Administration - إدارة",
  //                         },
  //                       ],
  //                       allowClear: false,

  //                       showOtherItem: true,
  //                       otherText: "Autre catégorie - فئة أخرى",
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_email",
  //                       startWithNewLine: false,
  //                       title: "Adresse e-mail - البريد الإلكتروني",
  //                       visible: false,

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "email",
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "management_data_staff_situation",
  //                       startWithNewLine: false,
  //                       title: "Situation - الوضعية",
  //                       allowClear: false,
  //                       visible: false,

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         {
  //                           value: "قار",
  //                           text: "Permanent -قار",
  //                         },
  //                         {
  //                           value: "غير قار",
  //                           text: "Non permanent - غير قار",
  //                         },
  //                       ],
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_last_name",
  //                       title: "Nom (En arabe)  - اللقب (بالعربية)",
  //                       enableIf: "{panel.management_data_staff_nationality} <>'تونسية'",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       visible: false,

  //                     },
  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_first_name",
  //                       startWithNewLine: false,
  //                       title: "Prénom (En arabe) - الإسم (بالعربية)",
  //                       enableIf: "{panel.management_data_staff_nationality} <>'تونسية'",
  //                       visible: false,

  //                       isRequired: true,
  //                       errorLocation: "bottom",

  //                     },

  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_last_name_fr",
  //                       title: "Nom (En français)  - اللقب (بالفرنسية)",

  //                       isRequired: true,
  //                       startWithNewLine: false,
  //                       visible: false,

  //                       errorLocation: "bottom",

  //                     },
  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_first_name_fr",
  //                       startWithNewLine: true,
  //                       title: "Prénom (En français) - الإسم (بالفرنسية)",
  //                       visible: false,

  //                       isRequired: true,
  //                       errorLocation: "bottom",

  //                     },

  //                     {
  //                       type: "dropdown",
  //                       name: "management_data_staff_civility",
  //                       startWithNewLine: false,
  //                       title: "Genre - الجنس",
  //                       enableIf: "{panel.management_data_staff_nationality} <>'تونسية'",
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       visible: false,

  //                       choices: [
  //                         {
  //                           value: "ذكر",
  //                           text: "Masculin - ذكر",
  //                         },
  //                         {
  //                           value: "أنثى",
  //                           text: "Féminin - أنثى",
  //                         },
  //                       ],
  //                       allowClear: false,
  //                     },

  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_residence_address",
  //                       startWithNewLine: false,
  //                       title:
  //                         "Adresse de résidence (En arabe) - عنوان الإقامة (بالعربية)",
  //                       visible: false,

  //                       isRequired: true,
  //                       errorLocation: "bottom",

  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "management_data_staff_residence_governorate",
  //                       title: "Gouvernorat de résidence - ولاية الإقامة",
  //                       choices: gouvernoratList,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       allowClear: false,
  //                       visible: false,
  //                     },
  //                     {
  //                       type: "dropdown",
  //                       name: "management_data_staff_residence_delegation",
  //                       startWithNewLine: false,
  //                       title: "Délégation - المعتمدية",
  //                       visible: false,

  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       placeholder: "",
  //                       allowClear: false,
  //                     },

  //                     {
  //                       type: "dropdown",
  //                       name: "management_data_staff_education_level",
  //                       startWithNewLine: false,
  //                       title: "Niveau scolaire - المستوى الدراسي ",
  //                       visible: false,

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       choices: [
  //                         {
  //                           value: "Primaire",
  //                           text: "Primaire - إبتدائي",
  //                         },
  //                         {
  //                           value: "Secondaire",
  //                           text: "Secondaire - ثانوي",
  //                         },
  //                         {
  //                           value: "Universitaire",
  //                           text: "Universitaire - جامعي",
  //                         },
  //                         {
  //                           value: "Formation professionnelle",
  //                           text: "Formation professionnelle - تكوين مهني",
  //                         },
  //                       ],
  //                       allowClear: false,
  //                     },
  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_experience_years",
  //                       title:
  //                         "Nombre d'années d’expérience professionnelle - عدد سنوات الخبرة المهنية",
  //                       visible: false,

  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       inputType: "number",
  //                     },

  //                     {
  //                       type: "text",
  //                       inputType: "date",
  //                       name: "management_data_staff_date_delivrance",

  //                       visible: false,
  //                       readOnly: false,
  //                       title:
  //                         "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
  //                       startWithNewLine: false,
  //                       hideNumber: true,
  //                       isRequired: true,
  //                       errorLocation: "bottom",
  //                       dateFormat: "dd/mm/yy",
  //                       placeholder: {
  //                         default: "DD/MM/YYYY",
  //                         ar: "اليوم / الشهر / السنة",
  //                       },
  //                     },

  //                     {
  //                       type: "file",
  //                       name: "management_data_staff_personal_folder",
  //                       title: "Dossier du personnel - ملف العون",
  //                       visible: false,

  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       errorLocation: "bottom",
  //                       isRequired: true,
  //                       filePlaceholder:
  //                         "Téléchargez le dossier du personnel auprès de l'établissement\nتحميل ملف العون لدى المؤسسة",

  //                       storeDataAsText: false,
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "management_data_staff_cin_copy",

  //                       visible: false,

  //                       errorLocation: "bottom",
  //                       title:
  //                         "Copie de la CIN - نسخة من بطاقة التعريف الوطنية",
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       isRequired: true,
  //                       filePlaceholder:
  //                         "Téléchargez une copie de la CIN\nحمّل نسخة من بطاقة التعريف الوطنية",
  //                       startWithNewLine: false,
  //                       storeDataAsText: false,
  //                     },
  //                     {
  //                       type: "file",
  //                       name: "management_data_staff_passport_copy",
  //                       visible: false,

  //                       startWithNewLine: false,
  //                       title: "Copie du passeport - نسخة من جواز السفر",
  //                       acceptedTypes: ".jpg,.jpeg,.png,.pdf",
  //                       isRequired: true,
  //                       filePlaceholder:
  //                         "Téléchargez une copie du passeport\nحمل نسخة من جواز السفر",
  //                       storeDataAsText: false,
  //                     },

  //                     {
  //                       type: "text",
  //                       name: "management_data_staff_valid",
  //                       visible: false,
  //                       defaultValue:false,
  //                     },
  //                     {
  //                       type: "text",
  //                       inputType: "refreshbar",
  //                       name: "management_data_check_spinner",
  //                       title: "Veuillez patientez ...",
  //                       visible: false,
  //                       startWithNewLine: false,
  //                     },
  //                   ],
  //                 },
  //               ],
  //               panelCount: 1,
  //               minPanelCount: 1,
  //               panelAddText:
  //                 "Ajouter un personnel d'encadrement - أضف عون التأطير",

  //               panelRemoveText: "Supprimer - إزالة",
  //             },
  //           ],
  //         },
  //       ],
  //     },

  //   ],
  //   triggers: [

  //     {
  //       type: "runexpression",
  //       expression: "{establishment_director_data_identity} <> ''",
  //       runExpression:
  //         "checkDirectorIdentity({establishment_director_data_identity})",
  //     },

  //   ],

  //   sendResultOnPageNext: true,
  //   showCompletedPage: false,
  //   showProgressBar: "aboveheader",
  //   progressBarShowPageTitles: true,
  //   storeOthersAsComment: false,
  //   pagePrevText: "Précédant - رجوع",

  //   pageNextText: "Suivant - متابعة",

  //   completeText: "Soumettre la demande - تقديم المطلب",
  //   previewText: "Aperçu - عرض",
  //   editText: "Modifier - تعديل",
  //   showPreviewBeforeComplete: "showAllQuestions",
  // };

  return (
    <GlobalContext.Provider
      value={{
        globalData,
        setGlobalData,
        form,
        isRtl,
        setIsRtl,
        gouvernorats,
        porteDoc,
        setPorteDocs,
        pageN,
        setPageN,
        // codes_communes,
        brouillon,
        setBrouillon,
        nationalityOptions,
        gouvernoratOptions,
        // survey,
        secteurData,
        gouvernoratList,
        nationalityList,
        FormesJuridiquesList,
        personneMorale,
        setPersonneMorale,
        matriculeFiscale,
        setMatriculeFiscale,
        numeroEnregistrement,
        setNumeroEnregistrement,
        listAllDelegations,
        employeurByPatNumPatCL, setEmployeurByPatNumPatCL,
        ficheEntrepriseDGI, setFicheEntrepriseDGI,
        etablissementSecondaireDGI, setEtablissementSecondaireDGI,
        listActiviteRNE, setListActiviteRNE,
        entrepriseRegistrationId, setEntrepriseRegistrationId

      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
